import React, { useState } from "react";
import SettlementVerticalTable from "./SettlementVerticalTable";
import { TableRow } from "../AppTable2";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { amountDisplay, amountStrToInt } from "@/utils/money";
import SettlementArtistPayment from "./SettlementArtistPayment";

const SupportArtistSupports: React.FC = () => {
  const { eventSettlement, event, handleUpdateEventSettlement } =
    useSettlementComponent();
  const artistSupports = eventSettlement.data?.artist?.supports ?? [];
  const getArtistSupportItem = (key: "offer_support_1" | "offer_support_2") =>
    artistSupports?.find((item) => item.id === key);
  // temporary way of creating supports
  const supportData = () => {
    const supportData = [];
    const hasSupport1 = !!event.data?.offer_support_1;
    const hasSupport2 = !!event.data?.offer_support_2;
    if (!hasSupport1 && !hasSupport2) return [];
    const baseObject = (key: "offer_support_1" | "offer_support_2") => {
      const id = key;
      const artistSupportItem = getArtistSupportItem(key);
      const total =
        artistSupportItem?.guarantee +
        artistSupportItem?.adjustments +
        artistSupportItem?.misc +
        artistSupportItem?.less_deposit;
      return {
        id: id,
        title: event.data?.[key],
        total,
        rows: [
          {
            key: "guarantee",
            label: "Guarantee",
            value: artistSupportItem?.guarantee ?? 0,
            isEditable: true,
            format: "money",
          },
          {
            key: "adjustments",
            label: "Plus Artist Adjustments",
            value: artistSupportItem?.adjustments ?? 0,
            isEditable: true,
            format: "money",
          },
          {
            key: "misc",
            label: "Plus Misc",
            value: artistSupportItem?.misc ?? 0,
            isEditable: true,
            format: "money",
          },
          {
            key: "tax_percent",
            label: "Tax %",
            value: artistSupportItem?.tax_percent ?? 0,
            isEditable: true,
            format: "money",
          },
          {
            key: "less_deposit",
            label: "Less Deposit",
            value: artistSupportItem?.less_deposit ?? 0,
            isEditable: true,
            format: "money",
          },
          {
            key: "total_due",
            label: "Total Due",
            value: artistSupportItem?.total_due ?? 0,
            isEditable: true,
            format: "money",
          },
        ],
      };
    };

    if (hasSupport1) {
      supportData.push(baseObject("offer_support_1"));
    }
    if (hasSupport2) {
      supportData.push(baseObject("offer_support_2"));
    }
    return supportData;
  };
  const [data, setData] = useState({
    support1: {
      title: event.data?.offer_support_1,
      rows: [
        {
          key: "Support1_Guarantee",
          label: "Guarantee",
          value: 65000,
          isEditable: true,
        },
        {
          key: "Support1_Adjustments",
          label: "Plus Artist Adjustments",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support1_Misc",
          label: "Plus Misc",
          value: 0,
          isEditable: true,
        },
        { key: "Support1_Tax", label: "Tax %", value: 0, isEditable: true },
        {
          key: "Support1_LessDeposit",
          label: "Less Deposit",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support1_TotalDue",
          label: "Total Due",
          value: 65000,
          isEditable: true,
        },
      ],
    },
    support2: {
      title: "Support 2",
      rows: [
        {
          key: "Support2_Guarantee",
          label: "Guarantee",
          value: 1000,
          isEditable: true,
        },
        {
          key: "Support2_Adjustments",
          label: "Plus Artist Adjustments",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support2_Misc",
          label: "Plus Misc",
          value: 0,
          isEditable: true,
        },
        { key: "Support2_Tax", label: "Tax %", value: 0, isEditable: true },
        {
          key: "Support2_LessDeposit",
          label: "Less Deposit",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support2_TotalDue",
          label: "Total Due",
          value: 1000,
          isEditable: true,
        },
      ],
    },
    support3: {
      title: "Support 3",
      rows: [
        {
          key: "Support3_Guarantee",
          label: "Guarantee",
          value: 2000,
          isEditable: true,
        },
        {
          key: "Support3_Adjustments",
          label: "Plus Artist Adjustments",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support3_Misc",
          label: "Plus Misc",
          value: 0,
          isEditable: true,
        },
        { key: "Support3_Tax", label: "Tax %", value: 0, isEditable: true },
        {
          key: "Support3_LessDeposit",
          label: "Less Deposit",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support3_TotalDue",
          label: "Total Due",
          value: 2000,
          isEditable: true,
        },
      ],
    },
  });

  const handleBlur = (id: string, key: string, value: string | number) => {
    const newValue = value;
    const isString = isNaN(Number(value));
    if (!isString) {
      //   newValue = amountStrToInt(value);
    }
    const updatedSupports = artistSupports.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [key]: newValue, // update the specific key with the new value
        };
      }
      return item;
    });

    // If the support with the given id does not exist, add it to the array
    if (!artistSupports.some((item) => item.id === id)) {
      const newSupport = {
        id,
        guarantee: 0,
        adjustments: 0,
        misc: 0,
        tax_percent: 0,
        less_deposit: 0,
        total_due: 0,
        payment_method: "",
        check_number: "",
        paid_through: "",
        [key]: newValue,
      };
      updatedSupports.push(newSupport);
    }

    handleUpdateEventSettlement({
      data: updatedSupports,
      key: "supports",
      book: "artist",
    });
  };

  return (
    <div className="grid grid-cols-3 gap-y-4">
      {/* Test layout */}
      {/* {Object.keys(data).map((sectionKey) => (
        <div key={sectionKey}>
          <SettlementVerticalTable
            title={data?.[sectionKey]?.title}
            data={data?.[sectionKey]?.rows ?? []}
            onBlur={(key, newValue) => handleBlur(key, newValue)}
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "TOTAL DUE",
              value: "$94949",
            }}
            rowContainerClass="bg-blue-200 p-0.5"
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: true,
              },
            ]}
            rowData={{
              name: "PAYMENT METHOD (check / wire)",
              value: "$94949",
            }}
            rowContainerClass="border p-0.5"
            inputClassName="!border-0"
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: true,
              },
            ]}
            rowData={{
              name: "CHECK #",
              value: "$94949",
            }}
            rowContainerClass="border p-0.5"
            inputClassName="!border-0"
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: true,
              },
            ]}
            rowData={{
              name: "PAID THROUGH:",
              value: "$94949",
            }}
            rowContainerClass="border p-0.5"
            inputClassName="!border-0"
          />
        </div>
      ))} */}
      {/* end test layout */}

      {supportData().map((section, index) => (
        <div key={section.id}>
          <SettlementVerticalTable
            title={`Support ${index + 1}(${section.title})`}
            data={section.rows ?? []}
            onBlur={(key, newValue) => {
              handleBlur(section.id, key, newValue);
            }}
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "TOTAL DUE",
              value: amountDisplay(section.total),
            }}
            rowContainerClass="bg-blue-200 p-0.5"
          />
          <SettlementArtistPayment
            checkNumber={{
              value: getArtistSupportItem(section.id)?.check_number,
              onUpdate: (value: string) => {
                handleBlur(section.id, "check_number", value);
              },
            }}
            paymentMethod={{
              value: getArtistSupportItem(section.id)?.payment_method,
              onUpdate: (value: string) => {
                handleBlur(section.id, "payment_method", value);
              },
            }}
            paidThrough={{
              value: getArtistSupportItem(section.id)?.paid_through,
              onUpdate: (value: string) => {
                handleBlur(section.id, "paid_through", value);
              },
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default SupportArtistSupports;
