import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Export both Form and FormBuilder pointing to the same component
export const Form = (props) => {
  const {
    onSubmit,
    schema,
    children,
    defaultValues,
    className,
    mode = "onSubmit",
    id = "this-form",
  } = props;

  const methods = useForm({
    mode: mode,
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (props.reset === true) {
      if (methods.formState.isSubmitSuccessful) {
        methods.reset();
      }
    }
  }, [methods.formState, methods.submittedData, methods.reset]);

  return (
    <FormProvider {...methods} {...className}>
      <form onSubmit={methods.handleSubmit(onSubmit)} id={id}>
        {children}
      </form>
    </FormProvider>
  );
};

// Add static useForm property
Form.useForm = useForm;
