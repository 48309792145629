import React from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { FormBuilder, Input, Checkbox } from "@/components/Form";
import { getVendor, updateVendor } from "@/queries/vendors";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { usePageTitle } from "@/utils/pagetitle";

export const VendorEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const vendor = useQuery(["vendor-detail", id], () => getVendor(id));
  usePageTitle(
    vendor.isSuccess
      ? `/c${String.fromCharCode(92)}${vendor.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating vendor");
      console.dir(data);

      return updateVendor(id, data);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["vendors"]);
      queryClient.invalidateQueries(["vendor-detail"]);
      console.log("Moving!");
      navigate(`/vendors/${id}`);
    },
  });

  if (vendor.isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  const updateSchema = yup.object().shape({
    name: yup.string().required(),
    is_agency: yup.bool(),
    description: yup.string(),
    abbr: yup.string(),
    url: yup.string().url(),
    email: yup.string().email(),
    phone: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
    twitter: yup.string(),
    instagram: yup.string(),
    facebook: yup.string(),
  });

  let values = {
    name: vendor.data.name || "",
    is_agency: vendor.data.is_agency || false,
    description: vendor.data.description || "",
    url: vendor.data.url || "",
    email: vendor.data.email || "",
    phone: vendor.data.phone || "",
    address: vendor.data.address || "",
    city: vendor.data.city || "",
    state: vendor.data.state || "",
    zipcode: vendor.data.zipcode || "",
    twitter: vendor.data.twitter || "",
    instagram: vendor.data.instagram || "",
    facebook: vendor.data.facebook || "",
  };

  const handleSubmit = (data) => {
    console.log("In submit");
    console.dir(data);
    mutation.mutate(data);
  };

  console.dir(values);
  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[
            { text: "Vendors", url: "/vendors" },
            { text: vendor.data.name },
          ]}
        />

        <h3 className="text-xl font-bold mb-4 dark:text-cave-white">
          Edit - {vendor.data.name}
        </h3>
        <div className="py-6 min-w-full">
          <FormBuilder
            defaultValues={values}
            onSubmit={(data) => handleSubmit(data)}
            schema={updateSchema}
          >
            <Input name="name" label="Name" />
            <Checkbox name="is_agency" label="Is this an Agency?" />
            <Input name="description" label="Description" />
            <Input name="url" label="URL" />
            <Input name="email" label="Email" />
            <Input name="phone" label="Phone" />
            <Input name="address" label="Address" />
            <Input name="city" label="City" />
            <Input name="state" label="State" />
            <Input name="zipcode" label="Zipcode" />
            <Input name="twitter" label="Twitter" />
            <Input name="instagram" label="Instagram" />
            <Input name="facebook" label="Facebook" />

            <div className="flex-inline">
              <button
                type="button"
                onClick={() => navigate(`/vendors/${vendor.data.id}`)}
                className="ml-4 mr-4 float-right rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-cave-white"
              >
                Save
              </button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
