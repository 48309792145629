import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FormBuilder,
  Input,
  TextInput,
  FormSelect,
  selectOptionToValue,
} from "@/components/Form";
import { createVenue } from "@/queries/venues";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";

export const VenueAdd = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating venue");
      console.dir(data);

      if (data.witholding_percentage == "") {
        data.witholding_percentage = null;
      }

      if (data.capacity == "") {
        data.capacity = null;
      }

      if (data.rent_rate == "") {
        data.rent_rate = "0.00";
      }

      if (data.sales_tax == "") {
        data.sales_tax = "0.00";
      }

      return createVenue(data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["venues"]);
      navigate(`/venues/${data.id}`);
    },
  });

  const updateSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    abbr: yup.string(),
    url: yup.string().url(),
    email: yup.string().email(),
    phone: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
    twitter: yup.string(),
    instagram: yup.string(),
    facebook: yup.string(),
    witholding_percentage: yup.string(),
    sales_tax: yup.string(),
    capacity: yup.string(),
    rent_rate: yup.string(),
    the_deal: yup.string(),
    market: yup.string(),
    region: yup.object(),
    partner: yup.object(),
    ticketing: yup.object(),
  });

  const handleSubmit = (data) => {
    console.log("In submit");
    data = selectOptionToValue("region", data);
    data = selectOptionToValue("partner", data);
    data = selectOptionToValue("ticketing", data);
    mutation.mutate(data);
  };

  const defaultValues = {
    witholding_percentage: "0.00",
  };
  const regionSelectOptions = [
    { value: "midwest", label: "Midwest" },
    { value: "southeast", label: "Southeast" },
    { value: "northeast", label: "Northeast" },
    { value: "southwest", label: "Southwest" },
    { value: "northwest", label: "Northwest" },
  ];

  const getRegionOptions = () => {
    let options = [...regionSelectOptions];
    return options;
  };

  const ticketingSelectOptions = [
    { value: "ticketmaster", label: "Ticketmaster" },
    { value: "evenue", label: "eVenue" },
    { value: "seatgeek", label: "Seat Geek" },
    { value: "etix", label: "eTix" },
    { value: "ticketweb", label: "Ticketweb" },
    { value: "ticketswest", label: "TicketsWest" },
    { value: "axs", label: "Axs" },
    { value: "eventbrite", label: "Eventbrite" },
    { value: "seetickets", label: "See Tickets" },
    { value: "ovationtix", label: "OvationTix" },
    { value: "ticketomaha", label: "Ticket Omaha" },
    { value: "selectaseat", label: "Select-A-Seat" },
    { value: "ticketstar", label: "Ticket Star" },
    { value: "venue", label: "Venue" },
  ];
  const getTicketingOptions = () => {
    let options = [...ticketingSelectOptions];
    return options;
  };

  const partnerSelectOptions = [
    { value: "asm", label: "ASM" },
    { value: "rental", label: "Rental" },
    { value: "copro", label: "Co-Pro" },
    { value: "copro_newwest", label: "Co-Pro/New West" },
    { value: "copro_ea", label: "Co-Pro/EA" },
    { value: "direct", label: "Direct" },
    { value: "venuecoalition", label: "VenueCoalition" },
    { value: "outback", label: "Outback" },
    { value: "confirmedent", label: "Confirmed Ent." },
    { value: "mammoth", label: "MAMMOTH" },
    { value: "copro_billy", label: "Co-Pro/Billy Hardison" },
    { value: "copro_livenation", label: "Co-Pro/LiveNation" },
    { value: "rental_tommy", label: "Rental/Tommy Ginoza" },
    { value: "ea", label: "EA" },
  ];
  const getPartnerOptions = () => {
    let options = [...partnerSelectOptions];
    return options;
  };

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[{ text: "Venues", url: "/venues" }, { text: "Add Venue" }]}
        />

        <h3 className="text-xl font-bold mb-4 dark:text-cave-white">
          Add New Venue
        </h3>
        <div className="py-6 min-w-full">
          <FormBuilder
            defaultValues={defaultValues}
            onSubmit={(data) => handleSubmit(data)}
            schema={updateSchema}
          >
            <Input name="name" label="Name" autoFocus={true} />
            <Input name="abbr" label="Abbreviation" />
            <TextInput name="description" label="Description" />
            <Input name="url" label="URL" />
            <Input name="email" label="Email" />
            <Input name="phone" label="Phone" />
            <Input name="address" label="Address" />
            <Input name="city" label="City" />
            <Input name="state" label="State" />
            <Input name="zipcode" label="Zipcode" />
            <Input name="twitter" label="Twitter" />
            <Input name="instagram" label="Instagram" />
            <Input name="facebook" label="Facebook" />
            <Input
              name="witholding_percentage"
              label="State Witholding Percentage"
            />
            <Input name="sales_tax" label="Sales Tax" />
            <TextInput name="capacity" label="Venue Total Capacity" />
            <TextInput name="rent_rate" label="Rent Rate" />
            <TextInput name="the_deal" label="The Deal" />
            <TextInput name="market" label="market" />
            <FormSelect
              name="region"
              label="Region"
              options={regionSelectOptions}
            />
            <FormSelect
              name="partner"
              label="Partner"
              options={partnerSelectOptions}
            />
            <FormSelect
              name="ticketing"
              label="Ticketing"
              options={ticketingSelectOptions}
            />

            <div className="flex-inline float-right mb-12">
              <button
                type="button"
                onClick={() => navigate(`/venues/`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Cancel
              </button>
              <Button type="submit">Add Venue</Button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
