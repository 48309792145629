import { getApiRequest, jsonHeaders, makeRequest } from "./base";

export const getEventAdvance = async (eventId) => {
  if (!eventId) throw new Error('Event ID is required');
  const url = `/api/events/advance/${eventId}/`;
  try {
    const response = await getApiRequest(url);
    if (!response) {
      throw new Error('No advance data received');
    }
    return response;
  } catch (error) {
    console.error('Error fetching advance data:', error);
    throw error;
  }
};

export const updateEventAdvance = async (eventId, data) => {
  if (!eventId) throw new Error('Event ID is required');
  if (!data || typeof data !== 'object') throw new Error('Invalid advance data format');

  const url = `/api/events/advance/${eventId}/`;
  const headers = await jsonHeaders(true);

  // Convert camelCase to snake_case for backend
  const convertedData = {};
  Object.entries(data).forEach(([key, value]) => {
    const snakeKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    convertedData[snakeKey] = value;
  });

  // Field mappings from frontend to backend model
  const fieldMappings = {
    // Event Info
    venueType: 'venue_type',

    // Venue Contacts
    gmName: 'gm_name',
    gmPhone: 'gm_phone',
    gmCell: 'gm_cell', 
    gmEmail: 'gm_email',

    marketingName: 'marketing_name',
    marketingPhone: 'marketing_phone',
    marketingCell: 'marketing_cell',
    marketingEmail: 'marketing_email',

    boxOfficeName: 'box_office_name',
    boxOfficePhone: 'box_office_phone',
    boxOfficeCell: 'box_office_cell',
    boxOfficeEmail: 'box_office_email',

    productionName: 'production_name',
    productionPhone: 'production_phone',
    productionCell: 'production_cell',
    productionEmail: 'production_email',

    cateringName: 'catering_name',
    cateringPhone: 'catering_phone',
    cateringCell: 'catering_cell',
    cateringEmail: 'catering_email',

    // Tour Contacts
    tourMgrName: 'tour_mgr_name',
    tourMgrPhone: 'tour_mgr_phone',
    tourMgrCell: 'tour_mgr_cell',
    tourMgrEmail: 'tour_mgr_email',

    prodMgrName: 'prod_mgr_name',
    prodMgrPhone: 'prod_mgr_phone',
    prodMgrCell: 'prod_mgr_cell',
    prodMgrEmail: 'prod_mgr_email',

    tourAcctName: 'tour_acct_name',
    tourAcctPhone: 'tour_acct_phone',
    tourAcctCell: 'tour_acct_cell',
    tourAcctEmail: 'tour_acct_email',

    // Schedule
    venueAccess: 'venue_access',
    breakfastTime: 'breakfast_time',
    localLoadIn: 'local_load_in',
    tourLoadIn: 'tour_load_in',
    lunchTime: 'lunch_time',
    soundCheck: 'sound_check',
    securityMeeting: 'security_meeting',
    dinnerTime: 'dinner_time',
    doorsTime: 'doors_time',
    setOneTime: 'set_one_time',
    intermissionTime: 'intermission_time',
    setTwoTime: 'set_two_time',
    callbackTime: 'callback_time',
    curfewTime: 'curfew_time',

    // Production Needs
    rackStacks: 'rack_stacks',
    consoles: 'consoles',
    lights: 'lights',
    video: 'video',
    spotlights: 'spotlights',
    clearComm: 'clear_comm',
    curtains: 'curtains',
    bikeRack: 'bike_rack',
    barricade: 'barricade',
    forkLift: 'fork_lift',
    risers: 'risers',
    strobes: 'strobes',
    haze: 'haze',

    // Venue Tech Info
    powerOne: 'power_one',
    powerTwo: 'power_two',
    shorePower: 'shore_power',
    stageSize: 'stage_size',
    stageLeftWing: 'stage_left_wing',
    stageRightWing: 'stage_right_wing',
    mixArea: 'mix_area',
    mixDistance: 'mix_distance',
    procenium: 'procenium',
    gridHeight: 'grid_height',
    loadInType: 'load_in_type',
    pushDistance: 'push_distance',
    truckDockCount: 'truck_dock_count',

    // Catering
    breakfastInfo: 'breakfast_info',
    lunchInfo: 'lunch_info',
    dressingRoomCatering: 'dressing_room_catering',
    dinnerInfo: 'dinner_info',

    // Phones/Internet
    phoneNeeds: 'phone_needs',
    internetInfo: 'internet_info',

    // Dressing Rooms
    roomOne: 'room_one',
    roomTwo: 'room_two',
    roomThree: 'room_three',
    furniture: 'furniture',

    // Parking
    truckParking: 'truck_parking',
    busParking: 'bus_parking',
    vanParking: 'van_parking',
    carParking: 'car_parking',

    // Towels/Showers/Laundry
    laundryOnsite: 'laundry_onsite',
    showersOnsite: 'showers_onsite',
    bathTowels: 'bath_towels',
    handTowels: 'hand_towels',

    // Estimates
    cateringEstimate: 'catering_estimate',
    handsEstimate: 'hands_estimate',
    productionEstimate: 'production_estimate',

    // Notes
    notes: 'notes',

    // Cash Needs
    cashNeeds: 'cash_needs'
  };

  // Normalize the data using field mappings
  const normalizedData = {};
  Object.entries(data).forEach(([key, value]) => {
    const mappedKey = fieldMappings[key] || key;
    normalizedData[mappedKey] = value?.toString()?.trim() || '';
  });

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers,
      body: JSON.stringify(normalizedData),
      credentials: 'same-origin'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || `Failed to update advance: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData.advance;
  } catch (error) {
    console.error('Error updating advance:', error);
    throw error;
  }
};

export const patchEventAdvance = async (eventId, data) => {
  if (!eventId) throw new Error('Event ID is required');
  if (!data || typeof data !== 'object') throw new Error('Invalid advance data format');

  const url = `/api/events/advance/${eventId}/`;
  const headers = await jsonHeaders(true);

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(data),
      credentials: 'same-origin'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || `Failed to patch advance: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData.advance;
  } catch (error) {
    console.error('Error patching advance:', error);
    throw error;
  }
};

export const getEventAdvanceSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};



