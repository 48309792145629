import { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import classNames from "classnames";
import { FormSelect, FormBuilder } from "@/components/Form";
import { companyChoices } from "@/utils/states";

// Components
import { Header } from "@/components/Header";
import { RecentItems } from "@/components/RecentItems/RecentItems";
import LoadingScreen from "@/components/LoadingScreen/LoadingScreen";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { HoldSwitcher } from "@/components/EventState/HoldSwitcher";
import { ChallengeHold } from "@/components/ChallengeHold";
import Button from "@/components/Button";
import EventAdvance from "@/components/EventDisplay/EventAdvance";
import EventState from "@/components/EventState";
import DuplicateEventButton from "@/components/DupeEvent";
import People from "@/components/People/People";
import { Expenses, RollUp } from "./accountingComponents";
import LinkDisplay from "@/components/Links/LinkDisplay";
import CommentDisplay from "@/components/Comments/CommentDisplay";
import FileDisplay from "@/components/Files/FileDisplay";
import ImageDisplay from "@/components/Images/ImageDisplay";
import EventPDF from "./EventPDF";
import { FilterDropdown } from "@/components/FilterDropdown/FilterDropdown";
import { SortedTable } from "@/components/SortedTable/SortedTable";
import { Wires } from "@/components/Wires/Wires";
import { ItemTable } from "@/components/ItemTable/ItemTable";
import { ManifestRow } from "@/components/ManifestRow/ManifestRow";

// Icons
import {
  PencilSquareIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";

// API queries
import {
  getEvent,
  getEventPeople,
  updateEvent,
  deleteEventPeople,
  getEventLinks,
  getEventLinkSettings,
  deleteEventLink,
  getEventComments,
  getEventCommentSettings,
  createEventComment,
  getEventFiles,
  getEventFileSettings,
  deleteEventFile,
  getEventImages,
  getEventImageSettings,
  deleteEventImage,
  createEventImages,
  getEventOfferVariables,
  getOffer,
  getOffers,
  createOffer,
  addEventToOffer,
  toggleEventMainPOC,
  recordTickets,
  postEventDuplicate,
} from "@/queries/events";

// Contexts and hooks
import { useLoading, LoadingProvider } from "@/contexts/LoadingContext";
import { useUser, usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import { useRecordVisit } from "@/queries/visit";

// Utils
import { amountDisplay, formatInputToMoney } from "@/utils/money";
import { localTime, formatTimeString } from "@/utils/datetime";
import { getEventRollups, getEventCopros } from "@/queries/accounting";

// Styles
import styles from "./EventDetail.module.css";

export const EventDetail = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (document.readyState === "complete" && event?.isSuccess) {
      setIsReady(true);
    }
  }, [event?.isSuccess]);

  return (
    <LoadingProvider>
      <EventDetailContent />
    </LoadingProvider>
  );
};

const ManifestRollup = (props) => {
  const { id, name, qty, comps, available, paid, total, toggleShowManifests } =
    props;
  return (
    <tr
      className="bg-green-100 cursor-pointer border-b dark:bg-[gray-800] dark:border-gray-700"
      key={id}
      onClick={() => toggleShowManifests()}
    >
      <td className="px-6 py-4 flex flex-inline justify-between">{name}</td>
      <td className="px-6 py-4">{qty}</td>
      <td className="px-6 py-4">{comps}</td>
      <td className="px-6 py-4"></td>
      <td className="px-6 py-4"></td>
      <td className="px-6 py-4">{available}</td>
      <td className="px-6 py-4">{paid}</td>
      <td className="px-6 py-4 text-right">{amountDisplay(total)}</td>
    </tr>
  );
};

const Manifests = (props) => {
  const { manifests, eventID, canEdit, facility_fee } = props;

  const [showManifests, setShowManifests] = useState(null);
  const [showTicketSalesTable, setShowTicketSalesTable] = useState(false);
  const [showTicketTypesTable, setShowTicketTypesTable] = useState(false);
  const [sortedManifests, setSortedManifests] = useState([...manifests]);
  const [sortKey, setSortKey] = useState(null);
  const sortOrder = useRef("asc");

  const navigate = useNavigate();
  const { perms } = usePerms();
  const { user } = useUser();

  useEffect(() => {
    if (showManifests == null && manifests.length > 4) {
      setShowManifests(false);
    }
    if (showManifests == null && manifests.length <= 4) {
      setShowManifests(true);
    }
  }, [manifests, eventID]);

  const toggleShowManifests = () => {
    setShowManifests(!showManifests);
  };

  // Calculate total tickts
  let total = 0;
  let totalValues = {
    qty: 0,
    comps: 0,
    platinum_lift: 0,
    available: 0,
    paid: 0,
  };

  manifests.map((manifest) => {
    if (manifest.active) {
      total = total + manifest.paid * manifest.price;
      totalValues.qty = totalValues.qty + manifest.qty;
      totalValues.comps = totalValues.comps + manifest.comps;
      totalValues.available = totalValues.available + manifest.available;
      totalValues.paid = totalValues.paid + manifest.paid;
    }
  });

  let tableBody = (
    <ManifestRollup
      key={0}
      name="All Tickets"
      total={total}
      toggleShowManifests={toggleShowManifests}
      {...totalValues}
    />
  );

  if (showManifests) {
    tableBody = manifests.map((manifest) => {
      if (manifest.active) {
        return <ManifestRow key={manifest.id} {...manifest} />;
      }
    });
  }

  const handleTicketTypesEdit = (rowData, rowIndex) => {
    navigate(`/events/manifest/edit/${rowData.id}?type=ticket`);
  };

  const handleTicketSalesEdit = (rowData, rowIndex) => {
    navigate(`/events/manifest/edit/${rowData.id}?type=sales`);
  };

  const formatManifests = (manifests) => {
    // calculate total for each manifest
    return (
      manifests &&
      manifests.map((manifest) =>
        Object.assign(manifest, {
          total: (manifest.price + manifest?.ticket_fees) * manifest.qty,
          total_price: manifest.price + manifest?.ticket_fees,
          facility_fee: facility_fee,
        })
      )
    );
  };

  const canEditManifests = canEdit && user?.data?.role !== "marketing";

  return (
    <>
      <div className="flex xs:flex-col sm:flex-row xs:items-center w-full mb-4 mt-12 flex justify-between border-[#595959] pt-[1rem]">
        <h3
          id="manifests"
          className="float-left text-2xl font-semibold text-gray-900 dark:text-cave-white"
        >
          {/* Manifests */}
        </h3>
        {canEditManifests && (
          <Button
            onClick={() => {
              navigate(`/events/manifest/add/${eventID}`);
            }}
          >
            Add Manifest
          </Button>
        )}
      </div>
      <SortedManifestTable
        manifests={formatManifests(manifests)}
        eventID={eventID}
        headers={[
          { header: "Name", accessor: "name" },
          { header: "Qty", accessor: "qty" },
          { header: "Comps", accessor: "comps" },
          { header: "On Sale", accessor: "on_sale" },
          { header: "Ticket Fees", accessor: "facility_fee", type: "money" },
          { header: "Price", accessor: "total_price", type: "money" },
          { header: "Available", accessor: "available" },
          { header: "Paid", accessor: "paid" },
          { header: "Total", accessor: "total", type: "money" },
        ]}
        type={"TICKET"}
        canEdit={canEditManifests}
        title={"Ticket Types"}
        handleEditClick={handleTicketTypesEdit}
      />

      <SortedManifestTable
        manifests={formatManifests(manifests)}
        eventID={eventID}
        headers={[
          { header: "NAME", accessor: "name" },
          { header: "TM", accessor: "tm" },
          { header: "ETix", accessor: "etix" },
          { header: "Venue", accessor: "venue" },
          { header: "Fan Club", accessor: "fan_club" },
          { header: "Outlets", accessor: "outlets" },
          { header: "VIP", accessor: "vip" },
        ]}
        type={"SALES"}
        canEdit={canEditManifests}
        title={"Ticket Sales"}
        handleEditClick={handleTicketSalesEdit}
      />
    </>
  );
};

const SortedManifestTable = ({
  manifests,
  headers,
  total,
  canEdit,
  title,
  handleEditClick,
}) => {
  const handleFormatCell = (accessor, rowData, rowIndex) => {
    if (accessor === "on_sale") {
      if (rowData["on_sale"]) {
        let dt = dayjs(rowData["on_sale"]);
        if (dt.isBefore(dayjs("2000-01-01"))) {
          return "No Date";
        } else {
          return dt.format("MMM D, YYYY");
        }
      } else {
        return "No Date";
      }
    }
    // return nothing for all other values
  };

  const handleSort = (key, item) => {
    if (key === "total") {
      return item["price"] * item["qty"];
    } else {
      return item[key];
    }
  };

  return (
    <SortedTable
      total={total}
      hasFilter={true}
      data={manifests}
      headers={headers}
      title={title}
      customSort={handleSort}
      formatCell={handleFormatCell}
      onEditClick={canEdit ? handleEditClick : null}
      excludeDarkMode={true}
    />
  );
};

const ManifestFilter = ({
  filterOptions,
  data,
  onFilteredData,
  parentFilters,
  updateFilters,
}) => {
  const [filters, setFilters] = useState(parentFilters);
  const [filteredData, setFilteredData] = useState(
    filterInitialData(parentFilters)
  );

  useEffect(() => {
    setFilters(parentFilters);
  }, [parentFilters]);

  useEffect(() => {
    onFilteredData(filteredData);
  }, [filteredData, onFilteredData]);

  function filterInitialData(filters) {
    return filterData(filters, data);
  }

  const applyFilters = (newFilters) => {
    // update local state
    setFilters(newFilters);
    // update parent state - tracks filters so when table is opened after closed, the filter state will persist
    updateFilters(newFilters);
    let newData = [...data];
    setFilteredData(filterData(newFilters, newData));
  };

  function filterData(newFilters, newData) {
    newFilters.forEach((filter) => {
      if (filter.field.value && filter.type && filter.value) {
        newData = newData.filter((row) => {
          const itemToFilter =
            row[filter.field.value.toLowerCase().replace(" ", "_")];
          switch (filter.type) {
            case "contains":
              return itemToFilter
                .toString()
                .toLowerCase()
                .includes(filter.value.toLowerCase());
            case "does not contain":
              return !itemToFilter
                .toString()
                .toLowerCase()
                .includes(filter.value.toLowerCase());
            case "equals":
              return itemToFilter.toString() === filter.value.toString();
            case "greater_than":
              return parseFloat(itemToFilter) > parseFloat(filter.value);
            case "less_than":
              return parseFloat(itemToFilter) < parseFloat(filter.value);
            case "is_before":
              return new Date(itemToFilter) < new Date(filter.value);
            case "is_after":
              return new Date(itemToFilter) > new Date(filter.value);
            default:
              return true;
          }
        });
      }
    });
    return newData;
  }

  return (
    <FilterDropdown
      parentFilters={filters}
      options={filterOptions}
      onFilterUpdate={applyFilters}
    />
  );
};

const CoPros = (props) => {
  const { id } = useParams();
  const {
    loadingState,
    setLoadingState,
    loadError,
    videoLoaded,
    setVideoLoaded,
    progress,
    setProgress,
  } = useLoading();
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const copros = useQuery(["event-copros", id], () => getEventCopros(id));
  const queryClient = useQueryClient();
  const videoRef = useRef(null);

  useEffect(() => {
    if (event.isSuccess) {
      // Minimum 2 second loading state before fade starts
      setTimeout(() => {
        setLoadingState("fadeOut");
        setTimeout(() => {
          setLoadingState("complete");
        }, 1000); // 1 second fade out
      }, 2000); // 2 second minimum loading state
    }
  }, [event.isSuccess]);

  if (event.isLoading || copros.isLoading) {
    return (
      <div className="relative min-h-screen bg-cave-white dark:bg-gray-900">
        <div
          className={`fixed inset-0 z-50 transition-all duration-1000 ease-in-out
          ${loadingState === "fadeOut" ? "opacity-0" : "opacity-100"}`}
        >
          {/* Video Preloader Status */}
          <div
            className={`absolute inset-0 bg-black flex items-center justify-center
            transition-opacity duration-500
            ${videoLoaded ? "opacity-0 pointer-events-none" : "opacity-100"}`}
          ></div>
        </div>
      </div>
    );
  }

  let display_copros = [];
  if (event.data.is_building_copro) {
    display_copros.push(event.data.venue.name);
  }

  copros.data.map((copro) => {
    display_copros.push(copro.name);
  });

  return (
    <p className="mb-4 border-t-[1px] border-[#595959] pt-[1rem]">
      <span className="font-semibold mr-2 dark:text-[#FFEBE6]">CoPros:</span>
      <span className="dark:text-[#FFEBE6]">{display_copros.join(", ")}</span>
    </p>
  );
};

const AgeTimeInfo = (props) => {
  const { event, canEdit, isAdmin } = props;
  const queryClient = useQueryClient();

  const companyMutation = useMutation({
    mutationFn: (newCompany) => {
      return updateEvent(event.id, { company: newCompany });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail", event.id]);
      toast.success("Company updated successfully");
    },
    onError: () => {
      toast.error("Failed to update company");
    },
  });

  if (!event.age_requirement && !event.door_time) {
    return null;
  }

  let ageText = null;
  switch (event.age_requirement) {
    case "all-ages":
      ageText = "All Ages";
      break;
    case "13-up":
      ageText = "13+";
      break;
    case "14-up":
      ageText = "14+";
      break;
    case "15-up":
      ageText = "15+";
      break;
    case "16-up":
      ageText = "16+";
      break;
    case "17-up":
      ageText = "17+";
      break;
    case "18-up":
      ageText = "18+";
      break;
    case "19-up":
      ageText = "19+";
      break;
    case "20-up":
      ageText = "20+";
      break;
    case "21-up":
      ageText = "21+";
      break;
  }

  let timeText = null;
  if (event.door_time) {
    const timeParts = event.door_time.split(":");
    const hour = parseInt(timeParts[0]);
    const minute = timeParts[1];
    let amPm = "AM";
    if (hour > 12) {
      amPm = "PM";
      timeText = `${hour - 12}:${minute} ${amPm}`;
    } else {
      timeText = `${hour}:${minute} ${amPm}`;
    }
  }

  return (
    <>
      <div className="my-2 text-gray-500">
        Company:
        {isAdmin ? (
          <FormBuilder onSubmit={() => {}} className="inline-block ml-2">
            <FormSelect
              name="company"
              options={companyChoices}
              value={companyChoices.find((c) => c.value === event.company)}
              defaultValue={event.company}
              onChange={(option) => companyMutation.mutate(option.value)}
              className="w-48"
              isSearchable={false}
              placeholder={event.company}
            />
          </FormBuilder>
        ) : (
          <span className="font-bold uppercase ml-2">{event.company}</span>
        )}
      </div>
      <h4 id="section4" className="my-2 text-gray-500">
        {ageText && `${ageText} - `}
        {timeText && `Doors at ${timeText}`}
      </h4>
      <h1 className="my-2 text-2xl text-black">
        <span className="text-md text-bold dark:text-cave-white">Buyer: </span>
        <span className="text-2xl text-bold dark:text-cave-white">
          {event.buyer.first_name} {event.buyer.last_name}
        </span>
      </h1>
    </>
  );
};

const EventConfirmation = ({ id }) => {
  const { user } = useUser();
  const { perms } = usePerms();
  const { loadingState, setLoadingState, loadError } = useLoading();
  const videoRef = useRef(null);
  const [grossPotential, setGrossPotential] = useState(0);
  const [ticketFees, setTicketFees] = useState(0);
  const [expandedTables, setExpandedTables] = useState({});

  const event = useQuery(["event-detail", id], () => getEvent(id));
  const variables = useQuery(["variables-offer-pdf", id], () =>
    getEventOfferVariables(id), {
      onError: (error) => {
        toast.error(error.message || "An unknown error occurred", {
          position: "top-center", 
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          position: "top-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
      retry: false, // Don't retry on validation errors
    }
  );
  const rollups = useQuery(["event-rollups", id], () => getEventRollups(id));
  const copros = useQuery(["event-copros", id], () => getEventCopros(id));

  const toggleTable = (tableId) => {
    setExpandedTables((prev) => ({
      ...prev,
      [tableId]: !prev[tableId],
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    if (event && event.data) {
      setGrossPotential(
        event.data.manifests.reduce(
          (total, manifest) =>
            total + (manifest.price + manifest?.ticket_fees) * manifest.qty,
          0
        )
      );
      setTicketFees(
        event.data.manifests.reduce(
          (total, manifest) => total + manifest.qty * manifest?.ticket_fees,
          0
        )
      );
    }
  }, [event?.data]);

  if (
    event.isLoading ||
    variables.isLoading ||
    rollups.isLoading ||
    copros.isLoading
  ) {
    return (
      <div className="relative min-h-screen bg-cave-white dark:bg-gray-900">
        <div
          className={`fixed inset-0 z-50 transition-opacity duration-1000
          ${loadingState === "fadeOut" ? "opacity-0" : "opacity-100"}`}
        >
          <video
            ref={videoRef}
            className="absolute inset-0 w-full h-full object-cover"
            autoPlay
            muted
            playsInline
            loop={false}
            onEnded={() => setLoadingState("fadeOut")}
          >
            <source src="/static/images/cave_loading3.mp4" type="video/mp4" />
          </video>

          {/* Fallback to logo if video fails */}
          {loadError && <CompanyLogoAnimation />}
        </div>
      </div>
    );
  }

  if (event.isError || variables.isError || rollups.isError || copros.isError) {
    return <div>Error Occurred</div>;
  }

  // constants

  const MAMMOTH_SOFT_MERCH = 100 - event.data.artist_soft_merch_percentage;
  const MAMMOTH_HARD_MERCH = 100 - event.data.artist_hard_merch_percentage;

  // handlers

  const handleEditButtonPress = () => {
    navigate(`/events/edit/offer/${event.data.id}`);
  };

  return (
    // Event Confirmation Return Section
    <div className="eventConfirmationWrapper border-t-[1px] border-[#595959] pt-[1rem]">
      <div className="flex flex-row justify-between items-start md:items-center mb-4">
        <h2
          id="section2"
          className="text-lg sm:text-xl md:text-2xl font-bold mb-2 md:mb-0 xs:mx-auto md:mx-1 dark:text-cave-white"
        >
          Event Confirmation
        </h2>
        {event.isSuccess && event.data.buyer.id === user.data.id && (
          <button
            className="ml-0 md:ml-3 mt-2 md:mt-0 inline-flex items-center rounded-md bg-cave-blue3 px-3 py-2 text-sm font-semibold text-cave-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
            onClick={handleEditButtonPress}
          >
            Edit Event
          </button>
        )}
      </div>

      <div className="grid grid-cols-12 grid-rows-48 gap-4">
        <div
          className={classNames(
            `col-start-1 lg:col-span-6 col-span-12 row-start-1 row-span-2 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["offerInfo"],
              [styles.collapsed]: !expandedTables["offerInfo"],
            }
          )}
          onClick={() => toggleTable("offerInfo")}
        >
          <ItemTable
            columnWidths={
              window.innerWidth < 640
                ? ["110px", "150px"]
                : window.innerWidth < 1024
                ? ["110px", "150px"]
                : ["110px", "150px"]
            }
            header={{ value: "OFFER INFORMATION" }}
            rows={[
              {
                header: "AGENT",
                value: event.data.offer_agent,
                editable: false,
              },
              {
                header: "BUYER",
                value: variables.data.buyer_name,
                editable: false,
              },
              {
                header: "HEADLINER",
                value: event.data.offer_headliner,
                editable: false,
              },
              {
                header: "SUPPORT 1",
                value: event.data.offer_support_1,
                editable: false,
              },
              {
                header: "SUPPORT 2",
                value: event.data.offer_support_2,
                editable: true,
              },
              {
                header: "OPENER",
                value: event.data.offer_opener,
                editable: true,
              },
              {
                header: "BILLING",
                value: event.data.offer_billing,
                editable: true,
              },
              {
                header: "STIPULATIONS",
                value: event.data.offer_stipulations,
                editable: true,
              },
              {
                header: "AVAILS 1",
                value: event.data.offer_avails_1,
                editable: false,
              },
              {
                header: "AVAILS 2",
                value: event.data.offer_avails_2,
                editable: false,
              },
            ]}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
            showTable={expandedTables["offerInfo"] || false}
            showChevrons={true}
            tableId="offerInfo"
            onToggle={toggleTable}
          />
        </div>

        <div
          className={classNames(
            `col-start-1 lg:col-start-7 col-span-12 lg:col-span-6 row-start-3 lg:row-start-1 row-span-2 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["venueInfo"],
              [styles.collapsed]: !expandedTables["venueInfo"],
            }
          )}
          onClick={() => toggleTable("venueInfo")}
        >
          <ItemTable
            columnWidths={
              window.innerWidth < 640
                ? ["110px", "150px"]
                : window.innerWidth < 1024
                ? ["110px", "150px"]
                : ["110px", "150px"]
            }
            header={{ value: "VENUE INFORMATION" }}
            rows={[
              {
                header: "AGE REQUIREMENT",
                value: event.data.age_requirement,
                accessor: "age_requirement",
                format: "select",
                editable: false,
              },
              {
                header: "MERCH",
                value: `${event.data.artist_soft_merch_percentage} / ${event.data.artist_hard_merch_percentage}`,
                accessor: "artist_soft_merch_percentage",
                editable: false,
              },
              {
                header: "WHO SELLS",
                value: event.data.who_sells_merch,
                accessor: "who_sells_merch",
                format: "select",
                editable: false,
              },
            ]}
            info={`${event.data.venue.name}\n${event.data.venue.address}\n${
              event.data.venue.city
            }, ${event.data.venue.state} ${event.data.venue.zipcode}\nPh: ${
              event.data.venue.phone ? event.data.venue.phone : "N/A"
            }\n`}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
            showTable={expandedTables["venueInfo"] || false}
            showChevrons={true}
            tableId="venueInfo"
            onToggle={toggleTable}
          />
        </div>

        <div
          className={classNames(
            `col-start-1 col-span-12 lg:col-span-4 lg:row-start-3 row-start-5 row-span-1 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["coPromoters"],
              [styles.collapsed]: !expandedTables["coPromoters"],
            }
          )}
          onClick={() => toggleTable("coPromoters")}
        >
          <ItemTable
            header={{ value: "CO-PROMOTERS" }}
            rows={copros.data.map((copro) => ({
              header: `Co-Pro ${copro.id}`,
              value: copro.name,
              editable: false,
            }))}
            info={copros.data.length === 0 ? "No co-promoters" : ""}
            showTable={expandedTables["coPromoters"] || false}
            showChevrons={true}
            tableId="coPromoters"
            onToggle={toggleTable}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
          />
        </div>

        <div
          className={classNames(
            `lg:col-start-5 col-start-1 col-span-12 lg:col-span-4 row-start-6 lg:row-start-3 row-span-1 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["totals"],
              [styles.collapsed]: !expandedTables["totals"],
            }
          )}
          onClick={() => toggleTable("totals")}
        >
          <ItemTable
            columnWidths={
              window.innerWidth < 640
                ? ["110px", "150px"]
                : window.innerWidth < 1024
                ? ["110px", "150px"]
                : ["110px", "150px"]
            }
            header={{ value: "TOTALS" }}
            rows={[
              {
                header: "TOTAL CAPACITY",
                value: rollups.data.capacity,
                editable: false,
              },
              {
                header: "GROSS POTENTIAL",
                value: amountDisplay(grossPotential),
                editable: false,
              },
              {
                header: "TICKET FEES",
                value: amountDisplay(ticketFees),
                editable: false,
              },
              {
                header: "ADJUSTED GROSS",
                value: amountDisplay(variables.data.adjusted_gross),
                editable: false,
              },
              {
                header: "TAX PERCENTAGE",
                value: `${event.data.venue.sales_tax}%`,
                highlight: true,
                editable: false,
              },
              {
                header: "TOTAL TAX",
                value: amountDisplay(variables.data.total_tax),
                editable: false,
              },
              {
                header: "NET POTENTIAL",
                value: amountDisplay(variables.data.net_potential),
                editable: false,
              },
            ]}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
            showTable={expandedTables["totals"] || false}
            showChevrons={true}
            tableId="totals"
            onToggle={toggleTable}
          />
        </div>

        <div
          className={classNames(
            // sm:col-start-5 col-start-1 col-span-12 sm:col-span-4 row-start-5 sm:row-start-3 row-span-1
            `lg:col-start-9 col-start-1 lg:col-span-4 col-span-12 lg:row-start-3 row-start-11 row-span-1 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["setTimes"],
              [styles.collapsed]: !expandedTables["setTimes"],
            }
          )}
          onClick={() => toggleTable("setTimes")}
        >
          <ItemTable
            columnWidths={
              window.innerWidth < 640
                ? ["110px", "150px"]
                : window.innerWidth < 1024
                ? ["110px", "150px"]
                : ["110px", "150px"]
            }
            header={{ value: "SET TIMES" }}
            rows={[
              {
                header: "DOORS",
                value: localTime(event.data.door_time),
                editable: true,
              },
              {
                header: "OPENERS",
                value: localTime(event.data.opener_time),
                editable: true,
              },
              {
                header: "SUPPORT",
                value: localTime(event.data.support_time),
                editable: true,
              },
              {
                header: "HEADLINER",
                value: localTime(event.data.headliner_time),
                highlight: true,
                editable: true,
              },
              {
                header: "CURFEW",
                value: localTime(event.data.curfew_time),
                editable: false,
              },
            ]}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
            showTable={expandedTables["setTimes"] || false}
            showChevrons={true}
            tableId="setTimes"
            onToggle={toggleTable}
          />
        </div>
        <div
          className={classNames(
            `col-start-1 col-span-12 row-start-34 row-span-1 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["contactInfo"],
              [styles.collapsed]: !expandedTables["contactInfo"],
            }
          )}
          onClick={() => toggleTable("contactInfo")}
        >
          <ItemTable
            columnWidths={
              window.innerWidth < 640
                ? ["60px", "150px"]
                : window.innerWidth < 1024
                ? ["110px", "150px"]
                : ["110px", "150px"]
            }
            header={{ value: "CONTACT INFO" }}
            rows={[
              {
                header: "PRODUCTION ADV.",
                value: "production@mammothlive.com",
                editable: false,
              },
              { header: "LOCAL MARKETING", value: "", editable: true },
              {
                header: "TICKETING",
                value: "ticketing@mammothlive.com",
                editable: false,
              },
              { header: "TOUR ADMAT", value: "", editable: true },
              {
                header: "TOUR MARKETING",
                value: "",
                highlight: true,
                editable: true,
              },
              { header: "ON SALE INFO", value: "", editable: true },
              { header: "PRE SALE INFO", value: "", editable: true },
              {
                header: "CONTRACTS",
                value: "contracts@mammothlive.com",
                editable: false,
              },
              { header: "TOUR MANAGER", value: "", editable: true },
              { header: "TOUR PRODUCTION", value: "", editable: true },
            ]}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
            showTable={expandedTables["contactInfo"] || false}
            showChevrons={true}
            tableId="contactInfo"
            onToggle={toggleTable}
            long
          />
        </div>
      </div>
    </div>
  );
};

const EventDetailContent = () => {
  // Hooks
  const { id } = useParams();
  const [isReady, setIsReady] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { perms } = usePerms();
  const { user } = useUser();
  const visitMutation = useRecordVisit();

  const videoRef = useRef(null);
  const [isManifestsOpen, setIsManifestsOpen] = useState(false);
  const [books, setBooks] = useState(null);
  const [expandedTables, setExpandedTables] = useState({});
  const [editMode, setEditMode] = useState(false);

  // Queries
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const people = useQuery(["event", "people", id], () => getEventPeople(id));
  const variables = useQuery(["variables-offer-pdf", id], () =>
    getEventOfferVariables(id)
  );
  // Can this user edit this event?
  let canEdit = false;

  // Is the User an Admin role?
  let isAdmin = false;

  // Can this user view these event numbers?
  let canSeeNumbers = false;

  let onlyMarketing = false;
  let canSeeFinalNumbers = false;

  const toggleManifests = () => {
    setIsManifestsOpen(!isManifestsOpen);
  };

  const links = useQuery(
    ["event", "links", id],
    () => getEventLinks(id),
    getEventLinkSettings
  );
  const comments = useQuery(
    ["event", "comments", id],
    () => getEventComments(id),
    getEventCommentSettings
  );
  const files = useQuery(
    ["event", "files", id, onlyMarketing],
    () => getEventFiles(id, onlyMarketing),
    getEventFileSettings
  );
  //  const files = useQuery(
  //    ["event", "files", id],
  //    () => getEventFiles(id),
  //    getEventFileSettings
  //  );
  const images = useQuery(
    ["event", "images", id],
    () => getEventImages(id),
    getEventImageSettings
  );

  usePageTitle(
    event.isSuccess
      ? `/c${String.fromCharCode(92)}${event.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  // Comments
  const commentMutation = useMutation({
    mutationFn: (data) => {
      return createEventComment(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["event", "comments", id]);
    },
  });

  const mutation = useMutation((personId) => deleteEventPeople(personId), {
    onSuccess: (data, personId) => {
      if (data.success) {
        // Invalidate and refetch
        queryClient.invalidateQueries("event", "people", id);
        toast.success("Person removed successfully.");
      } else {
        toast.error("Failed to remove the person.");
      }
    },
    onError: (error) => {
      console.error("Error removing person:", error);
      toast.error("An error occurred while removing the person.");
    },
  });

  const toggleMainPOC = useMutation(
    (personId) => toggleEventMainPOC(personId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("eventPeople");
      },
      onError: (error) => {
        console.error("Error toggling POC:", error);
        toast.error("An error occurred while toggling the main POC.");
      },
    }
  );

  const removePerson = (personId) => {
    if (
      window.confirm(
        "Are you sure you want to remove this person from the event?"
      )
    ) {
      mutation.mutate(personId);
    }
  };
  const handleCommentSubmit = (data) => {
    commentMutation.mutate(data);
  };

  const handleBookChange = (value) => {
    setBooks(value);
    queryClient.invalidateQueries(["expenses"]);
    queryClient.invalidateQueries(["expense-rollups"]);
  };

  // Handle successful event load
  useEffect(() => {
    if (!event.isSuccess) return;

    // Record visit
    visitMutation.mutate({
      itemID: event.data.id,
      itemType: "event",
      itemURL: `/events/${event.data.id}`,
    });

    // Set initial books state
    if (books === null) {
      setBooks(event.data.expenses_are_offer ? "offer" : "actual");
      queryClient.invalidateQueries(["expenses"]);
      queryClient.invalidateQueries(["expense-rollups"]);
    }

    // Handle loading state
  }, [event.isSuccess]);

  if (event.isLoading || user.isLoading) {
    return (
      <div className="relative min-h-screen bg-cave-white dark:bg-gray-900">
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div className="relative w-24 h-24 mb-16">
            <div className="absolute inset-0 border-8 border-[#FFFBE6] rounded-full animate-[ping_4s_ease-in-out_infinite] z-[2]"></div>
            <div className="absolute inset-0 border-8 border-[#3F3F3F] rounded-full animate-[pulse_4s_ease-in-out_infinite] z-[2]"></div>
            <div className="absolute inset-0 flex items-center justify-center z-[1]">
              <img
                src="/static/images/company-logo-light-bg.svg"
                className="h-12 w-auto dark:hidden animate-[pulse_4s_ease-in-out_infinite]"
                alt="Loading"
              />
              <img
                src="/static/images/company-logo-black-bg.svg"
                className="hidden h-12 w-auto dark:block animate-[pulse_4s_ease-in-out_infinite]"
                alt="Loading"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (perms.data.edit_events && event.data.buyer == user.data.id) {
    canEdit = true;
    canSeeNumbers = true;
    onlyMarketing = false;
  }
  const getEventDate = event.isSuccess ? `[${event.data.date}]` : "";
  // Special display for holds the user is not allowed to see
  if (
    event.data.state == "hold" &&
    (event.data.buyer != user.data.id || !perms.data.view_holds)
  ) {
    return (
      <>
        <Header />
        <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
          <BreadCrumbs
            links={[
              { text: "Events", url: `/events/` },
              {
                text: `${event.data.name} ${getEventDate}`,
                url: `/events/${event.data.id}`,
              },
              { text: "Details" },
            ]}
          />
          <div className="pt-6 pb-2 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2
                id="section2"
                className="text-2xl font-bold leading-7 dark:text-cave-white sm:truncate sm:text-3xl sm:tracking-tight"
              >
                {`${event.data.company} Hold`}
              </h2>
              <h3 id="section3" className="my-2 text-gray-500">
                {dayjs(event.data.date).format("dddd MMM D, YYYY")} @{" "}
                {event.data.venue &&
                  `${event.data.venue.name} - ${event.data.venue.city}, ${event.data.venue.state}`}
              </h3>
              <AgeTimeInfo event={event.data} isAdmin={isAdmin} />
            </div>
          </div>
          <div>
            {event.isSuccess && event.data.buyer.id == user.data.id && (
              <HoldSwitcher eventId={event.data.id} />
            )}
            {event.isSuccess && event.data.buyer.id != user.data.id && (
              <div className="w-full p-6 dark:bg-cave-black dark:text-cave-white text-2xl border-2 border-color-cave-orange">
                There is a hold on this venue for this date from another buyer.
                <br />
                <ChallengeHold event={event} user={user} />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  if (
    people.isSuccess &&
    people.data &&
    Array.isArray(people.data) &&
    people.data.length > 0
  ) {
    let debugInfo = [];
    const matchedPerson = people.data.find((person, index) => {
      const personLog = {
        index: index,
        person: JSON.parse(JSON.stringify(person)),
        userIdMatch: person.user_id === user.data.id,
        userDataId: user.data.id,
        personUserId: person.user_id,
      };
      return (
        person.user_id === user.data.id && person.role_name === "Promoter Rep"
      );
    });
    if (matchedPerson) {
      const isPromoterRep = !!matchedPerson;

      canEdit = true;
      canSeeNumbers = true;
    }
  }

  if (event.isSuccess) {
  }
  if (perms.data.edit_marketing) {
    canEdit = true;
    canSeeNumbers = true;
    onlyMarketing = true;
    isAdmin = false;
  }
  if (
    ["tier_admin", "admin", "accounting_admin", "accounting"].includes(
      user.data.role
    )
  ) {
    canEdit = true;
    canSeeNumbers = true;
    onlyMarketing = false;
    isAdmin = true;
  }

  const handleCreateImage = (data) => {
    return createEventImages(id, data);
  };

  if (
    user.data.role === "admin" ||
    user.data.role === "tier_admin" ||
    user.data.role === "legal"
  ) {
    canSeeFinalNumbers = true;
  } else {
    canSeeFinalNumbers = false;
  }
  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0 ml-2
    "
      >
        <BreadCrumbs
          links={[
            { text: "Events", url: "/events" },
            { text: event.data.name },
          ]}
        />

        <div className="pt-6 pb-2 md:flex md:items-center md:justify-between ">
          <div className="min-w-0 flex-8">
            <h2 className="text-2xl font-bold leading-7 dark:text-cave-white sm:truncate sm:text-3xl sm:tracking-tight">
              {event.data.name}
            </h2>
            <h2 className="p-4">
              {event.data.highlight && (
                <img src={event.data.highlight} width="300" height="200" />
              )}
            </h2>
            <h3 className="my-2 text-gray-500">
              {dayjs(event.data.date).format("dddd MMM D, YYYY")} @{" "}
              {event.data.venue &&
                `${event.data.venue.name} - ${event.data.venue.city}, ${event.data.venue.state}`}
            </h3>
            <AgeTimeInfo
              event={event.data}
              canEdit={canEdit}
              isAdmin={isAdmin}
            />
          </div>
          <div className="w-1/3 container p-2 m-4">
            <div className="grid grid-cols-3 gap-y-2 gap-x-40">
              {event.isSuccess &&
                (event.data.buyer.id === user.data.id ||
                  perms.data.edit_event ||
                  perms.data.is_admin) && (
                  <>
                    <div
                      className="text-cave-white font-bold rounded bg-cave-blue3 hover:bg-cave-green text-cave-white font-bold uppercase rounded-lg shadow-md flex items-center justify-center h-10 w-48 transition-colors duration-300 ease-in-out cursor-pointer mb-[-2rem] md:mb-0"
                      onClick={() =>
                        navigate(`/events/settlement/artist/${event.data.id}/`)
                      }
                    >
                      Edit Settlement
                    </div>
                  </>
                )}
              {((event.isSuccess && event.data.buyer.id === user.data.id) ||
                perms.data.edit_event ||
                perms.data.is_admin) &&
                perms.data.onlyMineEdit === true && (
                  <>
                    <div
                      className="bg-cave-blue3 hover:bg-cave-green
              text-cave-white font-bold uppercase
              p-4 rounded-lg shadow-md
              flex items-center justify-center
              h-10 w-48 transition-colors duration-300 ease-in-out
              cursor-pointer"
                    >
                      <a
                        onClick={() =>
                          navigate(`/events/edit/offer/${event.data.id}`)
                        }
                      >
                        Edit Offer
                      </a>
                    </div>
                  </>
                )}
              {((event.isSuccess && event.data.buyer.id === user.data.id) ||
                perms.data.edit_event ||
                perms.data.is_admin) &&
                perms.data.onlyMineEdit === true && (
                  <>
                    <div
                      className="bg-cave-blue3 hover:bg-cave-green
              text-cave-white font-bold uppercase
              p-4 rounded-lg shadow-md
              flex items-center justify-center
              h-10 w-48 transition-colors duration-300 ease-in-out
              cursor-pointer"
                    >
                      <div className="text-cave-white font-bold py-2 px-4 rounded">
                        <a
                          onClick={() =>
                            navigate(`/events/edit/${event.data.id}`)
                          }
                        >
                          Edit Event
                        </a>
                      </div>
                    </div>
                  </>
                )}
              <div>
                {((event.isSuccess && event.data.buyer.id === user.data.id) ||
                  perms.data.edit_event ||
                  perms.data.is_admin ||
                  perms.data.onlyMineEdit === false) && (
                  <DuplicateEventButton eventId={id} />
                )}
              </div>
              {event.isSuccess &&
                (event.data.buyer.id === user.data.id ||
                  perms.data.edit_event ||
                  perms.data.editAllFiles === true ||
                  perms.data.is_admin) && (
                  <>
                    <div
                      className="bg-cave-blue3 hover:bg-cave-green
              text-cave-white font-bold uppercase
              p-4 rounded-lg shadow-md
              flex items-center justify-center
              h-10 w-48 transition-colors duration-300 ease-in-out
              cursor-pointer"
                    >
                      <a href="#files">Files</a>
                    </div>
                  </>
                )}

              <div
                className="bg-cave-blue3 hover:bg-cave-green
              text-cave-white font-bold uppercase
              p-4 rounded-lg shadow-md
              flex items-center justify-center
              h-10 w-48 transition-colors duration-300 ease-in-out
              cursor-pointer"
              >
                <a href="#people">People</a>
              </div>
              <div
                className="bg-cave-blue3 hover:bg-cave-green
              text-cave-white font-bold uppercase
              p-4 rounded-lg shadow-md
              flex items-center justify-center
              h-10 w-48 transition-colors duration-300 ease-in-out
              cursor-pointer"
              >
                <a href="#advance">Advance</a>
              </div>
            </div>
          </div>
        </div>
        <div className="my-6">
          <ImageDisplay
            images={images}
            createImage={handleCreateImage}
            deleteQuery={deleteEventImage}
            editable={perms.data.edit_events}
            entityType="events"
            id={event.data.id}
          />
        </div>

        {event.data.artists && event.isSuccess && (
          <div className="mb-4 border-t-[1px] border-[#595959] pt-[1rem]">
            <span className="font-semibold mr-2 dark:text-[#FFFBE6]">
              Artists&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|:-
              {event.data.artists
                .map((artist) => (
                  <a
                    key={artist.id}
                    href={`/artists/${artist.id}`}
                    className="text-blue-500 hover:underline"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{artist.name}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </a>
                ))
                .reduce((prev, curr) => [prev, "-:|:-", curr])}{" "}
              -:|
            </span>
          </div>
        )}
        <CoPros />
        {event.data.description && (
          <p className="mb-4 dark:text-gray-500 ">{event.data.description}</p>
        )}

        <div className="mb-4 dark:text-[#FFFBE6]">
          <EventState state={event.data.state} />
        </div>

        <EventConfirmation id={id} />

        <div className="mt-[2rem] border-t-[1px] border-[#595959] py-[1rem]">
          <button
            className={`flex justify-between items-center w-full px-4 py-2 rounded-[10px] bg-[#4E6B85] text-cave-white text-lg font-semibold cursor-pointer mt-[1.5rem] ${styles.chevronAccordionDiv}`}
            onClick={toggleManifests}
          >
            <span className="m-auto">Ticketing Manifests</span>
            {isManifestsOpen ? (
              <ChevronDownIcon className={styles.chevronAccordionDown} />
            ) : (
              <ChevronUpIcon className={styles.chevronAccordionUp} />
            )}
          </button>
          {isManifestsOpen && (
            <div>
              <Manifests
                manifests={event.data.manifests}
                eventID={event.data.id}
                canEdit={canEdit}
                facility_fee={event.data.facility_fee}
              />
            </div>
          )}
        </div>
        <div className="py-6">
          <CommentDisplay
            comments={comments}
            handleAddComment={handleCommentSubmit}
          />
        </div>
        <div id="advance" className="py-6">
          {console.log(
            "EventDetail - eventData being passed to EventAdvance:",
            event.data
          )}
          <EventAdvance
            id={id}
            eventData={event.data}
            variablesData={variables.data}
          />
        </div>

        {canSeeNumbers && (
          <>
            <Expenses
              eventID={id}
              event={event.data}
              books={books}
              setBooks={handleBookChange}
              onlyMarketing={onlyMarketing}
            />

            <RollUp
              eventID={id}
              event={event.data}
              books={books}
              onlyMarketing={onlyMarketing}
            />
          </>
        )}

        {perms.data.view_wires && (
          <div className="mt-[2rem] border-t-[1px] border-[grey]">
            <Wires />
          </div>
        )}

        {event.isSuccess &&
          ((event.data.buyer.id === user.data.id &&
            perms.data.onlyMineEdit === true) ||
            perms.data.editAllFiles === true ||
            perms.data.is_admin === true ||
            onlyMarketing === true) && (
            <>
              <div
                id="people"
                className="my-6 mt-12 border-t-[1px] border-[#595959] pt-[1rem]"
              >
                <People
                  people={people}
                  toggleEventMainPOC={toggleEventMainPOC}
                  removePerson={removePerson}
                  addURL={`/events/people/add/${event.data.id}`}
                  itemType="event"
                  editable={perms.data.edit_people}
                />
              </div>
            </>
          )}
        {event.isSuccess &&
          ((event.data.buyer.id === user.data.id &&
            perms.data.onlyMineEdit === true) ||
            perms.data.editAllFiles === true ||
            perms.data.is_admin) && (
            <>
              <div className="flex flex-col my-6 border-t-[1px] border-[#595959] pt-[1rem]">
                <LinkDisplay
                  links={links}
                  addURL={`/events/link/add/${id}`}
                  deleteQuery={deleteEventLink}
                  editable={true}
                />
              </div>
            </>
          )}

        {event.isSuccess &&
          ((event.data.buyer.id === user.data.id &&
            perms.data.onlyMineEdit === true) ||
            perms.data.editAllFiles === true ||
            perms.data.is_admin ||
            onlyMarketing) && (
            <>
              <div
                id="files"
                className="flex flex-col my-6 border-t-[1px] border-[#595959] pt-[1rem]"
              >
                <FileDisplay
                  files={files}
                  id={id}
                  entityType="events"
                  editable={true}
                  perms={perms}
                  showFinalCheckbox={canSeeFinalNumbers}
                />
              </div>
            </>
          )}
        {event.isSuccess &&
          ((event.data.buyer.id === user.data.id &&
            perms.data.onlyMineEdit === true) ||
            perms.data.editAllFiles === true ||
            perms.data.is_admin) && (
            <>
              {canEdit && canSeeNumbers && (
                <div className="flex flex-col my-6 border-t-[1px] border-[#595959] pt-[1rem]">
                  <EventPDF event={event.data} canAdd={true} />
                </div>
              )}
            </>
          )}

        <div className="mb-12">
          <RecentItems />
        </div>
      </div>
    </>
  );
};
