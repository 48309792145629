import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  jsonHeaders,
  makeRequest,
} from "./base";

export const getReqsSettings = {
  cacheTime: 30 * 60 * 1000, // Cache for 30 minutes
  staleTime: 15 * 60 * 1000, // Data is considered stale after 15 minutes
};

export const getReqs = async () => {
  const url = `/api/reqs/`;
  return await getApiRequest(url);
};

export const getReq = async (id) => {
  const url = `/api/reqs/${id}/`;
  return await getApiRequest(url);
};

export const getReqDetails = async (id) => {
  const url = `/api/reqs/${id}/`;
  return await getApiRequest(url);
};

export const updateReq = async (id, data) => {
  const url = `/api/reqs/${id}/`;
  return await putApiRequest(url, data);
};

export const createReq = async (data) => {
  const url = `/api/reqs/`;
  return await postApiRequest(url, data);
};

export const deleteReq = async (id) => {
  const url = `/api/reqs/${id}/`;
  return await deleteApiRequest(url);
};

export const getReqCommentsSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

export const getReqComments = async (id) => {
  const url = `/api/reqs/comments/${id}/`;
  return await getApiRequest(url);
};

export const createReqComment = async (id, data) => {
  const url = `/api/reqs/comments/${id}/`;
  return await postApiRequest(url, data);
};

export const getAdminReqs = async () => {
  const url = "/api/reqs/all_reqs/";
  return await getApiRequest(url);
};

export const updateAdminReq = async (updatedReq) => {
  const url = `/api/reqs/reqs_admin/${updatedReq.id}/`;
  const headers = await jsonHeaders(true);
  const resp = await makeRequest(
    url,
    "PUT",
    headers,
    JSON.stringify(updatedReq)
  );
  return resp.json();
};

export const getReqAdminUsers = async () => {
  const url = "/api/reqs/reqs_admins/";
  return await getApiRequest(url);
};

export const getReqFileSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

export const getReqFiles = async (id) => {
  const url = `/api/reqs/files/${id}/`;
  return await getApiRequest(url);
};

export const createReqFile = async (id, data) => {
  const url = `/api/reqs/files/${id}/`;
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  const resp = await makeRequest(url, "POST", headers, data);
  return resp.json();
};

export const deleteReqFile = async (id) => {
  const url = `/api/reqs/file/${id}/`;
  return await deleteApiRequest(url);
};
