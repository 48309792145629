import React, { useState, useEffect } from "react";

export const Carousel = ({ children, three }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(three ? 3 : 6);

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth < 640) {
        setItemsPerPage(1);
      } else if (window.innerWidth < 1024) {
        setItemsPerPage(three ? 2 : 4);
      } else {
        setItemsPerPage(three ? 3 : 6);
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, [three]);

  const goNext = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % Math.ceil(children.length / itemsPerPage)
    );
  };

  const goPrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + Math.ceil(children.length / itemsPerPage)) %
        Math.ceil(children.length / itemsPerPage)
    );
  };

  const startIndex = currentIndex * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className="relative w-full">
      <div
        className={`overflow-hidden grid ${
          itemsPerPage === 1
            ? "grid-cols-1 justify-center"
            : itemsPerPage === 2
            ? "grid-cols-2"
            : itemsPerPage === 3
            ? "grid-cols-3"
            : "grid-cols-6"
        } gap-x-4`}
      >
        {children &&
          typeof children === "object" &&
          children.slice(startIndex, endIndex).map((child, index) => (
            <div key={index} className={itemsPerPage === 1 ? "mx-auto" : ""}>
              {child}
            </div>
          ))}
      </div>
      <button
        onClick={goPrev}
        className="dark:text-gray-200 absolute sm:right-10 sm:top-[-30px] xs:top-1/2 transform sm:-translate-y-1/2 xs:-translate-y-1/2"
      >
        &#10094;
      </button>
      <button
        onClick={goNext}
        className="dark:text-gray-200 absolute right-2 sm:top-[-30px] xs:top-1/2 transform sm:-translate-y-1/2 xs:-translate-y-1/2"
      >
        &#10095;
      </button>
    </div>
  );
};
