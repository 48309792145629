import React from "react";
import dayjs from "dayjs";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  UserIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";

const DayView = ({
  date,
  events,
  onDateChange,
  onEventClick,
  activeFilters,
  toggleFilter,
  EVENT_MAPPING,
  excludedStates,
  handleShowModal,
  perms,
}) => {
  const currentDate = dayjs(date);

  const filteredEvents = events.filter(
    (event) => activeFilters.length === 0 || activeFilters.includes(event.state)
  );

  const renderFilterButtons = () => (
    <div className="flex space-x-2 justify-end">
      {Object.entries(EVENT_MAPPING)
        .filter(([state]) => !excludedStates.includes(state))
        .map(([state, colors]) => (
          <button
            key={state}
            className={`px-2 py-1 rounded text-xs transition-colors duration-200`}
            style={{
              backgroundColor: activeFilters.includes(state)
                ? colors["rgb-background"]
                : "#4A5568",
              color: activeFilters.includes(state)
                ? colors["rgb-text"]
                : "#FFFFFF",
            }}
            onClick={() => toggleFilter(state)}
          >
            {state}
          </button>
        ))}
    </div>
  );

  return (
    <div className="bg-[#fffef9] dark:bg-cave-gray-900 dark:text-cave-white p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4">
          <button onClick={() => onDateChange("prev")} className="text-2xl">
            <ChevronLeftIcon className="h-6 w-6" />
          </button>
          <h2 className="text-2xl font-bold">{currentDate.format("D")}</h2>
          <button onClick={() => onDateChange("next")} className="text-2xl">
            <ChevronRightIcon className="h-6 w-6" />
          </button>
        </div>
      </div>

      <div className="space-y-4">
        {filteredEvents.length === 0 ? (
          <div className="text-center text-gray-500">
            No events for this day
          </div>
        ) : (
          filteredEvents.map((event, index) => (
            <div
              key={index}
              className={`border-l-4 bg-gray-800 p-4 rounded cursor-pointer`}
              style={{
                borderColor: EVENT_MAPPING[event.state]["rgb-background"],
              }}
              onClick={() => onEventClick(event)}
            >
              <div className="flex justify-between items-start">
                <div>
                  <div className="flex items-center space-x-2 mb-2">
                    <span
                      className={`w-2 h-2 rounded-full`}
                      style={{
                        backgroundColor:
                          EVENT_MAPPING[event.state]["rgb-background"],
                      }}
                    ></span>
                    <span className="text-sm text-gray-400 uppercase">
                      {event.state}
                    </span>
                  </div>
                  <h3 className="text-xl font-bold mb-2">{event.name}</h3>
                  <div className="flex items-center space-x-2 text-gray-400">
                    <UserIcon className="h-4 w-4" />
                    <span>{event.buyer_name}</span>
                  </div>
                  <div className="flex items-center space-x-2 text-gray-400">
                    <MapPinIcon className="h-4 w-4" />
                    <span>{event.venue_name}</span>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <button>
                    <EllipsisHorizontalIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DayView;
