import { useNavigate } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { usePerms } from "@/components/Auth";
import { useUser } from "@/components/Auth";
import dayjs from "dayjs";
import { amountDisplay } from "@/utils/money";

export const ManifestRow = ({
  id,
  canEdit,
  name,
  qty,
  comps,
  price,
  available,
  paid,
  on_sale,
}) => {
  const { perms } = usePerms();
  const { user } = useUser();
  const navigate = useNavigate();

  return (
    <tr
      className="bg-cave-white border-b dark:bg-g[red] dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      key={id}
    >
      <td className="px-6 py-4 flex flex-inline justify-between">
        {name}
        <div className="p-1 hover:bg-blue-200">
          {canEdit && (
            <PencilSquareIcon
              className="h-4 w-4 hover:text-blue-700"
              onClick={() => navigate(`/events/manifest/edit/${id}`)}
            />
          )}
        </div>
      </td>
      <td className="px-6 py-4">{qty}</td>
      <td className="px-6 py-4">{comps}</td>
      <td className="px-6 py-4">
        {on_sale && dayjs(on_sale).format("MMM D, YYYY")}
      </td>
      <td className="px-6 py-4">{amountDisplay(price)}</td>
      <td className="px-6 py-4">{available}</td>
      <td className="px-6 py-4">{paid}</td>
      <td className="px-6 py-4 text-right">{amountDisplay(price * paid)}</td>
    </tr>
  );
};
