import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { getRecentItems } from '@/queries/visit';

const ItemDetails = ({ item }) => {
  const details = {
    event: {
      primary: item.event_date ? dayjs(item.event_date).format('MMM D, YYYY') : 'Date TBD',
      secondary: [
        item.venue_name,
        item.event_type,
        item.ticket_price_range && `Tickets: ${item.ticket_price_range}`,
        item.total_tickets && item.tickets_sold && 
          `${item.tickets_sold}/${item.total_tickets} Tickets Sold`,
        item.gross_potential && `Potential: ${item.gross_potential}`,
        item.net_income && `Net: ${item.net_income}`
      ].filter(Boolean).join(' • '),
      image: item.event_image_url,
      status: item.event_status,
      statusColors: {
        confirmed: 'bg-green-100 text-green-800',
        hold: 'bg-yellow-100 text-yellow-800',
        cancelled: 'bg-red-100 text-red-800',
        default: 'bg-gray-100 text-gray-800'
      }
    },
    artist: {
      primary: item.genre || 'Genre Not Specified',
      secondary: [
        item.agent_name && `Agent: ${item.agent_name}`,
        item.upcoming_events_count && `${item.upcoming_events_count} Upcoming`,
        item.total_events && `${item.total_events} Total Shows`,
        item.last_event_date && `Last: ${dayjs(item.last_event_date).format('MMM D, YYYY')}`,
        item.social_media?.instagram && `@${item.social_media.instagram}`
      ].filter(Boolean).join(' • '),
      image: item.related_image_url,
      tags: Array.isArray(item.artist_tags) ? item.artist_tags : [],
      tagColor: 'bg-blue-100 text-blue-800'
    },
    venue: {
      primary: item.city && item.state ? `${item.city}, ${item.state}` : 'Location TBD',
      secondary: [
        item.capacity && `Capacity: ${item.capacity.toLocaleString()}`,
        item.venue_type && `Type: ${item.venue_type}`,
        item.upcoming_events && `${item.upcoming_events} Upcoming`,
        item.parking_info && `🅿️ ${item.parking_info}`,
        item.accessibility?.length > 0 && `♿️ ${item.accessibility.join(', ')}`
      ].filter(Boolean).join(' • '),
      image: item.related_image_url,
      amenities: Array.isArray(item.venue_amenities) ? item.venue_amenities : [],
      amenityColor: 'bg-purple-100 text-purple-800'
    }
  }[item.item_type];

  if (!details) return null;

  return (
    <div className="flex items-center space-x-4">
      <div className="relative">
        <img 
          src={details.image} 
          alt={item.name}
          className="w-16 h-16 object-cover rounded-lg"
        />
        <span className="absolute -top-2 -right-2 px-2 py-0.5 rounded-full text-xs font-medium bg-gray-800 text-white">
          {item.item_type.charAt(0).toUpperCase() + item.item_type.slice(1)}
        </span>
      </div>
      <div className="flex-1">
        <div className="text-sm text-gray-600 dark:text-gray-400">
          <div className="font-medium">{details.primary}</div>
          <div>{details.secondary}</div>
        </div>
        {details.status && (
          <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
            details.status === 'confirmed' ? 'bg-green-100 text-green-800' :
            details.status === 'hold' ? 'bg-yellow-100 text-yellow-800' :
            'bg-gray-100 text-gray-800'
          }`}>
            {details.status.toUpperCase()}
          </span>
        )}
        {details.tags && details.tags.length > 0 && (
          <div className="flex gap-1 mt-1">
            {details.tags.slice(0, 2).map(tag => (
              <span key={tag} className="px-2 py-0.5 rounded text-xs bg-blue-100 text-blue-800">
                {tag}
              </span>
            ))}
          </div>
        )}
        {details.amenities && details.amenities.length > 0 && (
          <div className="flex gap-1 mt-1">
            {details.amenities.slice(0, 2).map(amenity => (
              <span key={amenity} className="px-2 py-0.5 rounded text-xs bg-purple-100 text-purple-800">
                {amenity}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const RecentItems = () => {
  const { data: recentItems, isLoading } = useQuery(
    ['recent-items'],
    getRecentItems,
    {
      staleTime: 30000, // Consider data fresh for 30 seconds
      refetchOnWindowFocus: true
    }
  );

  if (isLoading) {
    return (
      <div className="border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
        <h3 className="text-lg font-semibold mb-3 dark:text-cave-white">
          Recently Viewed
        </h3>
        <div className="animate-pulse space-y-3">
          {[1, 2, 3].map((i) => (
            <div key={i} className="flex space-x-4">
              <div className="w-16 h-16 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
              <div className="flex-1 space-y-2">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4"></div>
                <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded w-1/2"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
      <h3 className="text-lg font-semibold mb-3 dark:text-cave-white">
        Recently Viewed
      </h3>
      <div className="space-y-3">
        {recentItems?.map((item) => (
          <Link
            key={`${item.item_type}-${item.url}`}
            to={item.url}
            className="block hover:bg-gray-50 dark:hover:bg-gray-800 p-2 rounded-lg transition-colors"
          >
            <div className="flex justify-between items-start mb-1">
              <div className="font-medium dark:text-cave-white">
                {item.title}
              </div>
              <div className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                item.item_type === 'event' ? 'bg-indigo-100 text-indigo-800' :
                item.item_type === 'artist' ? 'bg-purple-100 text-purple-800' :
                item.item_type === 'venue' ? 'bg-teal-100 text-teal-800' :
                'bg-rose-100 text-rose-800'
              }`}>
                {item.item_type.charAt(0).toUpperCase() + item.item_type.slice(1)}
              </div>
            </div>
            {item.subtitle && (
              <div className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                {item.subtitle}
              </div>
            )}
            <ItemDetails item={item} />
          </Link>
        ))}
      </div>
    </div>
  );
};
