import { TableRow } from "../AppTable2";

interface PaymentSection {
  value: string;
  onUpdate: (value: string) => void;
}

interface SettlementArtistPaymentProps {
  editable?: boolean;
  paymentMethod: PaymentSection;
  checkNumber: PaymentSection;
  paidThrough: PaymentSection;
}

export default function SettlementArtistPayment({
  checkNumber,
  paymentMethod,
  paidThrough,
  editable = true,
}: SettlementArtistPaymentProps) {
  const sections = [
    {
      label: "PAYMENT METHOD (check / wire)",
      id: "payment_method",
      value: paymentMethod.value ?? 0,
      onUpdate: paymentMethod.onUpdate,
    },
    {
      label: "CHECK #",
      id: "check_number",
      value: checkNumber.value ?? 0,
      onUpdate: checkNumber.onUpdate,
    },
    {
      label: "PAID THROUGH:",
      id: "paid_through",
      value: paidThrough.value ?? 0,
      onUpdate: paidThrough.onUpdate,
    },
  ];

  return (
    <div>
      {sections.map((section) => {
        return (
          <TableRow
            key={section.id}
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable,
              },
            ]}
            rowData={{
              name: section.label,
              value: section.value,
            }}
            rowContainerClass="border p-0.5"
            inputClassName="!border-0"
            onBlur={(_, value: string) => {
              section.onUpdate(value);
            }}
          />
        );
      })}
    </div>
  );
}
