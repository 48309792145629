import React from "react";
import { getMessages, removeMessage, dismissEvent, getMessageSettings } from "@/queries/bb";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import LoadingPulse from "@/components/shared/LoadingPulse";
import { getEventsBBList, getEventsSettings } from "@/queries/events";
import { PeopleIcon } from "@/components/People/PeopleIcon";
import { usePageTitle } from "@/utils/pagetitle";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";

export const BulletinBoard = () => {
  usePageTitle("Bulletin Board");
  const queryClient = useQueryClient();

  // Fetch bulletin board messages
  const {
    data: messages,
    isLoading: isLoadingMessages,
    error: errorMessages,
  } = useQuery(["messages"], getMessages, getMessageSettings);

  // Fetch other data
  const {
    data: otherData,
    isLoading: isLoadingOther,
    error: errorOther,
  } = useQuery(["events", "bb_list"], getEventsBBList, getMessageSettings);

  const removeBulletinMessageMutation = useMutation({
    mutationFn: (messageId) => removeMessage(messageId),
    onMutate: async (messageId) => {
      await queryClient.cancelQueries({ queryKey: ["messages"] });
      
      // Get current messages
      const previousMessages = queryClient.getQueryData(["messages"]);
      
      // Optimistically remove the message
      if (previousMessages) {
        const updatedMessages = previousMessages.filter(
          msg => msg.id !== messageId
        );
        queryClient.setQueryData(["messages"], updatedMessages);
      }
      
      return { previousMessages };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["messages"] });
    },
    onError: (error, messageId, context) => {
      console.error("Failed to remove message:", error);
      // Restore previous messages on error
      if (context?.previousMessages) {
        queryClient.setQueryData(["messages"], context.previousMessages);
      }
      queryClient.invalidateQueries({ queryKey: ["messages"] });
    }
  });

  const dismissEventMutation = useMutation({
    mutationFn: dismissEvent,
    onMutate: async (variables) => {
      // Validate input
      if (!variables?.event_ids?.length) {
        throw new Error("No event IDs provided");
      }

      // Cancel any outgoing refetches to avoid race conditions
      await queryClient.cancelQueries({ queryKey: ["events", "bb_list"] });
      await queryClient.cancelQueries({ queryKey: ["messages"] });
      
      // Snapshot the previous state
      const previousData = {
        events: queryClient.getQueryData(["events", "bb_list"]),
        messages: queryClient.getQueryData(["messages"])
      };
      
      // Optimistically update the UI
      if (previousData.events?.length) {
        const updatedData = previousData.events.filter(
          event => !variables.event_ids.includes(event.id)
        );
        queryClient.setQueryData(["events", "bb_list"], updatedData);
      }
      
      return previousData;
    },
    onError: (error, variables, context) => {
      console.error("Failed to dismiss events:", error);
      
      // Restore previous state on error
      if (context?.events) {
        queryClient.setQueryData(["events", "bb_list"], context.events);
      }
      if (context?.messages) {
        queryClient.setQueryData(["messages"], context.messages);
      }
    },
    onSuccess: (data) => {
      if (!data) {
        console.error("No response data received");
        return;
      }

      if (data.error) {
        console.error("API Error:", data.error);
        return;
      }

      // Invalidate and refetch in sequence to ensure fresh data
      return queryClient.invalidateQueries({ queryKey: ["events", "bb_list"] })
        .then(() => queryClient.invalidateQueries({ queryKey: ["messages"] }))
        .then(() => {
          return Promise.all([
            queryClient.refetchQueries({ queryKey: ["events", "bb_list"] }),
            queryClient.refetchQueries({ queryKey: ["messages"] })
          ]);
        });
    },
    retry: 2, // Retry failed mutations twice
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff
  });

  if (isLoadingMessages || isLoadingOther) {
    return (
      <div className="h-fit bg-[#F4F2E9] w-full rounded-xl flex flex-col justify-between overflow-hidden">
        <div className="h-10 dark:bg-cave-gray-600 bg-cave-gray-100 px-5 flex items-center justify-start">
          <p className="dark:text-gray-50 text-gray-500 font-bold">
            Bulletin Board
          </p>
        </div>
        <LoadingPulse />
      </div>
    );
  }
  if (errorMessages || errorOther) return <div>Error loading messages.</div>;

  // Normalize and combine data
  const normalizedMessages = (messages || []).map((msg) => ({
    id: msg.id,
    source: "bulletin",
    msg: msg.content,
    msgdate: msg.created_at,
    buyer: msg.sender_name || `User ${msg.sender_id}`,
    buyer_id: msg.sender_id,
    buyer_image_url: msg.sender_image_url,
    event_id: null,
    is_sticky: msg.is_sticky,
  }));

  // Filter out any null or undefined items before mapping
  const normalizedOtherData = (otherData || []).filter(Boolean).map((item) => ({
    id: item.id,
    source: "event",
    msg: `${item.name}@${item.venue_name}`,
    msgdate: item.confirmed_date,
    buyer: item.buyer,
    buyer_id: item.buyer_id,
    buyer_image_url: item.buyer_image_url,
    event_id: item.id,
    is_sticky: false,
  }));

  // Filter and combine messages and events with improved date handling
  const thirtyDaysAgo = new Date(Date.now() - 30*24*60*60*1000);
  const oneYearAhead = new Date(Date.now() + 365*24*60*60*1000);
  
  const combinedData = [...normalizedMessages, ...normalizedOtherData].filter(item => {
    if (!item || !item.msgdate) return false;
    
    const messageDate = new Date(item.msgdate);
    const now = new Date();
    
    // For bulletin messages
    if (item.source === "bulletin") {
      // Always show sticky messages
      if (item.is_sticky) return true;
      
      // For non-sticky messages, check if not dismissed and within date range
      return !item.is_dismissed && 
             messageDate >= thirtyDaysAgo &&
             messageDate <= now;
    }
    
    // For events
    if (item.source === "event") {
      return !item.is_dismissed && // Not dismissed
             messageDate >= thirtyDaysAgo && // Within last 30 days
             messageDate <= oneYearAhead; // Not more than 1 year ahead
    }
    
    return false;
  });

  // Sort combined data by date in descending order
  combinedData.sort((a, b) => new Date(b.msgdate) - new Date(a.msgdate));

  return (
    <div className="h-fit bg-[#F4F2E9] w-full rounded-xl flex flex-col justify-between overflow-hidden">
      <div className="h-10 dark:bg-cave-gray-600 bg-cave-gray-100 px-5 flex items-center justify-start">
        <p className="dark:text-gray-50 text-gray-500 font-bold">
          Bulletin Board
        </p>
      </div>
      <div className="max-h-96 overflow-y-auto">
        {combinedData.map((item, index) => (
          <div
            key={`${item.source}-${item.id}-${item.msgdate}`}
            className={`flex items-center w-full h-[100px] ${
              index % 2 === 0 ? "bg-[#FFFBE6] dark:bg-[#3F3F3F]" : "bg-[#3F3F3F] dark:bg-[#FFFBE6]"
            }`}
          >
            <div className="flex items-center">
              <div className="w-[55px] h-[55px] ml-[20px] bg-[#B3B3B3] rounded-full flex-shrink-0 overflow-hidden">
                {item.buyer_id && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/people/${item.buyer_id}`}
                  >
                    <img
                      src={item.buyer_image_url || "/default-profile.png"}
                      alt="Profile"
                      className="w-full h-full object-cover"
                    />
                  </a>
                )}
              </div>
              <p
                className={`ml-[20px] font-inter font-medium text-[15px] leading-[18px] ${
                  index % 2 === 0 ? "text-cave-black dark:text-cave-white" : "text-cave-white dark:text-cave-black"
                }`}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/events/${item.event_id}`}
                >
                  {item.msg}
                </a>
              </p>
            </div>
            <div className="flex items-center ml-auto mr-[20px]">
              <span
                className={`font-inter font-normal text-[15px] leading-[18px] ${
                  index % 2 === 0 ? "text-cave-black dark:text-cave-white" : "text-cave-white dark:text-cave-black"
                }`}
              >
                {new Date(item.msgdate).toLocaleDateString()}
              </span>
              {(item.source === "event" || (item.source === "bulletin" && !item.is_sticky)) && (
                <button
                  onClick={() => {
                    if (item.source === "bulletin") {
                      console.log("Removing bulletin message:", item.id);
                      removeBulletinMessageMutation.mutate(item.id);
                    } else if (item.source === "event") {
                      console.log("Dismissing event:", item.event_id);
                      dismissEventMutation.mutate({
                        event_ids: [item.event_id]
                      }, {
                        onError: (error) => {
                          console.error("Dismissal failed:", error);
                          // Show user-friendly error message
                          alert("Failed to dismiss event. Please try again.");
                        },
                        onSuccess: (data) => {
                          console.log("Dismissal processed:", data);
                          if (data.new_dismissals === 0 && data.already_dismissed > 0) {
                            console.log("Event was already dismissed");
                          } else {
                            console.log(`Dismissed ${data.new_dismissals} new events`);
                          }
                          // Invalidate queries after successful dismissal
                          Promise.all([
                            queryClient.invalidateQueries(['messages']),
                            queryClient.invalidateQueries(['events', 'bb_list'])
                          ]).catch(error => {
                            console.error("Failed to invalidate queries:", error);
                          });
                        }
                      });
                    }
                  }}
                  className="ml-4 px-1 text-[#EC4641] hover:text-red-700 text-md rounded-full border-4 font-black border-cave-red"
                >
                  X
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="h-10 dark:bg-cave-gray-600 bg-cave-gray-100 flex justify-between items-center w-100 pr-24 pl-24">
        <a
          href="https://www.facebook.com/mammothlive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF className="text-xl dark:text-gray-50 text-gray-500" />
        </a>

        {/* add .png here */}
        <a
          href="https://www.mammothlive.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/static/images/mammoth-logo-sm-trans-black.png"
            className="h-6 w-auto dark:hidden"
          />
          <img
            src="/static/images/mammoth-logo-sm-trans-white.png"
            className="hidden h-6 w-auto dark:block"
          />
        </a>
        <a
          href="https://www.instagram.com/mammothlive/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="text-xl dark:text-gray-50 text-gray-500" />
        </a>
        <a
          href="https://twitter.com/MammothLive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter className="text-xl dark:text-gray-50 text-gray-500" />
        </a>
      </div>
    </div>
  );
};

export default BulletinBoard;
