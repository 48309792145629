import React, { useState } from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormBuilder, Input, TextInput } from "@/components/Form";
import { useUser, usePerms } from "@/components/Auth";
import { createReq, createReqFile } from "@/queries/reqs";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { FileUploadForm } from "@/components/Files/FileDisplay";

export const ReqAdd = () => {
  const { perms } = usePerms();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pendingFiles, setPendingFiles] = useState([]);

  const addSchema = yup.object().shape({
    location: yup.string().required("Location is required"),
    description: yup.string().required("Description is required"),
  });

  const createReqMutation = useMutation(createReq, {
    onSuccess: (data) => {
      console.log("Request created successfully:", data);
      queryClient.invalidateQueries(["reqs"]);
      if (pendingFiles.length > 0) {
        uploadFiles(data.id);
      } else {
        setIsSubmitting(false);
        navigate(`/reqs/${data.id}`);
      }
    },
    onError: (error) => {
      console.error("Error creating request:", error);
      setIsSubmitting(false);
    },
  });

  const createReqFileMutation = useMutation(
    async ({ id, files, description }) => {
      const formData = new FormData();

      // Append each file
      files.forEach((file) => {
        formData.append("files", file);
      });

      // Add required name field - use the first file's name or a default
      formData.append("name", files[0]?.name || "uploaded-file");

      // Add other fields
      formData.append("description", description || "");
      formData.append("parent_id", id);
      formData.append("entity_type", "reqs");

      console.log("Request Files FormData contents:");
      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      return createReqFile(id, formData);
    },
    {
      onSuccess: (data) => {
        console.log("Files uploaded successfully:", data);
        queryClient.invalidateQueries(["reqs", "files", data[0].req_id]);
      },
      onError: (error) => {
        console.error("Error uploading files:", error);
      },
    }
  );

  const uploadFiles = async (reqId) => {
    try {
      await createReqFileMutation.mutateAsync({
        id: reqId,
        files: pendingFiles,
        description: "Uploaded with new request",
      });
      setIsSubmitting(false);
      navigate(`/reqs/${reqId}`);
    } catch (error) {
      console.error("Error uploading files:", error);
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (formData) => {
    console.log("handleSubmit called with formData:", formData);
    setIsSubmitting(true);
    createReqMutation.mutate(formData);
  };

  const handleFileChange = (files) => {
    console.log("Files changed:", files);
    setPendingFiles(files);
  };
  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
        <BreadCrumbs
          links={[
            { text: "IT Requests", url: "/reqs" },
            { text: "New Request" },
          ]}
        />

        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="max-w-4xl mx-auto p-5 bg-cave-white shadow-md rounded-lg p-4 mb-4">
            <h2 className="text-2xl font-bold mb-4">New Request</h2>
            <p>Please provide details about your IT request below.</p>
            <div className="mt-6">
              <FormBuilder
                onSubmit={handleSubmit}
                schema={addSchema}
                defaultValues={{ location: "", description: "" }}
                id="new-req-form"
              >
                <div className="space-y-4">
                  <Input
                    name="location"
                    label="Location"
                    autoFocus={true}
                    helpText="Please enter the location of device having issue"
                  />
                  <TextInput
                    name="description"
                    label="Description"
                    rows={6}
                    helpText="Description of the issue. (Please give as much detail about the issue as possible)"
                  />

                  <div className="mt-6">
                    <h3 className="text-lg font-semibold mb-2">
                      Do you have any screenshots/video of the issue?
                    </h3>
                    <FileUploadForm
                      parentId={null}
                      entityType="reqs"
                      onSuccess={() => {}}
                      onCancel={() => {}}
                      isReqs={true}
                      onFileChange={handleFileChange}
                      showMarketingCheckbox={false}
                      showFinalCheckbox={false}
                      hideSubmitButton={true}
                      simplified={true}
                    />
                  </div>

                  {pendingFiles.length > 0 && (
                    <div className="mt-4">
                      <h4 className="font-semibold">Files to be uploaded:</h4>
                      <ul className="list-disc pl-5">
                        {pendingFiles.map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <button
                    className="w-full text-cave-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Make Request"}
                  </button>
                </div>
              </FormBuilder>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReqAdd;
