import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import Button from "@/components/Button";

export const SaveCancel = (props) => {
  const { label, cancelURL, isSubmitting, submittingMessage } = props;
  const navigate = useNavigate();

  if (isSubmitting) {
    return (
      <div className="flex-inline float-right mb-12">
        <div>{submittingMessage || "Saving..."}</div>
      </div>
    );
  } else {
    return (
      <div className="flex-inline float-right mb-12">
        <button
          type="button"
          onClick={() => navigate(cancelURL || "/")}
          className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Cancel
        </button>
        <Button type="submit">{label || "Save"}</Button>
      </div>
    );
  }
};
