import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "vite/modulepreload-polyfill";
import "./index.css";
import "react-day-picker/dist/style.css";
import { AppRouter } from "./router";
import * as Sentry from "@sentry/react";
import "primereact/resources/themes/saga-blue/theme.css"; // theme
import "primereact/resources/primereact.min.css"; // core css
// import "primeicons/primeicons.css"; // icons
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const isDevelopment =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname.includes("dev");

Sentry.init({
  dsn: "https://81ac7dd445834bf9d66bc0e500a8ea40@o4507659974410240.ingest.us.sentry.io/4507794031378432",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/am\.cave\.pm\//],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  maxValueLength: 2048,

  //debug: true,
  environment: isDevelopment ? "development" : "production",
});

const queryClient = new QueryClient();

import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";
import { QueryErrorResetBoundary } from "@tanstack/react-query";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={({ error, resetError }) => {
        // Check if it's an API error
        const errorMessage = error.response?.data?.detail || error.message;
        return (
          <div className="flex flex-col items-center justify-center min-h-screen p-4">
            <h1 className="text-2xl font-bold text-red-600 mb-4">
              Something went wrong!
            </h1>
            <pre className="bg-gray-100 p-4 rounded mb-4 max-w-2xl overflow-auto">
              {errorMessage}
            </pre>
            <button
              onClick={resetError}
              className="bg-blue-500 text-cave-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Try again
            </button>
          </div>
        );
      }}
      onError={(error, info) => {
        console.error("Error caught by boundary:", error, info);
      }}
    >
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <DndProvider backend={HTML5Backend}>
            <QueryClientProvider client={queryClient}>
              <ToastContainer />
              <AppRouter />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </DndProvider>
        )}
      </QueryErrorResetBoundary>
    </ErrorBoundary>
  </React.StrictMode>
);
