export const EVENT_MAPPING = {
  hold: {
    display: "HOLD",
    classes: "text-gray-700",
    background: "bg-gray-400 hover:bg-gray-500",
    foreground: "text-gray-200",
    "rgb-background": "rgb(229 231 235)",
    "rgb-text": "rgb(31 41 55)",
  },
  draft: {
    display: "DRAFT",
    classes: "text-gray-700",
    background: "bg-yellow-400 hover:bg-yellow-500",
    foreground: "text-gray-600",
    "rgb-background": "color: rgb(250 204 21)",
    "rgb-text": "rgb(75 85 99)",
  },
  offer: {
    display: "OFFER",
    classes: "text-blue-700",
    background: "bg-blue-400 hover:bg-blue-500",
    foreground: "text-gray-200",
    "rgb-background": "rgb(96 165 250)",
    "rgb-text": "rgb(229 231 235)",
  },
  offer_sent: {
    display: "Offer Sent",
    classes: "text-blue-700",
    background: "bg-blue-400 hover:bg-blue-500",
    foreground: "text-gray-200",
  },
  canceled: {
    display: "CANCELED",
    classes: "text-cave-white",
    background: "bg-red-500 hover:bg-red-700",
    foreground: "text-gray-800",
    "rgb-background": "rgb(239 68 68)",
    "rgb-text": "rgb(31 41 55)",
  },
  postponed: {
    display: "POSTPONED",
    classes: "text-cave-white",
    background: "bg-red-300 hover:bg-red-500",
    foreground: "text-gray-800",
    "rgb-background": "rgb(252 165 165)",
    "rgb-text": "rgb(31 41 55)",
  },
  confirmed: {
    display: "CONFIRMED",
    classes: "text-green-700",
    background: "bg-green-500 hover:bg-green-700",
    foreground: "text-gray-800",
    "rgb-background": "rgb(34 197 94)",
    "rgb-text": "rgb(31 41 55)",
  },
};

const EventState = (props) => {
  const { state } = props;
  let map = EVENT_MAPPING[state];

  return (
    <div className={`w-full py-2 px-1 text-center rounded ${map.background}`}>
      <span className={map.foreground}>{map.display}</span>
    </div>
  );
};

export default EventState;
