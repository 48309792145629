import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { FormSelect, Form } from "@/components/Form";
import { FormBuilder } from "@/components/Form";
import {
  checkLoadingStates,
  LoadingScreen,
  ErrorScreen,
} from "@/utils/loadingStates";
import {
  getEventAdvance,
  updateEventAdvance,
  patchEventAdvance,
} from "@/queries/eventAdvance";
import { toast } from "react-toastify";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useUser, usePerms } from "@/components/Auth";
import { useParams, useNavigate } from "react-router-dom";
import { validateEventAdvanceForm } from "../../utils/validation";
import { getFieldError } from "@/utils/errorHandling";
import Button from "@/components/Button";
import styles from "@/views/events/EventDetail.module.css";
import classNames from "classnames";
import {
  addEventToOffer,
  createOffer,
  getEventOfferVariables,
  getOffer,
  getOffers,
  updateEvent,
  deleteEventPeople,
  getEventPeople,
  getEvent,
} from "@/queries/events";
import {
  PencilSquareIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";

import { getEventRollups, getEventCopros } from "@/queries/accounting";

import { ItemTable } from "./ItemTable";
const getEventRequiredParams = (eventData) => ({
  name: eventData?.name,
  date: eventData?.date,
  artists: eventData?.artists ? [eventData.artists[0].id] : [0],
  state: eventData?.state,
  age_requirement: eventData?.age_requirement,
  abbr: eventData?.abbr,
  description: eventData?.description,
  door_time: eventData?.door_time,
  opener_time: eventData?.opener_time,
  support_time: eventData?.support_time,
  headliner_time: eventData?.headliner_time,
  curfew_time: eventData?.curfew_time,
  offer_notes: eventData?.offer_notes,
  confirmation_notes: eventData?.confirmation_notes,
  venue_id: eventData?.venue?.id,
  has_insurance: eventData?.has_insurance,
  has_credit_card_fees: eventData?.has_credit_card_fees,
  has_ticket_commission: eventData?.has_ticket_commission,
  has_ascap: eventData?.has_ascap,
  has_bmi: eventData?.has_bmi,
  has_sesac: eventData?.has_sesac,
  offer_has_rent: eventData?.offer_has_rent,
  offer_has_comp_tax: eventData?.offer_has_comp_tax,
  schedule: eventData?.schedule,
});

export const EventAdvanceM = ({ id, eventData, variablesData }) => {
  if (!eventData) {
    return <LoadingScreen />;
  }

  // All hooks must be called in the same order every render
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useUser();
  const { perms } = usePerms();

  // Query options
  const queryOptions = {
    staleTime: 30000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: 1000,
  };

  // Query and mutation hooks

  // Callback hooks
  // handleUpdate callback is now consolidated at the top of the component

  // Add event advance queries
  const eventAdvance = useQuery(
    ["event-advance", eventData?.id],
    () => getEventAdvance(eventData.id),
    {
      enabled: !!eventData?.id,
      onSuccess: (data) => {
        // Initialize form state with database values
        setFormState((prevState) => ({
          ...prevState,
          eventInfo: {
            venue_type: data.venue_type,
            // Map other fields...
          },
          venueContacts: {
            gm: {
              name: data.gm_name,
              phone: data.gm_phone,
              cell: data.gm_cell,
              email: data.gm_email,
            },
            // Map other contacts...
          },
          // Map other sections...
        }));
      },
    }
  );

  const createOfferMutation = useMutation({
    mutationFn: createOffer,
    onSuccess: (data) => {
      const body = {
        event_id: eventData.id,
        offer_id: data.id,
      };
      addEventToOfferMutation.mutate(body);
    },
  });

  ItemTable.displayName = "ItemTable";

  const addEventToOfferMutation = useMutation({
    mutationFn: (data) =>
      addEventToOffer(parseInt(data.event_id), data.offer_id),
    onSuccess: () => {
      queryClient.invalidateQueries(["offer-detail"]);
      queryClient.invalidateQueries(["event-detail", id]);
    },
  });

  const updateEventMutation = useMutation({
    mutationFn: (data) => {
      if (!id) {
        throw new Error("Event ID is required");
      }
      if (!data || typeof data !== "object") {
        throw new Error("Invalid data format");
      }

      // Normalize the data before sending
      const normalizedData = {
        ...data,
        advance_json: data.advance_json
          ? typeof data.advance_json === "string"
            ? JSON.parse(data.advance_json)
            : data.advance_json
          : {},
      };

      return updateEvent(id, normalizedData);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
    },
    onError: (error) => {
      console.error("Mutation error:", error);
      toast.error(error.message || "Failed to update event");
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEventData = queryClient.getQueryData(["event-detail", id]);
      if (previousEventData && newData.schedule !== undefined) {
        queryClient.setQueryData(["event-detail", id], {
          ...previousEventData,
          schedule: newData.schedule,
        });
      }
      return { previousEventData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(["event-detail", id], context.previousEventData);
    },
  });
  const updateAdvanceFunction = async ({ section, field, value }) => {
    if (!eventData?.id || !section || !field) {
      throw new Error("Missing required data for update");
    }

    const currentAdvance = eventData?.advance_json || {};
    const normalizedSection = section.toLowerCase().trim();
    const normalizedField = field.toLowerCase().trim();
    const normalizedValue = value?.toString().trim() || "";

    const updatedAdvance = {
      ...currentAdvance,
      [normalizedSection]: {
        ...currentAdvance[normalizedSection],
        [normalizedField]: normalizedValue,
      },
    };

    return await updateEvent(eventData.id, { advance_json: updatedAdvance });
  };

  const updateAdvanceMutation = useMutation({
    mutationFn: (data) => updateEventAdvance(eventData.id, data),
    onMutate: async (variables) => {
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEvent = queryClient.getQueryData(["event-detail", id]);

      if (previousEvent) {
        queryClient.setQueryData(["event-detail", id], (old) => ({
          ...old,
          advance_json: {
            ...old.advance_json,
            [variables.section.toLowerCase().trim()]: {
              ...old.advance_json?.[variables.section.toLowerCase().trim()],
              [variables.field.toLowerCase().trim()]:
                variables.value?.trim() || "",
            },
          },
        }));
      }
      return { previousEvent };
    },
    onError: (err, variables, context) => {
      if (context?.previousEvent) {
        queryClient.setQueryData(
          ["event-detail", eventData?.id],
          context.previousEvent
        );
      }
      console.error("Failed to update advance:", err);
      toast.error("Failed to save changes. Please try again.");
    },
    onSuccess: (data) => {
      if (eventData?.id) {
        queryClient.invalidateQueries(["event-detail", eventData.id]);
        toast.success("Successfully updated advance information");
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(["event-detail", eventData?.id]);
    },
  });

  // State hooks
  const [openTable, setOpenTable] = useState(null);
  const [showTables, setShowTables] = useState(true);
  const [showTable2, setShowTable2] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  // Debug logging for initial props and data
  console.log("EventAdvance - Initial props:", { id, eventData });
  console.log(
    "EventAdvance - eventData?.advance_json:",
    eventData?.advance_json
  );
  console.log("EventAdvance - eventData structure:", {
    id: eventData?.id,
    name: eventData?.name,
    date: eventData?.date,
    venue: eventData?.venue,
    advance_json: eventData?.advance_json,
  });

  // Parse advance_json if it's a string
  const parseAdvanceJson = useCallback((jsonData) => {
    if (typeof jsonData === "string") {
      try {
        return JSON.parse(jsonData);
      } catch (e) {
        console.error("Failed to parse advance_json string:", e);
        return null;
      }
    }
    return jsonData;
  }, []);

  const defaultEmptyState = {
    eventInfo: {
      headliner: "",
      support1: "",
      support2: "",
      date: "",
      headlinerTime: "",
      venue: "",
      address: "",
      cityStateZip: "",
      mainPhone: "",
      capacity: "",
      website: "",
      venueType: "",
    },
    venueContacts: [
      { header: "GM", name: "", phone: "", cell: "", email: "" },
      { header: "MARKETING", name: "", phone: "", cell: "", email: "" },
      { header: "BOX OFFICE", name: "", phone: "", cell: "", email: "" },
      { header: "PRODUCTION", name: "", phone: "", cell: "", email: "" },
      { header: "CATERING", name: "", phone: "", cell: "", email: "" },
    ],
    tourContacts: [
      { header: "TOUR MGR", name: "", phone: "", cell: "", email: "" },
      { header: "PRODUCTION MGR", name: "", phone: "", cell: "", email: "" },
      { header: "TOUR ACCOUNTANT", name: "", phone: "", cell: "", email: "" },
    ],
    promoters: [
      { header: "BUYER", value: "" },
      { header: "PRODUCTION ADV", value: "" },
      { header: "TICKETING", value: "" },
      { header: "REP/SETTLEMENT", value: "" },
      { header: "TOUR MARKETING", value: "" },
      { header: "LOCAL MARKETING", value: "" },
    ],
    schedule: [
      { header: "VENUE ACCESS", value: "" },
      { header: "BREAKFAST", value: "" },
      { header: "LOCAL LOAD IN", value: "" },
      { header: "TOUR LOAD IN", value: "" },
      { header: "LUNCH", value: "" },
      { header: "SOUND CHECK", value: "" },
      { header: "SECURITY MEETING", value: "" },
      { header: "DINNER", value: "" },
      { header: "DOORS", value: "" },
      { header: "SET 1", value: "" },
      { header: "INTERMISSION", value: "" },
      { header: "SET 2", value: "" },
      { header: "CALL BACK", value: "" },
      { header: "CURFEW", value: "" },
    ],
    laborCalls: [
      { header: "STEWARD", value: "" },
      { header: "HANDS", value: "" },
      { header: "LOADERS", value: "" },
      { header: "RIGGERS (UP)", value: "" },
      { header: "RIGGERS (DOWN)", value: "" },
      { header: "ELECTRICIAN", value: "" },
      { header: "WARDROBE", value: "" },
      { header: "SPOT OPS", value: "" },
      { header: "HOUSE LIGHTS", value: "" },
      { header: "FLY MAN", value: "" },
    ],
    laborRules: [
      { header: "CREW TYPE", value: "" },
      { header: "STEWARD NAME", value: "" },
      { header: "MIN IN", value: "" },
      { header: "MIN SHOW", value: "" },
      { header: "MIN OUT", value: "" },
      { header: "CONVERT", value: "" },
      { header: "FEED BY", value: "" },
      { header: "MIN CREW", value: "" },
      { header: "OVERTIME", value: "" },
      { header: "DARK", value: "" },
    ],
    productionNeeds: [
      { header: "RACK/STACKS", value: "" },
      { header: "CONSOLES", value: "" },
      { header: "LIGHTS", value: "" },
      { header: "VIDEO", value: "" },
      { header: "SPOTLIGHTS", value: "" },
      { header: "CLEAR COMM", value: "" },
      { header: "CURTAINS", value: "" },
      { header: "BIKE RACK", value: "" },
      { header: "BARRICADE", value: "" },
      { header: "FORK LIFT", value: "" },
      { header: "RISERS", value: "" },
      { header: "STROBES", value: "" },
      { header: "HAZE", value: "" },
    ],
    venueTechInfo: [
      { header: "POWER #1", value: "" },
      { header: "POWER #2", value: "" },
      { header: "SHORE POWER", value: "" },
      { header: "STAGE SIZE", value: "" },
      { header: "STAGE LEFT WING", value: "" },
      { header: "STAGE RIGHT WING", value: "" },
      { header: "MIX AREA", value: "" },
      { header: "MIX DISTANCE", value: "" },
      { header: "PROCENIUM", value: "" },
      { header: "STEEL/GRID HEIGHT", value: "" },
      { header: "LOAD-IN TYPE", value: "" },
      { header: "PUSH DISTANCE", value: "" },
      { header: "# OF TRUCKS AT HOME", value: "" },
    ],
    catering: [
      { header: "BREAKFAST", value: "" },
      { header: "LUNCH", value: "" },
      { header: "DRESSING ROOMS", value: "" },
      { header: "DINNER", value: "" },
    ],
    phonesInternet: [
      { header: "PHONE NEEDS", value: "" },
      { header: "INTERNET", value: "" },
    ],
    dressingRooms: [
      { header: "ROOM #1", value: "" },
      { header: "ROOM #2", value: "" },
      { header: "ROOM #3", value: "" },
      { header: "FURNITURE", value: "" },
    ],
    parking: [
      { header: "TRUCKS", value: "" },
      { header: "BUSSES", value: "" },
      { header: "VANS", value: "" },
      { header: "CARS", value: "" },
    ],
    towelsShowersLaundry: [
      { header: "LAUNDRY ON-SITE", value: "" },
      { header: "SHOWERS ON-SITE", value: "" },
      { header: "BATH TOWELS", value: "" },
      { header: "HAND TOWELS", value: "" },
    ],
    estimates: [
      { header: "CATERING", value: "" },
      { header: "HANDS", value: "" },
      { header: "PRODUCTION", value: "" },
    ],
    notes: [
      { header: "Note 1", value: "" },
      { header: "Note 2", value: "" },
      { header: "Note 3", value: "" },
    ],
    cashNeeds: [
      { header: "Cash Need 1", value: "" },
      { header: "Cash Need 2", value: "" },
      { header: "Cash Need 3", value: "" },
    ],
  };

  const form = Form.useForm();
  const { register, handleSubmit, setValue, watch } = form;
  const [formState, setFormState] = useState(defaultEmptyState);

  useEffect(() => {
    if (!eventData?.advance) {
      return;
    }
    // Initialize form state from EventAdvance model
    const initializeFormState = () => {
      try {
        const advanceData = eventData?.advance;
        if (!advanceData) return;

        // Convert snake_case to camelCase
        const convertKey = (key) => {
          return key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        };

        const convertedData = {};
        Object.entries(advanceData).forEach(([key, value]) => {
          convertedData[convertKey(key)] = value;
        });

        if (currentAdvanceJson) {
          if (typeof currentAdvanceJson === "string") {
            parsedJson = JSON.parse(currentAdvanceJson);
          } else {
            parsedJson = currentAdvanceJson;
          }

          // Extract the view-specific data
          const viewData = parsedJson.club || parsedJson.arena || {};
          parsedJson = viewData;
        }

        // Deep merge with default state
        const mergedState = {
          eventInfo: {
            ...defaultEmptyState.eventInfo,
            ...parsedJson.eventInfo,
          },
          venueContacts: {
            ...defaultEmptyState.venueContacts,
            ...parsedJson.venueContacts,
          },
          tourContacts: {
            ...defaultEmptyState.tourContacts,
            ...parsedJson.tourContacts,
          },
          schedule: { ...defaultEmptyState.schedule, ...parsedJson.schedule },
          laborCalls: {
            ...defaultEmptyState.laborCalls,
            ...parsedJson.laborCalls,
          },
          laborRules: {
            ...defaultEmptyState.laborRules,
            ...parsedJson.laborRules,
          },
          productionNeeds: {
            ...defaultEmptyState.productionNeeds,
            ...parsedJson.productionNeeds,
          },
          venueTechInfo: {
            ...defaultEmptyState.venueTechInfo,
            ...parsedJson.venueTechInfo,
          },
          catering: { ...defaultEmptyState.catering, ...parsedJson.catering },
          phonesInternet: {
            ...defaultEmptyState.phonesInternet,
            ...parsedJson.phonesInternet,
          },
          dressingRooms: {
            ...defaultEmptyState.dressingRooms,
            ...parsedJson.dressingRooms,
          },
          parking: { ...defaultEmptyState.parking, ...parsedJson.parking },
          towelsShowersLaundry: {
            ...defaultEmptyState.towelsShowersLaundry,
            ...parsedJson.towelsShowersLaundry,
          },
          estimates: {
            ...defaultEmptyState.estimates,
            ...parsedJson.estimates,
          },
          notes: { ...defaultEmptyState.notes, ...parsedJson.notes },
          cashNeeds: {
            ...defaultEmptyState.cashNeeds,
            ...parsedJson.cashNeeds,
          },
        };

        console.log("Setting form state:", mergedState);
        setFormState(mergedState);
      } catch (error) {
        console.error("Error initializing form state:", error);
        setFormState(defaultEmptyState);
      }
    };

    initializeFormState();
  }, [eventData?.advance_json]);

  // Debug logging for state changes
  useEffect(() => {
    const parsedAdvanceJson = parseAdvanceJson(eventData?.advance_json);
    console.log("EventAdvance - Data Update:", {
      eventDataId: eventData?.id,
      rawAdvanceJson: eventData?.advance_json,
      parsedAdvanceJson,
      currentFormState: formState,
    });
  }, [eventData, formState, parseAdvanceJson]);
  const [grossPotential, setGrossPotential] = useState(0);
  const [ticketFees, setTicketFees] = useState(0);
  const [activeView, setActiveView] = useState(null);
  const [visibleViews, setVisibleViews] = useState({
    club: true,
    arena: true,
  });
  const [viewMode, setViewMode] = useState("all");

  // Memoized values
  const viewTitles = useMemo(
    () => ({
      club: eventData
        ? `${eventData.name} / ${eventData.date} = Advance`
        : "Club/Small Theater View",
      arena: eventData
        ? `${eventData.name} - Advance`
        : "Arena Ampitheater View",
    }),
    [eventData]
  );

  // Query options

  // View states consolidated

  const latestOffer =
    eventData?.offers?.sort(
      (a, b) => new Date(b.modified) - new Date(a.modified)
    )[0] || null;

  const offerID = latestOffer?.id;

  const toggleView = (viewType) => {
    setActiveView(activeView === viewType ? null : viewType);
  };

  const handleToggle = () => {
    setViewMode(viewMode === "all" ? "selected" : "all");
  };

  useEffect(() => {
    if (!eventData?.manifests) return;

    const calcGrossPotential = eventData.manifests.reduce(
      (total, manifest) =>
        total + (manifest.price + (manifest?.ticket_fees || 0)) * manifest.qty,
      0
    );

    const calcTicketFees = eventData.manifests.reduce(
      (total, manifest) => total + manifest.qty * (manifest?.ticket_fees || 0),
      0
    );

    setGrossPotential(calcGrossPotential);
    setTicketFees(calcTicketFees);
  }, [eventData]); // Keep dependencies array as [eventData]

  useEffect(() => {
    if (!eventData) {
      return;
    }

    const latestOffer = eventData?.offers?.sort(
      (a, b) => new Date(b.modified) - new Date(a.modified)
    )[0];

    if (latestOffer) {
      queryClient.invalidateQueries(["offer-detail", latestOffer.id]);
    } else {
      createOfferMutation.mutate({
        production_adv: "production@mammothlive.com",
        local_marketing: "",
        ticketing: "ticketing@mammothlive.com",
        tour_admat: "",
        tour_marketing: "",
        onsale_info: "",
        presale_info: "",
        contracts: "contracts@mammothlive.com",
        draft: false,
        name: "New Offer",
        created: new Date().toISOString(),
        modified: new Date().toISOString(),
        buyer_id: user?.data?.id,
        created_by_id: user?.data?.id,
        events: [eventData.id],
        active: true,
        closed: false,
      });
    }
  }, [eventData, user?.data?.id, queryClient, createOfferMutation]);

  // Define all hooks before any conditional returns
  const handleUpdateAdvance = useCallback(
    (section, field, value) => {
      if (!eventData?.id) {
        toast.error("No event ID found");
        return;
      }

      // Normalize inputs
      const normalizedField = field.toLowerCase().trim();
      const normalizedValue = value?.toString()?.trim() || "";
      const normalizedSection = section.toLowerCase().trim();

      // Update local state first for immediate feedback
      setFormState((prev) => ({
        ...prev,
        [normalizedSection]: {
          ...prev[normalizedSection],
          [normalizedField]: normalizedValue,
        },
      }));

      // Prepare data for API
      const updateData = {
        [normalizedField]: normalizedValue,
      };

      // Send update to backend
      patchEventAdvance(eventData.id, updateData)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to update advance data");
          }
          // Refresh data
          queryClient.invalidateQueries(["event-advance", eventData.id]);
          toast.success("Field updated successfully");
        })
        .catch((error) => {
          console.error("Error updating advance field:", error);
          toast.error(error.message || "Failed to update field");

          // Revert form state on error
          setFormState((prevState) => ({
            ...prevState,
            [section]: {
              ...prevState[section],
              [field]: eventData?.advance?.[field] || "",
            },
          }));
        });
    },
    [eventData?.id, queryClient]
  );

  const handleSubmitAdvance = useCallback(
    (viewType) => {
      if (!eventData?.id) {
        toast.error("No event ID found");
        return;
      }

      setIsSubmitting(true);
      setErrors([]);

      // Validate form data
      const validationErrors = validateEventAdvanceForm(formState);
      if (validationErrors.length > 0) {
        setErrors(validationErrors);
        toast.error("Please fix validation errors before submitting");
        setIsSubmitting(false);
        return;
      }

      // Prepare the data structure
      const sectionData = {};

      // Convert form state into proper structure
      Object.entries(formState).forEach(([section, data]) => {
        if (data && typeof data === "object") {
          sectionData[section] = Object.fromEntries(
            Object.entries(data).map(([key, value]) => [
              key.toLowerCase().trim(),
              value?.toString().trim() || "",
            ])
          );
        }
      });

      const advanceData = {
        advance_json: {
          [viewType]: {
            ...sectionData,
            metadata: {
              lastUpdated: new Date().toISOString(),
              updatedBy: user?.data?.id,
            },
          },
        },
      };

      updateEventAdvance(eventData.id, advanceData)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to update advance data");
          }
          toast.success(`Successfully submitted ${viewType} advance`);
          setErrors([]);

          // Refresh queries
          queryClient.invalidateQueries(["event-advance", eventData.id]);
          queryClient.invalidateQueries(["event-detail", eventData.id]);
        })
        .catch((error) => {
          console.error("Error submitting advance:", error);
          toast.error(error.message || "Failed to submit advance");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [eventData, formState, user?.data?.id, queryClient]
  );

  const handleGetPersonRoles = (roles) => {
    return people?.data
      ?.map((person) => {
        if (roles.includes(person.role_name)) {
          return {
            header: person.role_name,
            value: person.first_name + " " + person.last_name,
          };
        } else {
          return null;
        }
      })
      .filter((person) => person !== null);
  };

  const toggleTable = (tableId) => {
    setOpenTable(openTable === tableId ? null : tableId);
  };

  if (!eventData) {
    return <LoadingScreen />;
  }

  return (
    <div
      className="eventAdvanceWrapper border-t-[1px] border-[#595959] pt-[1rem]"
      data-testid="event-advance"
    >
      <div className="flex flex-col gap-4 mb-12 mt-12">
        <div className="flex flex-row justify-between items-center mb-4">
          <h2 id="section2" className="text-xl font-bold dark:text-cave-white">
            Event Advance
          </h2>
        </div>

        {/* Club/Small Theater View Accordion */}
        {visibleViews.club && (
          <div key="club-view">
            <button
              className={`flex justify-between items-center w-full px-4 py-2 bg-[green] text-left text-lg font-semibold cursor-pointer rounded-[10px] ${styles.chevronAccordionDiv}`}
              onClick={() => toggleView("club")}
            >
              <span className="m-auto dark:text-cave-white">
                {viewTitles.club}
              </span>
              {activeView === "club" ? (
                <ChevronDownIcon className={styles.chevronAccordionDown} />
              ) : (
                <ChevronUpIcon className={styles.chevronAccordionUp} />
              )}
            </button>

            {activeView === "club" && (
              <div className="mt-4">
                <div className="grid grid-cols-12 gap-4">
                  {/* Existing sections */}

                  {/* Labor Calls */}
                  <div className="col-span-6">
                    <ItemTable
                      header={{ value: "Labor Calls" }}
                      rows={
                        eventData?.advance_json?.arenaView?.laborCalls || []
                      }
                      onUpdate={(field, value) =>
                        handleUpdateAdvance("laborCalls", field, value)
                      }
                      color="blue"
                      showTable={showTables}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "", accessor: "value" },
                      ]}
                    />
                  </div>

                  {/* Labor Rules */}
                  <div className="col-span-6">
                    <Section
                      title="Labor Rules"
                      data={
                        eventData?.advance_json?.arenaView?.laborRules || []
                      }
                      onUpdate={(field, value) =>
                        handleUpdateAdvance("laborRules", field, value)
                      }
                      color="blue"
                    />
                  </div>

                  {/* Production Needs */}
                  <div className="col-span-4">
                    <Section
                      title="Production Needs"
                      data={
                        eventData?.advance_json?.arenaView?.productionNeeds ||
                        []
                      }
                      onUpdate={(field, value) =>
                        handleInputChange(
                          "arenaView.productionNeeds",
                          field,
                          value
                        )
                      }
                      color="blue"
                    />
                  </div>

                  {/* Venue Tech Info */}
                  <div className="col-span-4">
                    <Section
                      title="Venue Tech Info"
                      data={
                        eventData?.advance_json?.arenaView?.venueTechInfo || []
                      }
                      onUpdate={(field, value) =>
                        handleInputChange(
                          "arenaView.venueTechInfo",
                          field,
                          value
                        )
                      }
                      color="blue"
                    />
                  </div>

                  {/* Catering */}
                  <div className="col-span-4">
                    <Section
                      title="Catering"
                      data={eventData?.advance_json?.arenaView?.catering || []}
                      onUpdate={(field, value) =>
                        handleInputChange("arenaView.catering", field, value)
                      }
                      color="blue"
                    />
                  </div>

                  {/* Phones/Internet */}
                  <div className="col-span-4">
                    <Section
                      title="Phones/Internet"
                      data={
                        eventData?.advance_json?.arenaView?.phonesInternet || []
                      }
                      onUpdate={(field, value) =>
                        handleInputChange(
                          "arenaView.phonesInternet",
                          field,
                          value
                        )
                      }
                      color="blue"
                    />
                  </div>

                  {/* Dressing Rooms */}
                  <div className="col-span-4">
                    <Section
                      title="Dressing Rooms"
                      data={
                        eventData?.advance_json?.arenaView?.dressingRooms || []
                      }
                      onUpdate={(field, value) =>
                        handleInputChange(
                          "arenaView.dressingRooms",
                          field,
                          value
                        )
                      }
                      color="blue"
                    />
                  </div>

                  {/* Parking */}
                  <div className="col-span-4">
                    <Section
                      title="Parking"
                      data={eventData?.advance_json?.arenaView?.parking || []}
                      onUpdate={(field, value) =>
                        handleInputChange("arenaView.parking", field, value)
                      }
                      color="blue"
                    />
                  </div>

                  {/* Towels/Showers/Laundry */}
                  <div className="col-span-6">
                    <Section
                      title="Towels/Showers/Laundry"
                      data={formState.towelsShowersLaundry || []}
                      onUpdate={(field, value) =>
                        handleInputChange(
                          "arenaView.towelsShowersLaundry",
                          field,
                          value
                        )
                      }
                      color="blue"
                    />
                  </div>

                  {/* Estimates */}
                  <div className="col-span-6">
                    <Section
                      title="Estimates"
                      data={formState.estimates || []}
                      onUpdate={(field, value) =>
                        handleInputChange("arenaView.estimates", field, value)
                      }
                      color="blue"
                    />
                  </div>
                  {/* Event Info Table */}
                  <FormBuilder form={form} onSubmit={handleSubmitAdvance}>
                    <div
                      className={`col-start-1 col-span-3 row-start-1 row-span-2 ${styles.chevronAccordionDiv}`}
                      onClick={() => toggleTable("eventInfo")}
                    >
                      <ItemTable
                        header={{ value: "EVENT INFO" }}
                        form={form}
                        rows={[
                          {
                            header: "HEADLINER",
                            value: eventData?.offer_headliner || "",
                            editable: false,
                          },
                          {
                            header: "SUPPORT 1",
                            value: eventData?.offer_support_1 || "",
                            editable: false,
                          },
                          {
                            header: "SUPPORT 2",
                            value: eventData?.offer_support_2 || "",
                            editable: true,
                          },
                          {
                            header: "DAY/DATE",
                            value: eventData?.date
                              ? new Date(eventData.date).toDateString()
                              : "No date",
                            editable: false,
                          },
                          {
                            header: "HEADLINER TIME",
                            value: eventData?.headliner_time || "",
                            editable: true,
                          },
                          {
                            header: "VENUE",
                            value: eventData?.venue?.name || "",
                            editable: false,
                          },
                          {
                            header: "ADDRESS",
                            value: eventData?.venue?.address || "",
                            editable: false,
                          },
                          {
                            header: "CITY/STATE/ZIP",
                            value: eventData?.venue
                              ? `${eventData.venue.city || ""}, ${
                                  eventData.venue.state || ""
                                } ${eventData.venue.zipcode || ""}`
                              : "",
                            editable: false,
                          },
                          {
                            header: "MAIN PHONE",
                            value: eventData?.venue?.phone || "",
                            editable: true,
                          },
                          {
                            header: "CAPACITY",
                            value: eventData?.venue?.capacity || "",
                            editable: true,
                          },
                          {
                            header: "WEBSITE",
                            value: eventData?.venue?.website || "",
                            editable: true,
                          },
                          { header: "VENUE TYPE", value: "", editable: true },
                        ]}
                        onValuesChange={(changedValues, allValues) => {
                          handleUpdateAdvance("eventInfo", changedValues);
                        }}
                        columns={[
                          { header: "", accessor: "header" },
                          { header: "", accessor: "value" },
                        ]}
                        color="red"
                        showTable={showTables}
                        showChevrons={false}
                      />
                    </div>
                  </FormBuilder>
                  {/* Venue Contacts */}
                  <div
                    className={`col-start-4 col-span-9 row-start-1 row-span-1 ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("venueContacts")}
                  >
                    <ItemTable
                      header={{ value: "VENUE CONTACTS" }}
                      rows={[
                        {
                          header: "GM",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "MARKETING",
                          Name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "BOX OFFICE",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "PRODUCTION",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "CATERING",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "name" },
                        { header: "Phone", accessor: "phone" },
                        { header: "Cell", accessor: "cell" },
                        { header: "Email", accessor: "email" },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Tour Contacts */}
                  <div
                    className={`col-start-4 col-span-9 row-start-2 row-span-1  ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("tourContacts")}
                  >
                    <ItemTable
                      header={{ value: "TOUR CONTACTS" }}
                      rows={[
                        {
                          header: "TOUR MGR",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "PRODUCTION MGR",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "TOUR ACCOUNTANT",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "",
                          editable: true,
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "name" },
                        { header: "Phone", accessor: "phone" },
                        { header: "Cell", accessor: "cell" },
                        { header: "Email", accessor: "email" },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Schedule */}
                  <div
                    className={`col-start-1 col-span-3 row-start-3 row-span-2 ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("schedule")}
                  >
                    <ItemTable
                      header={{ value: "SCHEDULE" }}
                      rows={[
                        {
                          header: "GM",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "VENUE ACCESS",
                          accessor: "venue_access",
                          value: eventData?.schedule?.venue_access || "",
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "BREAKFAST",
                          accessor: "breakfast",
                          value: eventData?.schedule?.breakfast || "",
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "LOCAL LOAD IN",
                          accessor: "local_load_in",
                          value: eventData?.schedule?.local_load_in || "",
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "TOUR LOAD IN",
                          accessor: "tour_load_in",
                          value: eventData?.schedule?.tour_load_in || "",
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "LUNCH",
                          accessor: "lunch",
                          value: eventData?.schedule?.lunch || "",
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "SOUND CHECK",
                          accessor: "sound_check",
                          value: eventData?.schedule?.sound_check || "",
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "SECURITY MEETING",
                          accessor: "security_meeting",
                          value: eventData.schedule?.security_meeting || "",
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "DINNER",
                          accessor: "dinner",
                          value: eventData.schedule?.dinner,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "DOORS",
                          accessor: "doors",
                          value: eventData.schedule?.doors,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "SET 1",
                          accessor: "set_1",
                          value: eventData.schedule?.set_1,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "INTERMISSION",
                          accessor: "intermission",
                          value: eventData.schedule?.intermission,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "SET 2",
                          accessor: "set_2",
                          value: eventData.schedule?.set_2,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "CALL BACK",
                          accessor: "call_back",
                          value: eventData.schedule?.call_back,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "CURFEW",
                          accessor: "curfew",
                          value: eventData.schedule?.curfew,
                          format: "time",
                          editable: true,
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "", accessor: "" },
                      ]}
                      color="red"
                      onUpdate={(field, value) =>
                        handleUpdate("schedule", field, value)
                      }
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Outside Vendors */}
                  <div
                    className={`col-start-4 col-span-9 row-start-3 row-span-1  ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("outsideVendors")}
                  >
                    <ItemTable
                      header={{ value: "OUTSIDE VENDORS" }}
                      rows={[
                        { header: "SOUND/LIGHTS", value: "", editable: true },
                        { header: " ", value: "", editable: true },
                        { header: " ", value: "", editable: true },
                        { header: "", value: "", editable: true },
                        { header: "", value: "", editable: true },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "name" },
                        { header: "Phone", accessor: "phone" },
                        { header: "Cell", accessor: "cell" },
                        { header: "Email", accessor: "email" },
                      ]}
                      color="red"
                      columnWidths={["150px", "300px", "200px"]}
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Promoters */}
                  <div
                    className={`col-start-4 col-span-9 row-start-4 row-span-1  ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("promoters")}
                  >
                    <ItemTable
                      header={{ value: "PROMOTERS" }}
                      rows={[
                        {
                          header: "BUYER",
                          value:
                            variablesData?.data?.buyer_name ||
                            (eventData?.buyer
                              ? `${eventData.buyer.first_name} ${eventData.buyer.last_name}`
                              : ""),
                          editable: false,
                        },
                        {
                          header: "PRODUCTION ADV",
                          value:
                            eventData.offers[0].production_adv ||
                            "production@mammothlive.com",
                          editable: false,
                        },
                        {
                          header: "TICKETING",
                          value: eventData.offers[0].ticketing,
                          editable: false,
                        },
                        {
                          header: "REP/SETTLEMENT",
                          value: "-",
                          editable: true,
                        },
                        {
                          header: "TOUR MARKETING",
                          value: eventData.offers[0].tour_marketing,
                          editable: true,
                        },
                        {
                          header: "LOCAL MARKETING",
                          value: eventData.offers[0].local_marketing,
                          editable: true,
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "value" },
                        { header: "Phone", accessor: "" },
                        { header: "Cell", accessor: "" },
                        { header: "Email", accessor: "" },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>
                </div>
                <div className="mt-[1rem]">
                  <Button onClick={() => handleSubmitAdvance("club")}>
                    Submit Advance
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Arena/Ampitheater View Accordion */}
        {visibleViews.arena && (
          <div>
            <button
              className={`lex justify-between items-center w-full px-4 py-2 bg-[blue] text-left text-lg font-semibold cursor-pointer rounded-[10px] ${styles.chevronAccordionDiv}`}
              onClick={() => toggleView("arena")}
            >
              <span className="m-auto dark:text-cave-white">
                {viewTitles.arena}
              </span>
              {activeView === "arena" ? (
                <ChevronDownIcon className={styles.chevronAccordionDown} />
              ) : (
                <ChevronUpIcon className={styles.chevronAccordionUp} />
              )}
            </button>

            {activeView === "arena" && (
              <div className="grid grid-cols-12 grid-rows-48 gap-4 mt-[1rem]">
                <div
                  className={`col-start-1 col-span-3 row-start-1 row-span-2 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("eventInfo")}
                >
                  <ItemTable
                    header={{ value: "EVENT INFO" }}
                    rows={[
                      {
                        header: "HEADLINER",
                        value: eventData?.offer_headliner || "",
                        editable: false,
                      },
                      {
                        header: "SUPPORT 1",
                        value: eventData?.offer_support_1 || "",
                        editable: false,
                      },
                      {
                        header: "SUPPORT 2",
                        value: eventData?.offer_support_2 || "",
                        editable: true,
                      },
                      {
                        header: "DAY/DATE",
                        value: eventData?.date
                          ? new Date(eventData.date).toDateString()
                          : "No date",
                        editable: false,
                      },
                      {
                        header: "HEADLINER TIME",
                        value: eventData.headliner_time,
                        editable: true,
                      },
                      {
                        header: "VENUE",
                        value: eventData?.venue?.name || "",
                        editable: false,
                      },
                      {
                        header: "ADDRESS",
                        value: eventData?.venue?.address || "",
                        editable: false,
                      },
                      {
                        header: "CITY/STATE/ZIP",
                        value: eventData?.venue
                          ? `${eventData.venue.city || ""}, ${
                              eventData.venue.state || ""
                            } ${eventData.venue.zipcode || ""}`
                          : "",
                        editable: false,
                      },
                      {
                        header: "MAIN PHONE",
                        value: eventData?.venue?.phone || "",
                        editable: true,
                      },
                      {
                        header: "CAPACITY",
                        value: eventData?.venue?.capacity || "",
                        editable: true,
                      },
                      {
                        header: "WEBSITE",
                        value: eventData?.venue?.website || "",
                        editable: true,
                      },

                      { header: "VENUE TYPE", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "value" },
                    ]}
                    color="red"
                    // showTable={openTable === "eventInfo"}
                    showTable={showTables}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-1 row-span-1 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("venueContacts")}
                >
                  <ItemTable
                    header={{ value: "VENUE CONTACTS" }}
                    rows={[
                      {
                        header: "GM",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "MARKETING",
                        Name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "BOX OFFICE",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "PRODUCTION",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "CATERING",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "name" },
                      { header: "Phone", accessor: "phone" },
                      { header: "Cell", accessor: "cell" },
                      { header: "Email", accessor: "email" },
                    ]}
                    color="red"
                    // showTable={openTable === "venueContacts"}
                    showTable={showTables}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-2 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("tourContacts")}
                >
                  <ItemTable
                    header={{ value: "TOUR CONTACTS" }}
                    rows={[
                      {
                        header: "TOUR MGR",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "PRODUCTION MGR",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "TOUR ACCOUNTANT",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "",
                        editable: true,
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "name" },
                      { header: "Phone", accessor: "phone" },
                      { header: "Cell", accessor: "cell" },
                      { header: "Email", accessor: "email" },
                    ]}
                    // rows={[
                    //   { header: "TOUR MGR", value: "" },
                    //   { header: "PRODUCTION MGR", value: "" },
                    //   { header: "TOUR ACCOUNTANT", value: "" },
                    //   { header: "", value: "" },
                    //   { header: "", value: "" },
                    // ]}
                    // rows={handleGetPersonRoles[
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    //   "Tour Accountant",
                    //   "Tour Accountant",
                    // ]}
                    color="red"
                    onUpdate={(field, value) =>
                      handleUpdate(header.value.toLowerCase(), field, value)
                    }
                    // showTable={openTable === "tourContacts"}
                    showTable={showTables}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-3 row-start-3 row-span-2 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("schedule")}
                >
                  <ItemTable
                    header={{ value: "SCHEDULE" }}
                    rows={[
                      {
                        header: "GM",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "VENUE ACCESS",
                        accessor: "venue_access",
                        value: eventData?.schedule?.venue_access || "",
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "BREAKFAST",
                        accessor: "breakfast",
                        value: eventData?.schedule?.breakfast || "",
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "LOCAL LOAD IN",
                        accessor: "local_load_in",
                        value: eventData?.schedule?.local_load_in || "",
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "TOUR LOAD IN",
                        accessor: "tour_load_in",
                        value: eventData?.schedule?.tour_load_in || "",
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "LUNCH",
                        accessor: "lunch",
                        value: eventData?.schedule?.lunch || "",
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "SOUND CHECK",
                        accessor: "sound_check",
                        value: eventData?.schedule?.sound_check || "",
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "SECURITY MEETING",
                        accessor: "security_meeting",
                        value: eventData.schedule?.security_meeting,
                        format: "time",
                        editable: true,
                      },
                      {
                        header: "DINNER",
                        accessor: "dinner",
                        value: eventData.schedule?.dinner,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "DOORS",
                        accessor: "doors",
                        value: eventData.schedule?.doors,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "SET 1",
                        accessor: "set_1",
                        value: eventData.schedule?.set_1,
                        format: "time",
                        editable: true,
                      },
                      {
                        header: "INTERMISSION",
                        accessor: "intermission",
                        value: eventData.schedule?.intermission,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "SET 2",
                        accessor: "set_2",
                        value: eventData.schedule?.set_2,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "CALL BACK",
                        accessor: "call_back",
                        value: eventData.schedule?.call_back,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "CURFEW",
                        accessor: "curfew",
                        value: eventData.schedule?.curfew,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    color="red"
                    onUpdate={handleUpdateAdvance}
                    // showTable={openTable === "schedule"}
                    showTable={showTables}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-3 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("outsideVendors")}
                >
                  <ItemTable
                    header={{ value: "OUTSIDE VENDORS" }}
                    rows={[
                      { header: "SOUND/LIGHTS", value: "", editable: true },
                      { header: " ", value: "", editable: true },
                      { header: " ", value: "", editable: true },
                      { header: "", value: "", editable: true },
                      { header: "", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "name" },
                      { header: "Phone", accessor: "phone" },
                      { header: "Cell", accessor: "cell" },
                      { header: "Email", accessor: "email" },
                    ]}
                    // rows={handleGetPersonRoles(["Sound", "Lights"])}
                    color="red"
                    columnWidths={["150px", "300px", "200px"]}
                    onUpdate={handleUpdateAdvance}
                    // showTable={openTable === "outsideVendors"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-4 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("promoters")}
                >
                  <ItemTable
                    header={{ value: "PROMOTERS" }}
                    rows={[
                      {
                        header: "BUYER",
                        value:
                          variablesData?.data?.buyer_name ||
                          (eventData?.buyer
                            ? `${eventData.buyer.first_name} ${eventData.buyer.last_name}`
                            : ""),
                        editable: false,
                      },
                      {
                        header: "PRODUCTION ADV",
                        value:
                          eventData?.offers?.[0]?.production_adv ||
                          "production@mammothlive.com",
                        editable: false,
                      },
                      {
                        header: "TICKETING",
                        value: eventData?.offers?.[0]?.ticketing || "",
                        editable: false,
                      },
                      {
                        header: "REP/SETTLEMENT",
                        value: "-",
                        editable: true,
                      },
                      {
                        header: "TOUR MARKETING",
                        value: eventData?.offers?.[0]?.tour_marketing || "",
                        editable: true,
                      },
                      {
                        header: "LOCAL MARKETING",
                        value: eventData?.offers?.[0]?.local_marketing || "",
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "value" },
                      { header: "Phone", accessor: "" },
                      { header: "Cell", accessor: "" },
                      { header: "Email", accessor: "" },
                    ]}
                    color="red"
                    onUpdate={handleUpdateAdvance}
                    // showTable={openTable === "promoters"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-6 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("laborCalls")}
                >
                  <ItemTable
                    header={{ value: "LABOR CALLS" }}
                    rows={[
                      { header: "STEWARD", value: "", editable: true },
                      { header: "HANDS", value: "", editable: true },
                      { header: "LOADERS", value: "", editable: true },
                      { header: "RIGGERS (UP)", value: "", editable: true },
                      { header: "RIGGERS (DOWN)", value: "", editable: true },
                      { header: "ELECTRITION", value: "", editable: true },
                      { header: "WARDROBE", value: "", editable: true },
                      { header: "SPOT OPTS", value: "", editable: true },
                      { header: "HOUSE LIGHTS", value: "", editable: true },
                      { header: "FLY MAN", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "LOAD IN:", accessor: "name" },
                      { header: "SHOW CALL:", accessor: "phone" },
                      { header: "LOAD OUT:", accessor: "cell" },
                    ]}
                    // rows={handleGetPersonRoles([
                    // rows={["Tour Manager", "Production Manager", "Tour Accountant"]}
                    color="red"
                    onUpdate={handleUpdateAdvance}
                    // showTable={openTable === "laborCalls"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-7 col-span-3 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("laborRules1")}
                >
                  <ItemTable
                    header={{ value: "LABOR TYPE/RULES" }}
                    rows={[
                      { header: "CREW TYPE", value: "", editable: true },
                      { header: "STEWARD NAME", value: "", editable: true },
                      { header: "MIN IN", value: "", editable: true },
                      { header: "MIN SHOW", value: "", editable: true },
                      { header: "MIN OUT", value: "", editable: true },
                      { header: "CONVERT", value: "", editable: true },
                      { header: "FEED BY", value: "", editable: true },
                      { header: "MIN CREW", value: "", editable: true },
                      { header: "OVERTIME", value: "", editable: true },
                      { header: "DARK", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "laborRules1"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-10 col-span-3 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("laborRules2")}
                >
                  <ItemTable
                    header={{ value: "LABOR TYPE/RULES" }}
                    rows={[
                      { header: "CREW TYPE", value: "", editable: true },
                      { header: "STEWARD NAME", value: "", editable: true },
                      { header: "MIN IN", value: "", editable: true },
                      { header: "MIN SHOW", value: "", editable: true },
                      { header: "MIN OUT", value: "", editable: true },
                      { header: "CONVERT", value: "", editable: true },
                      { header: "FEED BY", value: "", editable: true },
                      { header: "MIN CREW", value: "", editable: true },
                      { header: "OVERTIME", value: "", editable: true },
                      { header: "DARK", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "laborRules2"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-4 row-start-8 row-span-3  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("tourContacts")}
                >
                  <ItemTable
                    header={{ value: "PRODUCTION PROVIDERS/NEEDS" }}
                    rows={[
                      { header: "RACK/STACKS", value: "", editable: true },
                      { header: "CONSOLES", value: "", editable: true },
                      { header: "LIGHTS", value: "", editable: true },
                      { header: "VIDEO", value: "", editable: true },
                      { header: "SPOTLIGHTS", value: "", editable: true },
                      { header: "CLEAR COMM", value: "", editable: true },
                      { header: "CURTAINS", value: "", editable: true },
                      { header: "BIKE RACK", value: "", editable: true },
                      { header: "BARRICADE", value: "", editable: true },
                      { header: "FORK LIFT", value: "", editable: true },
                      { header: "RISERS", value: "", editable: true },
                      { header: "STROBES", value: "", editable: true },
                      { header: "HAZE", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "tourContacts"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-5 col-span-4 row-start-8 row-span-3 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("venuTechStageInfo")}
                >
                  <ItemTable
                    header={{ value: "VENU TECH/STAGE INFO" }}
                    rows={[
                      { header: "POWER #1", value: "", editable: true },
                      { header: "POWER #2", value: "", editable: true },
                      { header: "SHORE POWER", value: "", editable: true },
                      { header: "STAGE SIZE", value: "", editable: true },
                      {
                        header: "STAGE LEFT WING",
                        value: "",
                        editable: true,
                      },
                      {
                        header: "STAGE RIGHT WING",
                        value: "",
                        editable: true,
                      },
                      { header: "MIX AREA", value: "", editable: true },
                      { header: "MIX DISTANCE", value: "", editable: true },
                      { header: "PROCENIUM", value: "", editable: true },
                      {
                        header: "STEEL/GRID HEIGHT",
                        value: "",
                        editable: true,
                      },
                      { header: "LOAD-IN TYPE", value: "", editable: true },
                      { header: "PUSH DISTANCE", value: "", editable: true },
                      {
                        header: "# OF TRUCKS AT HOME",
                        value: "",
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "venuTechStageInfo"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start-8 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("cateringinfo")}
                >
                  <ItemTable
                    header={{ value: "CATERING INFO" }}
                    rows={[
                      { header: "BREAKFAST", value: "", editable: true },
                      { header: "LUNCH", value: "", editable: true },
                      { header: "DRESSING ROOMS", value: "", editable: true },
                      { header: "DINNER", value: "", editable: true },
                      { header: "", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "cateringinfo"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start- row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("phonesInternet")}
                >
                  <ItemTable
                    header={{ value: "PHONES/INTERNET" }}
                    rows={[
                      { header: "PHONE NEEDS", value: "", editable: true },
                      { header: "INTERNET", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header", editable: true },
                      { header: "", accessor: "", editable: true },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "phonesInternet"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("dressingRoomNeeds")}
                >
                  <ItemTable
                    header={{ value: "DRESSING ROOM NEEDS" }}
                    rows={[
                      { header: "ROOM #1", value: "", editable: true },
                      { header: "ROOM #2", value: "", editable: true },
                      { header: "ROOM #3", value: "", editable: true },
                      { header: "FURNITURE", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "dressingRoomNeeds"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("parking")}
                >
                  <ItemTable
                    header={{ value: "PARKING" }}
                    rows={[
                      { header: "TRUCKS", value: "", editable: true },
                      { header: "BUSSES", value: "", editable: true },
                      { header: "VANS", value: "", editable: true },
                      { header: "CARS", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "parking"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-5 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("towelsShowersLaundry")}
                >
                  <ItemTable
                    header={{ value: "TOWELS/SHOWERS/LAUNDRY" }}
                    rows={[
                      {
                        header: "LAUNDRY ON-SITE",
                        value: "",
                        editable: true,
                      },
                      {
                        header: "SHOWERS ON-SITE",
                        value: "",
                        editable: true,
                      },
                      { header: "BATH TOWELS", value: "", editable: true },
                      { header: "HAND TOWELS", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "towelsShowersLaundry"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("estimates")}
                >
                  <ItemTable
                    header={{ value: "ESTIMATES" }}
                    rows={[
                      { header: "CATERING", value: "", editable: true },
                      { header: "HANDS", value: "", editable: true },
                      { header: "PRODUCTION", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "estimates"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-6 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("notes")}
                >
                  <ItemTable
                    header={{ value: "NOTES" }}
                    rows={[
                      {
                        header: "Note 1",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "Note 2",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "Note 3",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "value" },
                    ]}
                    showTable={true}
                    showChevrons={false}
                    onUpdate={(accessor, value) =>
                      console.log(`Updated ${accessor}: ${value}`)
                    }
                    color="red"
                  />
                </div>

                <div
                  className={`col-start-7 col-span-6 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("cashNeeds")}
                >
                  <ItemTable
                    header={{ value: "CASH NEEDS" }}
                    rows={[
                      {
                        header: "",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "cashNeeds"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>
                <div className="mt-[1rem]">
                  <Button onClick={() => handleSubmitAdvance("arena")}>
                    Submit Advance
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const EventAdvance = React.memo(EventAdvanceM);
const Section = ({ title, data, onUpdate, color = "gray" }) => {
  // Ensure data is an array
  const items = Array.isArray(data) ? data : [];
  const [showContent, setShowContent] = React.useState(true);

  const thClasses = classNames(
    "cursor-pointer text-center font-bold text-cave-white text-md font-inter",
    { "bg-[#4E6B85] hover:bg-[#3A4F61]": color === "gray" },
    { "bg-red-500 hover:bg-red-700": color === "red" },
    { "bg-blue-500 hover:bg-blue-700": color === "blue" }
  );

  return (
    <div className="w-full">
      <div className={thClasses} onClick={() => setShowContent(!showContent)}>
        <div className="flex justify-center items-center p-2">
          <span>{title}</span>
          {showContent ? (
            <ChevronDownIcon className={styles.chevronAccordionDown} />
          ) : (
            <ChevronUpIcon className={styles.chevronAccordionUp} />
          )}
        </div>
      </div>

      {showContent && (
        <div className="bg-cave-white">
          {items.map((item, index) => (
            <div
              key={index}
              className={`flex p-2 ${index % 2 === 0 ? "bg-gray-50" : ""}`}
            >
              <div className="flex-1 font-bold">{item.header}:</div>
              <div
                className="flex-1"
                contentEditable={true}
                suppressContentEditableWarning={true}
                onBlur={(e) => onUpdate(item.header, e.target.textContent)}
              >
                {item.value}
              </div>
            </div>
          ))}
          {items.length === 0 && (
            <div className="p-2 text-center text-gray-500">
              what No data available
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventAdvance;
