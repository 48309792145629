import React, { useState, useRef, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DayPicker } from "react-day-picker";
import { format, parse } from "date-fns";
import "react-day-picker/dist/style.css";
import { getEventDate, postEventDate } from "@/queries/events.js";

export const DateChanger = ({ eventId }) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const popoverRef = useRef(null);

  // Query to fetch the current event date
  const {
    data: currentDate,
    isLoading: isDateLoading,
    isError: isDateError,
  } = useQuery({
    queryKey: ["eventDate", eventId],
    queryFn: () => getEventDate(eventId),
    select: (data) =>
      data.date ? parse(data.date, "yyyy-MM-dd", new Date()) : undefined,
  });

  // Mutation to update the event date
  const {
    mutate: updateDate,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
  } = useMutation({
    mutationFn: (newDate) =>
      postEventDate(eventId, {
        date: newDate ? format(newDate, "yyyy-MM-dd") : null,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["eventDate", eventId]);
      setIsOpen(false);
    },
  });

  // Handle date change
  const handleDateChange = (date) => {
    updateDate(date);
  };

  // Handle clear date
  const handleClearDate = () => {
    updateDate(null);
  };

  // Close popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (isDateLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  if (isDateError) {
    return <div>Error fetching event date</div>;
  }

  const buttonText = currentDate ? format(currentDate, "MM/dd/yyyy") : "TBD";

  return (
    <div>
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className="px-4 py-2 bg-blue-500 text-cave-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
      >
        Event Date: {buttonText}
      </button>
      {isOpen && (
        <div
          ref={popoverRef}
          className="absolute z-[10001] bg-cave-white border border-gray-200 rounded-lg shadow-lg p-4"
        >
          <DayPicker
            mode="single"
            selected={currentDate}
            onSelect={handleDateChange}
            disabled={isUpdateLoading}
          />
          <button
            onClick={handleClearDate}
            className="mt-2 px-4 py-2 bg-red-500 text-cave-white font-semibold rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
            disabled={isUpdateLoading || !currentDate}
          >
            Clear Date
          </button>
        </div>
      )}
      {isUpdateLoading && <div>Updating date...</div>}
      {isUpdateError && (
        <div>
          Error updating date: {updateError?.message || "Unknown error"}
        </div>
      )}
    </div>
  );
};
