import { SettlementTab } from "@/types/settlement";
import { useSettlementComponent } from "./useSettlementComponent";

export function useFinalFigures(book: SettlementTab) {
  const { event, rollups, eventSettlement } = useSettlementComponent();
  if (!event?.data || !rollups?.data || !eventSettlement?.data) return null;
  const settlementRollups = eventSettlement.data?.[book as string]?.rollups;
  const isRollupsData =
    settlementRollups && Object.keys(settlementRollups).length;
  const dataToUse = (key: string) =>
    isRollupsData && settlementRollups[key]
      ? settlementRollups[key]
      : (rollups.data[key] ?? event.data[key]);

  return {
    // event,
    // rollups,
    // eventSettlement,
    // settlementRollups,
    // isRollupsData,
    dataToUse,
  };
}
