import React from "react";
import SettlementInputField from "./SettlementInputField";
import SettlementTableTitle from "./SettlementTableTitle";

type RowData = {
  key: string;
  label: string;
  value: string | number;
  isEditable?: boolean;
  format?: "text" | "money";
};

type SettlementVerticalTableProps = {
  title?: string;
  headers?: string[];
  data: RowData[];
  onBlur: (key: string, newValue: string | number) => void;
  onChange?: (key: string, newValue: string | number) => void;
  className?: string;
  valueClass?: string;
};

const SettlementVerticalTable: React.FC<SettlementVerticalTableProps> = ({
  title,
  headers,
  data,
  onBlur,
  onChange,
  className,
  valueClass,
}) => {
  const handleFieldChange = (key: string, newValue: string | number) => {
    if (onChange) onChange(key, newValue); // Pass updated value to parent
  };

  return (
    <div className={className}>
      <SettlementTableTitle>{title}</SettlementTableTitle>
      <div className="space-y-4">
        {headers && (
          <div className="grid grid-cols-2 gap-4">
            {headers.map((header, index) => (
              <div key={index} className="font-semibold bg-gray-200 p-2 border">
                {header}
              </div>
            ))}
          </div>
        )}
        <div>
          {data.length === 0 ? (
            <span className="inline-block font-medium px-2">
              No Data Found.
            </span>
          ) : (
            <>
              {data.map((row, index) => (
                <div
                  key={index}
                  className="flex items-center border bg-cave-white"
                >
                  <label className="font-medium w-[58%]">{row.label}</label>
                  <SettlementInputField
                    className={valueClass}
                    value={row.value} // Controlled value
                    editable={
                      row.isEditable ||
                      (typeof row.editable !== "undefined" &&
                        row.editable !== false)
                    } // Editable or not
                    format={row?.format}
                    onBlur={(e) => onBlur(row.key, e.target.value)} // Handle blur
                    onChange={(newValue) =>
                      handleFieldChange(row.key, newValue)
                    } // Handle input change
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettlementVerticalTable;
