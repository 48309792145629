import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createEventManifest,
  deleteEventManifest,
  getEvent,
  updateManifest,
} from "@/queries/events";
import { toast } from "react-toastify";
import { CaveEvent, Manifest } from "@/types/event";

/**
 * Custom hook to manage manifests
 */
export const useEventManifests = (eventId: string) => {
  const queryClient = useQueryClient();

  /**
   * Fetch Manifests
   */
  const {
    data: manifests,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery<Manifest[], Error>(["manifests", eventId], () =>
    getEvent(eventId).then((response: CaveEvent) =>
      response.manifests.filter((m) => m.active && m.is_offer)
    )
  );

  /**
   * Add Manifest Mutation
   */
  const addManifestMutation = useMutation<
    Manifest,
    Error,
    Partial<Manifest>,
    unknown
  >(
    (newManifest) =>
      createEventManifest(eventId, {
        ...newManifest,
        eventId,
        is_offer: true,
      }),
    {
      onSuccess: () => {
        toast.success("Manifest added successfully");
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
      onError: () => {
        toast.error("Failed to add manifest");
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
    }
  );

  /**
   * Update Manifest Mutation
   */
  const updateManifestMutation = useMutation<
    Manifest,
    Error,
    { id: string; updates: Partial<Manifest> },
    unknown
  >(
    ({ id, updates }) => {
      const manifest = manifests?.find(
        (m) => m.id?.toString() === id?.toString()
      );
      return updateManifest(id, {
        ...manifest,
        ...updates,
        eventId,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
      onError: () => {
        toast.error("Failed to update manifest");
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
    }
  );

  /**
   * Delete Manifest Mutation
   */
  const deleteManifestMutation = useMutation<void, Error, string, unknown>(
    (manifestId) => deleteEventManifest(eventId, manifestId),
    {
      onSuccess: () => {
        toast.success("Manifest deleted successfully");
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
      onError: () => {
        toast.error("Failed to delete manifest");
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
    }
  );

  return {
    manifests,
    isLoading,
    isError,
    error,
    refetch,
    addManifest: addManifestMutation.mutate,
    updateManifest: updateManifestMutation.mutate,
    deleteManifest: deleteManifestMutation.mutate,
  };
};
