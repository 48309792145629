import React, { useState, useEffect } from "react";
import { Header } from "@/components/Header";
import { useUser } from "@/components/Auth";
import { DashboardLoading } from "./DashboardLoading";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";
import { useQuery } from "@tanstack/react-query";
import { getRecentVisits, recentVisitsQuerySettings } from "@/queries/visit";
import { getPersonEventsRoles } from "@/queries/people";
import { useNavigate } from "react-router-dom";
import { NextEventCard } from "./NextEvent/NextEvent";
//import { BulletinBoard } from "./BulletinBoard/BulletinBoard";
import { BulletinBoard } from "@/components/Dashboard/BulletinBoard/bb";
import { EventCard } from "./EventCard/EventCard";
import {
  getEventsDashboardList,
  getEventsSettings,
  getMyDrafts,
} from "../../queries/events";
import { Carousel } from "./Carousel/Carousel";
import { usePageTitle } from "@/utils/pagetitle";
import { usePerms } from "@/components/Auth";

export const Dashboard = () => {
  const { user } = useUser();
  const { perms } = usePerms();
  const navigate = useNavigate();
  const recent_visits = useQuery(
    ["recent-visits"],
    () => getRecentVisits(),
    recentVisitsQuerySettings
  );

  // Get all confirmed events for Dashboard
  const events = useQuery(
    ["events"],
    () => getEventsDashboardList(),
    getEventsSettings
  );

  // Get my Drafts
  const drafts = useQuery(
    ["events", "drafts"],
    () => getMyDrafts(),
    getEventsSettings
  );

  const myEvents = useQuery(
    ["person-events", user?.data?.person_id],
    () => {
      if (!user?.data?.person_id) {
        console.warn("No person_id available, skipping query", {
          userData: user?.data,
          email: user?.data?.email,
          timestamp: new Date().toISOString(),
        });
        return Promise.resolve({ events_roles: [] });
      }
      console.info("Fetching events for person_id:", user.data.person_id);
      return getPersonEventsRoles(user.data.person_id, true);
    },
    {
      enabled: !!user?.data?.person_id,
      ...getEventsSettings,
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 10000),
      staleTime: 5 * 60 * 1000, // 5 minutes
      onError: (error) => {
        console.error("Error in myEvents query:", {
          name: error.name,
          message: error.message,
          stack: error.stack,
          personId: user?.data?.person_id,
          timestamp: new Date().toISOString(),
        });
      },
      onSuccess: (data) => {
        console.log("Successfully fetched myEvents:", {
          personId: user?.data?.person_id,
          eventCount: data?.events_roles?.length,
          events: data?.events_roles,
          timestamp: new Date().toISOString(),
        });
      },
    }
  );

  usePageTitle(
    events.isSuccess
      ? `/c${String.fromCharCode(92)}dashboard`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  const isLoading = events.isLoading || user.isLoading;

  useEffect(() => {
    // Ensure background color is set for the entire app
    document.body.style.backgroundColor = "#181818";
    document.documentElement.style.backgroundColor = "#181818";
  }, []);

  if (isLoading) {
    return <DashboardLoading />;
  }

  return (
    <ErrorBoundary>
      <div className="relative z-[1] min-h-screen bg-cave-black">
        <Header />
        <div className="flex flex-col relative px-6 py-8 mx-auto lg:py-0 bg-cave-black">
          <div className="my-6">
            <span className="text-gray-500 dark:text-gray-200 font-sans">
              Your Role: {user.data?.role_name || "No Role Assigned"}
            </span>
          </div>
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-1/2 flex flex-col">
              <div className="flex-1">
                {events.data && events.data.length > 0 && (
                  <NextEventCard
                    eventName={events.data[0].name}
                    venue={events.data[0].venue_name}
                    date={events.data[0].date}
                    status={events.data[0].state}
                    onClick={() => {}}
                    backgroundImage={events.data[0].event_image_url}
                    url={`/events/${events.data[0].id}`}
                  />
                )}
              </div>
              <div className="mt-6">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                  <h3 className="text-2xl font-sans font-bold dark:text-gray-200">
                    Drafts
                  </h3>
                  <button
                    onClick={() => navigate(`/events/add`)}
                    className="bg-[#888888] rounded w-full sm:w-[200px] px-4 py-2 text-cave-white font-sans font-bold capitalize mt-4 sm:mt-0"
                  >
                    CREATE DRAFT
                  </button>
                </div>
                <div className="flex flex-col gap-4 items-center mt-6 pt-8 pb-4">
                  <Carousel three>
                    {drafts.data &&
                      drafts.data.map((draft) => (
                        <EventCard
                          eventName={draft.name}
                          venue={draft.venue_name}
                          date={draft.date}
                          status={draft.state}
                          onClick={() => {}}
                          backgroundImage={draft.event_image_url}
                          key={`draft-${draft.id}`}
                          url={`/events/${draft.id}`}
                        />
                      ))}
                  </Carousel>
                  {drafts && drafts.length === 0 && (
                    <div className="dark:text-gray-200">No drafts</div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 mt-6 lg:mt-0 flex flex-col">
              <div className="flex-1">
                <BulletinBoard userId={user.data.id} />
              </div>
              <div className="mt-6">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                  <h3 className="text-2xl font-sans font-bold dark:text-gray-200">
                    Staffed Events
                  </h3>
                </div>
                <div className="flex flex-col gap-4 items-center mt-6 pt-8 pb-4">
                  {myEvents.isLoading ? (
                    <div className="dark:text-gray-200">
                      Loading staffed events...
                    </div>
                  ) : myEvents.isError ? (
                    <div className="dark:text-gray-200">
                      Error loading staffed events
                    </div>
                  ) : myEvents.data?.events_roles &&
                    myEvents.data.events_roles.length > 0 ? (
                    <Carousel three>
                      {myEvents.data.events_roles.map((event) => (
                        <EventCard
                          eventName={event.event_name}
                          date={event.event_date}
                          status={event.role_name}
                          onClick={() => {}}
                          backgroundImage="/static/images/cave_no_image_event.png"
                          key={`my-event-${event.event_id}`}
                          url={`/events/${event.event_id}`}
                        />
                      ))}
                    </Carousel>
                  ) : (
                    <div className="dark:text-gray-200">No Staffed Events</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="py-6 bg-cave-black">
            <h2 className="text-3xl font-sans font-bold text-gray-200 w-full py-2 bg-cave-black mb-4 sm:mb-[0rem] xs:mb-[3rem]">
              Recently Viewed
            </h2>
            {recent_visits.isSuccess && (
              <Carousel>
                {recent_visits.data.map((item, index) => (
                  <div key={`recent-visit-${item.id}`} className="relative">
                    <EventCard
                      eventName={item.title}
                      venue={item.item_type === 'event' ? 
                        `${item.venue_name || ''}${item.event_date ? ` • ${item.event_date}` : ''}` : 
                        item.subtitle || ''}
                      status={item.event_status || ""}
                      date=""
                      backgroundImage={
                        item.event_image_url ||
                        item.related_image_url ||
                        "/static/images/cave_no_image_event.png"
                      }
                      url={item.url}
                    />
                        <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                          item.item_type === 'event' ? 'bg-indigo-500 text-white' :
                          item.item_type === 'artist' ? 'bg-purple-500 text-white' :
                          item.item_type === 'venue' ? 'bg-teal-500 text-white' :
                          item.item_type === 'person' ? 'bg-rose-500 text-white' :
                          'bg-gray-500 text-white'
                        }`}>
                          {item.item_type.charAt(0).toUpperCase() + item.item_type.slice(1)}
                        </span>
                      {item.item_type === 'event' && item.event_status && (
                          <span className={`px-2 py-0.5 rounded text-xs font-medium ${
                            item.event_status === 'confirmed' ? 'bg-green-100 text-green-800' :
                            item.event_status === 'hold' ? 'bg-yellow-100 text-yellow-800' :
                            'bg-gray-100 text-gray-800'
                          }`}>
                            {item.event_status.toUpperCase()}
                          </span>
                      )}
                  </div>
                ))}
              </Carousel>
            )}
          </div>
          <div className="py-6 bg-cave-black">
            <h2 className="bg-cave-black text-gray-200 text-3xl font-sans font-bold w-full py-2 mb-4">
              Events
            </h2>
            <Carousel>
              {events.data &&
                events.data.map((event, index) => (
                  <EventCard
                    eventName={event.name}
                    venue={event.venue_name}
                    date={event.date}
                    status={event.state}
                    onClick={() => {}}
                    backgroundImage={
                      event.event_image_url
                        ? event.event_image_url
                        : "/static/images/cave_no_image_event.png"
                    }
                    url={`/events/${event.id}`}
                    key={`non-draft-${event.id}`}
                  />
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
