import React from "react";
import { Input } from "./Input";
import { useFormContext, useController } from "react-hook-form";

export const Datepicker = (props) => {
  const { name, label } = props;
  return (
    <div className="my-3">
      <Input {...props} type={"date"} />
    </div>
  );
};
