import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { postEventDuplicate } from "@/queries/events";
import { BeakerIcon } from "@heroicons/react/24/outline";

const DuplicateEventButton = ({ eventId }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState("");
  const [newEventId, setNewEventId] = useState(null);
  const [newEventName, setNewEventName] = useState("");

  const mutation = useMutation(() => postEventDuplicate(eventId), {
    onMutate: () => {
      setStatusMessage("Duplicating...");
    },
    onSuccess: (data) => {
      setNewEventId(data.new_event_id);
      setNewEventName(data.new_event_name);
      setStatusMessage("Success: Event duplicated successfully.");
      queryClient.invalidateQueries(["events"]); // Invalidate event-related queries if needed
    },
    onError: (error) => {
      setStatusMessage("Failure: " + error.message);
    },
  });

  return (
    <>
      <div
        onClick={() => mutation.mutate()}
        disabled={mutation.isLoading}
        className={`
        inline-flex
        items-center
        justify-center
        w-48
        h-10
        p-4
        font-bold
        text-cave-white
        uppercase
        rounded-lg
        shadow-md
        transition-colors
        duration-300
        ease-in-out
        cursor-pointer
                ${
                  mutation.isLoading
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-yellow-600 hover:bg-yellow-700"
                }`}
      >
        {mutation.isLoading ? (
          <svg
            className="animate-spin h-5 w-5 mr-2 inline-block text-cave-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
        ) : (
          <>
            <span>Copy</span> <BeakerIcon className="w-6 h-6 text-cave-white" />
            <span>&nbsp;Event</span>
          </>
        )}
      </div>
      <p
        className={`text-sm ${
          statusMessage.includes("Success") ? "text-green-600" : "text-red-600"
        }`}
      >
        {statusMessage}
      </p>
      {newEventId && (
        <div
          onClick={() => navigate(`/events/${newEventId}`)}
          className="px-4 py-2 bg-green-600 hover:bg-green-700 text-cave-white font-semibold rounded-md shadow-sm transition-colors"
        >
          Click here to view the new event: {newEventName}
        </div>
      )}
    </>
  );
};

export default DuplicateEventButton;
