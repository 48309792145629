export const stateChoices = [
  { value: "draft", label: "Draft" },
  { value: "hold", label: "Hold" },
  { value: "offer", label: "Offer" },
  { value: "canceled", label: "Canceled" },
  { value: "postponed", label: "Postponed" },
  { value: "confirmed", label: "Confirmed" },
];

export const holdChoices = [
  { value: "draft", label: "Draft" },
  { value: "hold", label: "Hold" },
];

export const companyChoices = [
  { value: "mammoth", label: "Mammoth" },
  { value: "mammoth_touring", label: "Mammoth Touring" },
  { value: "mammoth_bb", label: "Mammoth BB" },
  { value: "sabertooth", label: "Sabertooth" },
  { value: "astro", label: "Astro" },
  { value: "grizzly", label: "Grizzly" },
  { value: "other", label: "Other" },
];
