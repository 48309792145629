import React, { useState } from "react";
import SettlementVerticalTable from "./SettlementVerticalTable"; // Assuming you have it already
import SettlementFinalFigures from "./SettlementFinalFigures";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { amountDisplay } from "@/utils/money";

const DealAndFinalFigures: React.FC = ({
  className,
  hideDeal,
}: {
  className?: string;
  hideDeal?: boolean;
}) => {
  const { event, variables } = useSettlementComponent();
  if (!variables.data || !event.data) return null;
  const manifestsTotalTicketPrice = event.data.manifests.reduce(
    (acc, manifest) => acc + (manifest.price + manifest.ticket_fees),
    0
  );
  const amountOfManifests = event.data.manifests.length;
  const averageTicketPrice = manifestsTotalTicketPrice / amountOfManifests;
  const data = {
    deal: {
      title: "THE DEAL",
      data: [
        {
          key: "Guarantee",
          label: "Guarantee",
          value: amountDisplay(variables.data?.the_deal_amount ?? 0),
          isEditable: true,
        },
        {
          key: "DealType",
          label: "Deal Type",
          value: event.data?.offer_type,
          isEditable: false,
        },
        {
          key: "PromProfit",
          label: "PROM PROFIT %",
          value: variables.data.promoter_profit,
          isEditable: true,
        },
        {
          key: "ArtistShare",
          label: "ARTIST SHARE",
          value: variables.data.walkout_percentage,
          isEditable: true,
        },
        {
          key: "Versus",
          label: "VERSUS %",
          value: 100 - variables.data.walkout_percentage,
          isEditable: true,
        },
        {
          key: "GuaranteePaidBy",
          label: "GUARANTEE PAID BY:",
          value: "MAMMOTH",
          isEditable: false,
        },
        {
          key: "CoHeadlinerSplit",
          label: "CO-HEADLINER SPLIT (if applicable)",
          //   TODO : ask about this value
          value: "100%",
          isEditable: false,
        },
      ],
    },
    ticketInfo: {
      title: "Ticket Info",
      data: [
        {
          key: "PercentSold",
          label: "% SOLD",
          value:
            (
              (event.data.tickets_sold / event.data.tickets_total) *
              100
            ).toFixed(2) + "%",
          isEditable: false,
        },
        {
          key: "AvgTxtPrice",
          label: "AVERAGE TXT PRICE",
          value: amountDisplay(averageTicketPrice),
          isEditable: false,
        },
        {
          key: "DropCount",
          label: "DROP COUNT",
          value: event.data.drop_count,
          isEditable: false,
        },
      ],
    },
  };

  const handleBlur = (
    sectionKey: keyof typeof data,
    key: string,
    newValue: string | number
  ) => {
    const updatedData = { ...data };
    updatedData[sectionKey].data = updatedData[sectionKey].data.map((row) =>
      row.key === key ? { ...row, value: newValue } : row
    );
    setData(updatedData);
  };

  return (
    <div className={className}>
      <div className="grid grid-cols-3 my-8 gap-4">
        {/* THE DEAL Section */}
        {hideDeal ? (
          <div></div>
        ) : (
          <SettlementVerticalTable
            title={data.deal.title}
            data={data.deal.data}
            onBlur={(key, newValue) => handleBlur("deal", key, newValue)}
          />
        )}
        {/* Ticket Info Section */}
        <SettlementVerticalTable
          title={data.ticketInfo.title}
          data={data.ticketInfo.data}
          onBlur={(key, newValue) => handleBlur("ticketInfo", key, newValue)}
        />
        <SettlementFinalFigures />
        {/* Final Figures Section */}
        {/* Final Figures are already implemented */}
      </div>
    </div>
  );
};

export default DealAndFinalFigures;
