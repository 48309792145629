import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Alert } from "flowbite-react";
import { getActives } from "@/queries/auth.js";

const getActiveUsersSettings = {
  refetchInterval: 60000, // Refetch every minute
};

export const ActiveUsersDashboard = () => {
  const activeUsers = useQuery(
    ["activeUsers"],
    getActives,
    getActiveUsersSettings
  );

  if (activeUsers.isLoading)
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (activeUsers.error)
    return (
      <Alert color="failure">
        An error occurred: {activeUsers.error.message}
      </Alert>
    );

  if (!activeUsers.data || activeUsers.data.length === 0) {
    return <Alert color="info">No active users at the moment.</Alert>;
  }

  return (
    <div className="w-full bg-cave-white dark:bg-gray-800 shadow-md">
      <div className="px-4 sm:px-6 lg:px-8 py-6">
        <h1 className="text-2xl font-bold mb-6 text-gray-800 dark:text-cave-white">
          Active Users Dashboard
        </h1>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Last Activity
                </th>
                <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Current Page
                </th>
                <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Last Page
                </th>
              </tr>
            </thead>
            <tbody className="bg-cave-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
              {activeUsers.data.map((user, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-cave-white">
                    {user.email || "N/A"}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    {user.last_activity
                      ? new Date(user.last_activity).toLocaleString()
                      : "N/A"}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    {user.current_page || "N/A"}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    {user.last_page || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ActiveUsersDashboard;
