import { Expense } from "@/types/expense";

export const getExpensesTotal = (expenses: Expense[]) => {
  return expenses.reduce((acc, curr) => {
    return (
      acc +
      (curr.amount || 0) +
      (curr.mammoth || 0) +
      (curr.venue || 0) +
      (curr.artist || 0) +
      (curr.partner_1 || 0) +
      (curr.partner_2 || 0)
    );
  }, 0);
};

export const bundleAllExpenses = (expenses: Expense[]) => {
  return expenses.reduce(
    (acc, curr) => {
      acc.mammoth += curr.mammoth || 0;
      acc.venue += curr.venue || 0;
      acc.artist += curr.artist || 0;
      acc.partner_1 += curr.partner_1 || 0;
      acc.partner_2 += curr.partner_2 || 0;
      acc.totals +=
        (curr.mammoth || 0) +
        (curr.venue || 0) +
        (curr.artist || 0) +
        (curr.partner_1 || 0) +
        (curr.partner_2 || 0);
      acc.offer += curr.amount || 0;
      return acc;
    },
    {
      mammoth: 0,
      venue: 0,
      artist: 0,
      partner_1: 0,
      partner_2: 0,
      totals: 0,
      offer: 0,
      difference: 0,
    }
  );
};

export function calculateRebateValue(inputValue: number): number {
  if (inputValue <= 0) {
    // If inputValue is less than or equal to 0, return an empty string
    return 0;
  } else if (inputValue > 0 && inputValue <= 10.99) {
    // Between 0 (exclusive) and 10.99 (inclusive)
    return 2.55;
  } else if (inputValue > 10.99 && inputValue <= 19.99) {
    // Between 10.99 (exclusive) and 19.99 (inclusive)
    return 3.2;
  } else if (inputValue > 19.99 && inputValue <= 29.99) {
    // Between 19.99 (exclusive) and 29.99 (inclusive)
    return 3.9;
  } else if (inputValue > 29.99 && inputValue <= 39.99) {
    // Between 29.99 (exclusive) and 39.99 (inclusive)
    return 6.2;
  } else if (inputValue > 39.99 && inputValue <= 49.99) {
    // Between 39.99 (exclusive) and 49.99 (inclusive)
    return 7.2;
  } else if (inputValue > 49.99 && inputValue <= 89.99) {
    // Between 49.99 (exclusive) and 89.99 (inclusive)
    return 9.45;
  } else if (inputValue > 89.99) {
    // Greater than 89.99
    return 13;
  } else {
    // For any other unexpected case
    return 0;
  }
}

type Procedure = (...args: any[]) => void;

export function debounce<F extends Procedure>(func: F, waitFor: number) {
  let timeout: ReturnType<typeof setTimeout>;

  const debounced = (...args: Parameters<F>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}
