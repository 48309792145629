import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  StarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EnvelopeIcon,
  PhoneIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";

import { PPeopleIcon } from "@/components/People/PeopleIcon";

import Button from "@/components/Button";
import MammothIcon from "@/components/MammothIcon";

import styles from "./People.module.css";

const PeopleRow = ({ person, toggleMainPOC, removePerson, editable }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="flex relative flex-row w-full bg-cave-blue2 dark:bg-cave-blue3 border border-gray-200 text-cave-white dark:text-cave-white">
        {/* Accordion Header */}
        <div
          id={`accordion-collapse-heading-${person.id}`}
          onClick={toggleOpen}
          className="flex-grow cursor-pointer grid w-full p-2 font-medium text-left border border-[#383f4d] focus:ring-2 focus:ring-sky-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-cave-white xl:grid-cols-6"
          aria-expanded={open}
          aria-controls={`accordion-collapse-body-${person.id}`}
        >
          {/* Chevron Icon */}
          {open ? (
            <ChevronDownIcon className="text-cave-white h-6 absolute top-2 right-4" />
          ) : (
            <ChevronUpIcon className="text-cave-white h-6 absolute top-2 right-4" />
          )}

          {/* Main Contact Star */}
          <div>
            {person.main_contact && (
              <StarIcon className="w-6 h-6 text-yellow-300" />
            )}
          </div>

          {/* Mammoth Icon */}
          <div>
            {person.is_mammoth && (
              <MammothIcon className="w-8 h-8 text-cave-black" />
            )}
          </div>

          {/* People Icon with Link */}
          <div>
            <a
              target="_blank"
              href={`/people/${person.people_id}`}
              className="text-indigo-300 underline hover:text-blue-600 flex relative"
              onClick={(e) => e.stopPropagation()} // Prevent accordion toggle when clicking the link
            >
              <PPeopleIcon person={person} />
            </a>
          </div>

          {/* Role Box */}
          <div className="flex flex-row relative items-center justify-between w-3/12 gap-4 whitespace-nowrap">
            {person.role_name}
          </div>

          {/* Names and Email */}
          <div className="flex flex-row relative items-center justify-between w-3/12 gap-4 whitespace-nowrap">
            <div>
              {person.first_name} {person.last_name}
            </div>

            {person.email ? (
              <a
                href={`mailto:${person.email}`}
                className="text-blue-400 underline hover:text-blue-600"
                onClick={(e) => e.stopPropagation()} // Prevent accordion toggle when clicking the email
              >
                <EnvelopeIcon className="w-6 h-6" />
              </a>
            ) : (
              <span></span>
            )}
          </div>

          {/* Phone */}
          {person.phone ? (
            <a
              href={`tel:${person.phone}`}
              className="text-blue-400 underline hover:text-blue-600 flex relative"
              onClick={(e) => e.stopPropagation()} // Prevent accordion toggle when clicking the phone
            >
              <PhoneIcon className="w-6 h-6" />
            </a>
          ) : (
            <span></span>
          )}
        </div>

        {/* Delete Button */}
        {editable && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation(); // Prevent accordion toggle
              removePerson(person.id); // Ensure this is the EventPeople ID
            }}
            className="text-red-500 hover:text-red-700 ml-2 p-2 mr-32"
            aria-label={`Remove ${person.first_name} ${person.last_name}`}
          >
            <TrashIcon className="w-6 h-6" />
          </button>
        )}
      </div>

      {/* Accordion Content */}
      {open && (
        <div
          id={`accordion-collapse-body-${person.id}`}
          aria-labelledby={`accordion-collapse-heading-${person.id}`}
          className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-[#181818]"
        >
          <div className="flex">
            {person.role_name && (
              <p className="mb-2 text-cave-black dark:text-cave-white">
                <strong>Role:</strong> {person.role_name}{" "}
                {person.role_description}
              </p>
            )}
          </div>

          {person.email && (
            <p className="mb-2 text-cave-black dark:text-cave-white">
              <strong>Email:</strong> {person.email}
            </p>
          )}
          {person.phone && (
            <p className="mb-2 text-cave-black dark:text-cave-white">
              <strong>Phone:</strong> {person.phone}
            </p>
          )}
          {person.company_name && (
            <p className="mb-2 text-cave-black dark:text-cave-white">
              <strong>Cammoth:</strong> {person.company_name}
            </p>
          )}
          {person.role_description && (
            <p className="mb-2 text-cave-black dark:text-cave-white">
              <strong>Role Description:</strong> {person.role_description}
            </p>
          )}
          {person.description && (
            <p className="mb-2 text-cave-black dark:text-cave-white">
              <strong>Description:</strong> {person.description}
            </p>
          )}
          {person.website_url && (
            <p className="mb-2 text-cave-black dark:text-cave-white">
              <strong>Website:</strong>{" "}
              <a
                href={person.website_url}
                target="_blank"
                rel="noreferrer"
                className="text-blue-400 underline hover:text-blue-600"
              >
                {person.website_url}
              </a>
            </p>
          )}
          <div className="my-6 w-full">
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation(); // Prevent accordion toggle
                toggleMainPOC.mutate(person.id);
              }}
              className="mb-4 float-right inline-flex items-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-cave-white shadow-sm hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {!person.main_contact ? (
                <span>Make Main Point of Contact</span>
              ) : (
                <span>Remove Main Point of Contact</span>
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const People = ({
  people,
  toggleMainPOC,
  removePerson,
  itemType,
  addURL,
  editable,
}) => {
  const navigate = useNavigate();

  if (people.isLoading) {
    return (
      <div className="text-center text-cave-black dark:text-cave-white">
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-cave-white">People</h3>
        <span>
          {editable && (
            <Button onClick={() => navigate(addURL)}>Add Person</Button>
          )}
        </span>
      </div>
      <div id="accordion-collapse">
        {people.data.map((person) => (
          <PeopleRow
            key={person.id}
            person={person}
            toggleMainPOC={toggleMainPOC}
            removePerson={removePerson}
            editable={editable}
          />
        ))}
        {people.data.length === 0 && (
          <div className="w-full p-5 font-medium text-left text-cave-black text-center">
            <em>No people defined for this {itemType}</em>
          </div>
        )}
      </div>
    </>
  );
};

export default People;
