import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { StarIcon } from "@heroicons/react/24/solid";

import {
  EnvelopeIcon,
  PhoneIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import Button from "@/components/Button";
import MammothIcon from "@/components/MammothIcon";

const WireInfoRow = (props) => {
  const { wireinfos, toggleMainPOC } = props;
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  let showAddress = false;
  if (
    wireinfos.address_line_1 ||
    wireinfos.city ||
    wireinfos.state ||
    wireinfos.zipcode
  ) {
    showAddress = true;
  }

  return (
    <>
      <h2
        id="accordion-collapse-heading-{wireinfos.id}"
        onClick={() => toggleOpen()}
      >
        <button
          type="button"
          className="grid grid-cols-7 w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-2 focus:ring-sky-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
        >
          <span>{wireinfos.description}</span>
          {wireinfos.bank_name ? (
            <span className="text-blue-400 underline hover:text-blue-600"></span>
          ) : (
            <span></span>
          )}
          {wireinfos.bank_address ? (
            <span className="text-blue-400 underline hover:text-blue-600"></span>
          ) : (
            <span></span>
          )}
          {wireinfos.account_number ? (
            <span className="text-blue-400 underline hover:text-blue-600">
              <BanknotesIcon className="w-6 h-6" />
            </span>
          ) : (
            <span></span>
          )}
          {wireinfos.routing_number ? (
            <span className="text-blue-400 underline hover:text-blue-600"></span>
          ) : (
            <span></span>
          )}
          {wireinfos.swift ? (
            <span className="text-blue-400 underline hover:text-blue-600"></span>
          ) : (
            <span></span>
          )}
        </button>
      </h2>
      {open && (
        <div
          id="accordion-collapse-body-1"
          aria-labelledby="accordion-collapse-heading-{wireinfos.id}"
        >
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-[#181818]">
            {wireinfos.role_name && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>{wireinfos.description}</strong>
              </p>
            )}
            {wireinfos.bank_name && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Bank Name</strong> {wireinfos.bank_name}
              </p>
            )}
            {wireinfos.bank_address && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Bank Address</strong> {wireinfos.bank_address}
              </p>
            )}
            {wireinfos.account_number && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Account Number</strong> {wireinfos.account_number}
              </p>
            )}
            {wireinfos.routing_number && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Routing Number</strong> {wireinfos.routing_number}
              </p>
            )}
            {wireinfos.swift && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>SWIFT</strong> {wireinfos.swift}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const WireInfo = (props) => {
  const { wireinfos, toggleMainPOC, itemType, addURL, editable } = props;
  const navigate = useNavigate();

  if (wireinfos.isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">WireInfo</h3>
        <span>
          {editable && (
            <Button onClick={() => navigate(addURL)}>Add WireInfo</Button>
          )}
        </span>
      </div>
      <div id="accordion-collapse">
        {wireinfos.data.map((wireinfos) => (
          <WireInfoRow
            key={wireinfos.id}
            wireinfos={wireinfos}
            toggleMainPOC={toggleMainPOC}
          />
        ))}
        {wireinfos.data.length === 0 && (
          <div className="w-full p-5 font-medium text-left text-gray-500 border border-gray-200 text-center">
            <em>No wireinfos defined for this {itemType}</em>
          </div>
        )}
      </div>
    </>
  );
};

export default WireInfo;
