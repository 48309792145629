import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from "./base";

export const getMessageSettings = {
  cacheTime: 30 * 60 * 1000, // 30 minutes
  staleTime: 5 * 60 * 1000,  // 5 minutes - reduced to ensure fresher data
  refetchOnWindowFocus: true,
  refetchOnMount: true,
  refetchInterval: 5 * 60 * 1000 // Refetch every 5 minutes
};

export const getMessages = async () => {
  const url = `/api/bull/messages/`;
  const response = await getApiRequest(url);
  console.log('Fetched messages:', response); // Add logging
  return response;
};

export const getMessage = async (id) => {
  const url = `/api/bull/messages/${id}/`;
  return await getApiRequest(url);
};

export const createMessage = async (data) => {
  const url = `/api/bull/messages/`;
  return await postApiRequest(url, data);
};

export const updateMessage = async (id, data) => {
  const url = `/api/bull/messages/${id}/`;
  return await putApiRequest(url, data);
};

export const deleteMessage = async (id) => {
  const url = `/api/bull/messages/${id}/`;
  return await deleteApiRequest(url);
};

export const removeMessage = async (messageId) => {
  const url = `/api/bull/messages/${messageId}/remove/`;
  return await postApiRequest(url, {});
};

export const dismissMessage = async (messageId) => {
  const url = `/api/bull/messages/${messageId}/dismiss/`;
  return await postApiRequest(url, {});
};

export const dismissEvent = async (payload) => {
  if (!payload?.event_ids?.length) {
    throw new Error("No event IDs provided");
  }

  const url = `/api/bull/messages/event/dismiss/`;
  const dismissPayload = {
    event_ids: payload.event_ids.map(id => {
      const parsed = parseInt(id, 10);
      if (isNaN(parsed)) {
        throw new Error(`Invalid event ID: ${id}`);
      }
      return parsed;
    })
  };

  try {
    const response = await postApiRequest(url, dismissPayload);
    if (!response.success) {
      throw new Error(response.error || "Failed to dismiss events");
    }
    return response;
  } catch (error) {
    console.error('Dismiss event error:', error);
    throw error;
  }
};
