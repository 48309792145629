import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FormBuilder,
  Input,
  TextInput,
  Datepicker,
  FormSelect,
  SaveCancel,
} from "@/components/Form";
import { getVendor, createVendorFile } from "@/queries/vendors";
import { BreadCrumbs } from "@/components/BreadCrumbs";

export const VendorFileAdd = () => {
  const { id } = useParams(); // Vendor ID
  const vendor = useQuery(["vendor-detail", id], () => getVendor(id));
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating file");
      console.dir(data);
      return createVendorFile(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["vendor", "files", id]);
      navigate(`/vendors/${id}`);
    },
    onSettled: () => setIsSubmitting(false),
  });

  const addSchema = yup.object().shape({
    name: yup.string().required(),
    file: yup.string().required(),
    description: yup.string(),
  });

  const handleSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("file", file);
    formData.append("description", data.description);
    setIsSubmitting(true);
    mutation.mutate(formData);
  };

  // Wait for Event to load
  if (vendor.isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[
            { text: "Vendors", url: "/vendors" },
            { text: "Add Vendor File" },
          ]}
        />

        <h3 className="text-xl font-bold dark:text-cave-white">
          Add New File for {vendor.data.name}
        </h3>
        <div className="py-6 min-w-full">
          <FormBuilder
            mode="onChange"
            onSubmit={(data) => handleSubmit(data)}
            schema={addSchema}
          >
            <Input
              name="name"
              label="Name"
              autoFocus={true}
              autoComplete="one-time-code"
            />
            <Input
              name="file"
              label="File"
              type="file"
              onChange={(event) => {
                setFile(event.target.files[0]);
              }}
            />
            <TextInput
              name="description"
              label="Description"
              placeholder="Optional description"
            />
            <SaveCancel
              label="Add File"
              cancelURL={`/vendors/${id}`}
              isSubmitting={isSubmitting}
              submittingMessage="Uploading file..."
            />
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
