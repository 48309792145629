import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Manifest } from "@/types/event";
import { amountDisplay } from "@/utils/money";
import { Column, Table } from "@/components/AppTable2";
import { useEventManifests } from "@/hooks/useEventManifests";
import { toast } from "react-toastify";

const EventManifestTable: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<Manifest[]>([]);

  const rowClassName = "text-sm bg-gray-100 h-8";
  const inputClassName =
    "hover:bg-orange-400 duration-200 bg-transparent p-0.5 w-full text-sm h-8";
  const headerCustomClasses = "bg-gray-300 text-sm";

  const params = useParams<{ id: string }>();
  const eventId = params.id as string;

  const {
    manifests,
    isLoading,
    isError,
    error,
    addManifest,
    updateManifest,
    deleteManifest,
  } = useEventManifests(eventId);

  useEffect(() => {
    if (manifests) {
      const preparedRows = manifests
        .map((manifest) => ({
          ...manifest,
          gross_price: amountDisplay(
            manifest.price + (manifest.ticket_fees || 0)
          ),
          potential: amountDisplay(
            (manifest.price + (manifest.ticket_fees || 0)) * manifest.qty
          ),
        }))
        .sort((a, b) => a.sort_order - b.sort_order);
      setRows(preparedRows);
    }
  }, [manifests]);

  const handleAddManifest = async (newRow: Record<string, any>) => {
    if (!newRow.name.trim()) {
      toast.error("Name is required.");
      return;
    }
    try {
      setLoading(true);
      await addManifest({
        name: newRow.name,
        qty: Number(newRow.qty),
        comps: Number(newRow.comps),
        price: Number(newRow.price),
        ticket_fees: Number(newRow.ticket_fees),
        sort_order: rows.length - 1 > 0 ? rows.length - 1 : 0,
      });
    } catch (error: any) {
      console.error("Error adding manifest:", error);
      toast.error("Failed to add manifest.");
    } finally {
      setLoading(false);
    }
  };

  const handleCellChange = async (
    rowIndex: number,
    accessor: string,
    value: any,
    row: Manifest
  ) => {
    try {
      setLoading(true);
      await updateManifest({
        id: row.id,
        updates: {
          [accessor]:
            accessor === "qty" || accessor === "comps" ? Number(value) : value,
        },
      });
    } catch (error: any) {
      console.error("Error updating manifest:", error);
      toast.error("Failed to update manifest.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (_: any, rowData: Manifest) => {
    const manifestId = rowData.id;
    if (!manifestId) {
      toast.error("Invalid manifest ID.");
      return;
    }
    try {
      setLoading(true);
      await deleteManifest(manifestId.toString());
    } catch (error: any) {
      console.error("Error deleting manifest:", error);
      toast.error("Failed to delete manifest.");
    } finally {
      setLoading(false);
    }
  };

  const handleReorder = async (reorderedRows: Record<string, any>[]) => {
    setLoading(true);
    const mapped = reorderedRows.map((row, i) => ({
      ...row,
      sort_order: i,
    }));
    setRows(mapped);
    try {
      const updatePromises = mapped.map((row) => {
        return updateManifest({
          id: row.id,
          updates: {
            sort_order: row.sort_order,
          },
        });
      });
      await Promise.all(updatePromises);
      toast.success("Manifests reordered successfully.");
    } catch (error: any) {
      console.error("Error reordering manifests:", error);
      toast.error("Failed to reorder manifests.");
    } finally {
      setLoading(false);
    }
  };

  const columns: Column[] = [
    {
      title: "TICKETING",
      accessor: "name",
      editable: true,
      format: "input",
      textAlign: "left",
    },
    {
      title: "CAPACITY",
      accessor: "qty",
      editable: true,
      format: "input",
      textAlign: "center",
    },
    {
      title: "COMPS",
      accessor: "comps",
      editable: true,
      format: "input",
      textAlign: "center",
    },
    {
      title: "NET PRICE",
      accessor: "price",
      editable: true,
      format: "money",
      textAlign: "left",
    },
    {
      title: "TICKET FEES",
      accessor: "ticket_fees",
      editable: true,
      format: "money",
      textAlign: "left",
    },
    {
      title: "GROSS PRICE",
      accessor: "gross_price",
      editable: false,
      format: "money",
      textAlign: "left",
    },
    {
      title: "POTENTIAL",
      accessor: "potential",
      editable: false,
      format: "money",
      textAlign: "left",
    },
  ];

  if (isLoading && rows.length === 0) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error loading manifests: {error?.message}</div>;
  }

  return (
    <div>
      <Table
        columns={columns}
        data={rows}
        onCellBlur={handleCellChange}
        orderable={true}
        reorderablePosition="first"
        onReorder={handleReorder}
        headerClassName={headerCustomClasses}
        rowClassName={rowClassName}
        rowContainerClass="border-b"
        inputClassName={inputClassName}
        unstyled={true}
        enableAddRow={true}
        onAddRow={handleAddManifest}
        showNoDataMessage={true}
        onDelete={handleDelete as any}
        addRowButtonTitle="Add Manifest"
        // loading={loading || isLoading}
      />
    </div>
  );
};

export default EventManifestTable;
