import { useState } from "react";
import classNames from "classnames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import styles from "./ItemTable.module.css";
import { Form, FormSelect, FormBuilder } from "@/components/Form";

export const ItemTable = ({
  rows = [],
  header,
  info,
  columns = [],
  color = "gray",
  highlightedColumn,
  onUpdate,
  tableId,
  showTable,
  columnWidths = ["200px", "auto"],
  showChevrons = true,
  form,
  onValuesChange,
}) => {
  const [editableRows, setEditableRows] = useState(rows);

  const thClasses = classNames(
    "cursor-pointer text-center font-bold text-cave-white text-md font-inter",
    { "bg-[#4E6B85] hover:bg-[#3A4F61]": color === "gray" },
    { "bg-red-500 hover:bg-red-700": color === "red" }
  );

  const handleBlur = (accessor, e, row) => {
    onUpdate && onUpdate(accessor, e.target.textContent);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  };

  const handleHeaderChange = (event, rowIndex) => {
    const newRows = [...editableRows];
    newRows[rowIndex].header = event.target.value;
    setEditableRows(newRows);
  };

  return (
    <table
      className="min-w-full rounded-lg overflow-hidden table-fixed"
      style={{ tableLayout: "fixed", width: "100%" }}
    >
      <colgroup>
        {columnWidths.map((width, idx) => (
          <col key={idx} style={{ width }} />
        ))}
      </colgroup>

      <thead>
        {header && (
          <tr className="h-10">
            <th className={thClasses} colSpan={columns.length || 1}>
              <div className="flex justify-center items-center">
                <span>{header.value}</span>
                {showChevrons &&
                  (showTable ? (
                    <ChevronDownIcon className={styles.chevronAccordionDown} />
                  ) : (
                    <ChevronUpIcon className={styles.chevronAccordionUp} />
                  ))}
              </div>
            </th>
          </tr>
        )}
        {showTable && columns.length > 0 && (
          <tr className="bg-[#f3836f]">
            {columns.map((col, idx) => (
              <th
                key={idx}
                className={`border-r border-black text-center dark:text-cave-white uppercase ${styles.heavyFont}`}
              >
                {col.header}
              </th>
            ))}
          </tr>
        )}
      </thead>
      {showTable && (
        <tbody className="w-full">
          {info && (
            <tr className="w-full h-[240px]">
              <td
                colSpan={columns.length || 1}
                className="bg-cave-white border-b border-[black] text-center text-sm font-inter whitespace-pre-line fton-[900] text-[1.2rem] leading-[1.5]"
              >
                {info}
              </td>
            </tr>
          )}
          {editableRows.length > 0 ? (
            editableRows.map((row, index) => (
              <tr
                className={`${
                  index % 2 !== 0 ? "bg-gray-100" : "bg-cave-white"
                } h-12 w-48`}
                key={index}
              >
                {/* font size for table body - just added text mobile size*/}
                {columns.map((col, colIdx) => (
                  <td
                    key={colIdx}
                    className={`border-r border-black p-1 px-3 sm:text-sm text-[.688rem]  font-inter ${
                      colIdx === 0
                        ? "text-black font-[900]"
                        : "text-black font-[400]"
                    }`}
                    style={{
                      whiteSpace: "wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {col.accessor === "header" ? (
                      row.isEditableHeader ? (
                        <input
                          type="text"
                          value={row.header}
                          onChange={(e) => handleHeaderChange(e, index)}
                          className="bg-transparent border-none p-0 m-0 text-center text-black font-[900]"
                          style={{
                            width: "100%",
                            outline: "none",
                            textAlign: "left",
                            textTransform: "uppercase",
                          }}
                        />
                      ) : (
                        row[col.accessor]
                      )
                    ) : (
                      <Form.Item
                        name={[row.header, col.accessor]}
                        defaultValue={row[col.accessor]}
                      >
                        <input
                          className="w-full bg-transparent border-none focus:outline-none"
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (
                              form &&
                              typeof form.setFieldsValue === "function"
                            ) {
                              form.setFieldsValue({
                                [row.header]: {
                                  [col.accessor]: newValue,
                                },
                              });
                            }
                            if (onValuesChange) {
                              onValuesChange({
                                [row.header]: {
                                  [col.accessor]: newValue,
                                },
                              });
                            }
                          }}
                        />
                      </Form.Item>
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length || 1}
                className="bg-cave-white border-b border-black text-center text-sm font-normal whitespace-pre-line h-12 w-48"
              >
                NO DATA
              </td>
            </tr>
          )}
        </tbody>
      )}
    </table>
  );
};
