export const validateEventAdvanceForm = (formData) => {
  const errors = [];

  // Validate time fields
  const timeFields = ['venueAccess', 'breakfastTime', 'localLoadIn', 'tourLoadIn', 
                     'lunchTime', 'soundCheck', 'securityMeeting', 'dinnerTime',
                     'doorsTime', 'setOneTime', 'intermissionTime', 'setTwoTime',
                     'callbackTime', 'curfewTime'];
  
  const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;
  
  timeFields.forEach(field => {
    if (formData[field] && !timeRegex.test(formData[field])) {
      errors.push(`Invalid time format for ${field}. Use HH:MM or HH:MM:SS`);
    }
  });

  // Validate phone numbers
  const phoneFields = ['gm_phone', 'marketing_phone', 'box_office_phone', 'production_phone',
                      'catering_phone', 'tour_mgr_phone', 'prod_mgr_phone', 'tour_acct_phone'];
  
  const phoneRegex = /^\+?[\d\s-]{10,}$/;
  
  phoneFields.forEach(field => {
    if (formData[field] && !phoneRegex.test(formData[field])) {
      errors.push(`Invalid phone number format for ${field}`);
    }
  });

  // Validate email addresses
  const emailFields = ['gm_email', 'marketing_email', 'box_office_email', 'production_email',
                      'catering_email', 'tour_mgr_email', 'prod_mgr_email', 'tour_acct_email'];
  
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  emailFields.forEach(field => {
    if (formData[field] && !emailRegex.test(formData[field])) {
      errors.push(`Invalid email format for ${field}`);
    }
  });

  // Validate numeric fields
  const numericFields = ['bath_towels', 'hand_towels', 'truck_dock_count'];
  
  numericFields.forEach(field => {
    if (formData[field] && isNaN(Number(formData[field]))) {
      errors.push(`${field} must be a number`);
    }
  });

  // Validate decimal fields
  const decimalFields = ['catering_estimate', 'hands_estimate', 'production_estimate'];
  
  decimalFields.forEach(field => {
    if (formData[field] && (isNaN(Number(formData[field])) || Number(formData[field]) < 0)) {
      errors.push(`${field} must be a positive number`);
    }
  });

  return errors;
};
