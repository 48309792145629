import React, { useEffect, useState, useMemo } from "react";
import { Header } from "@/components/Header";
import { TwitterHandle } from "@/components/TwitterHandle";
import { LoadingPulse } from "@/components/shared/LoadingPulse";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useUser, usePerms } from "@/components/Auth";
import { getPerson, removePersonFromEvent } from "@/queries/people";
import { getBuyerEvents } from "@/queries/events";
import { useRecordVisit } from "@/queries/visit";
import { useParams, useNavigate } from "react-router-dom";
import { PersonImageDisplay } from "@/components/People/PersonImageDisplay";
import ImageDisplay from "@/components/Images/ImageDisplay";
import FileDisplay from "@/components/Files/FileDisplay";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { usePageTitle } from "@/utils/pagetitle";
//import { EventTable } from "@/components/EventDisplay/EventTable";
//
import { format, isPast as isDatePast, parseISO } from "date-fns";

import { TrashIcon } from "@heroicons/react/24/outline";

import {
  getPersonEventsRoles,
  getPeopleFiles,
  getPeopleFileSettings,
  deletePeopleFile,
  getPeopleSettings,
  createPeopleImages,
  deletePeopleImage,
  getPeopleImages,
} from "@/queries/people";

const EventTable = ({
  events,
  defaultSortColumn = "event_date",
  defaultSortDirection = "asc",
  isPast = false,
  showDelete = false,
  showRole = false,
  onDelete,
}) => {
  const { perms } = usePerms();
  const [sortColumn, setSortColumn] = useState(defaultSortColumn);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const [filter, setFilter] = useState("");

  const isDatePast = (date) => {
    const today = new Date();
    return new Date(date).setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
  };

  const sortedAndFilteredEvents = useMemo(() => {
    let filteredEvents = events.filter((event) => {
      const eventDate = parseISO(event.event_date);
      // Filter based on past or future events
      return isPast ? isDatePast(eventDate) : !isDatePast(eventDate);
    });

    // Filter by search term
    filteredEvents = filteredEvents.filter((event) =>
      Object.values(event).some((value) =>
        value.toString().toLowerCase().includes(filter.toLowerCase())
      )
    );

    // Sort by the specified column and direction
    if (sortColumn) {
      filteredEvents.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (sortColumn === "event_date") {
          // Ensure proper date sorting for the 'event_date' column
          const aDate = new Date(aValue);
          const bDate = new Date(bValue);
          return sortDirection === "asc" ? aDate - bDate : bDate - aDate;
        }

        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
    }

    return filteredEvents;
  }, [events, sortColumn, sortDirection, filter, isPast]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  if (!events || events.length === 0) {
    return (
      <p className="text-center text-gray-500 dark:text-gray-400">
        No events found.
      </p>
    );
  }

  return (
    <div className="overflow-x-auto">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Filter events..."
          className="w-full p-2 border rounded"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <table className="w-full table-auto divide-y divide-cave-white dark:divide-gray-700">
        <thead className="bg-gray-50 dark:bg-gray-800">
          <tr>
            <th
              className="w-1/5 min-w-[120px] px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort("event_date")}
            >
              Date{" "}
              {sortColumn === "event_date" &&
                (sortDirection === "asc" ? "▲" : "▼")}
            </th>
            <th
              className="w-2/5 min-w-[200px] px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort("event_name")}
            >
              Event{" "}
              {sortColumn === "event_name" &&
                (sortDirection === "asc" ? "▲" : "▼")}
            </th>
            <th
              className="w-1/5 min-w-[150px] px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort("venue_name")}
            >
              Venue{" "}
              {sortColumn === "venue_name" &&
                (sortDirection === "asc" ? "▲" : "▼")}
            </th>
            {showRole && (
              <th
                className="hidden sm:table-cell w-1/5 px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort("role_name")}
              >
                Role{" "}
                {sortColumn === "role_name" &&
                  (sortDirection === "asc" ? "▲" : "▼")}
              </th>
            )}
          </tr>
        </thead>
        <tbody className="bg-cave-white divide-y divide-cave-white dark:bg-gray-900 dark:divide-gray-700">
          {sortedAndFilteredEvents.map((event) => (
            <tr key={event.event_id}>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                {format(parseISO(event.event_date), "MMM d, yyyy")}
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-cave-white">
                <a
                  className="hover:underline block truncate"
                  target="_blank"
                  href={`/events/${event.event_id}`}
                  title={event.event_name}
                >
                  {event.event_name}
                </a>
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                {event.venue_name || ""}
              </td>
              {showRole && (
                <td className="hidden sm:table-cell px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                  <div className="flex justify-between items-center">
                    <span>{event.role_name || ""}</span>
                    {showDelete && perms.data.edit_staff && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (
                            window.confirm(
                              "Are you sure you want to remove this person from the event?"
                            )
                          ) {
                            onDelete(event.event_people_id);
                          }
                        }}
                        className="text-red-500 hover:text-red-700 p-1"
                        title="Remove person from event"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const PersonEventsSection = ({ id, isPast }) => {
  const { perms } = usePerms();
  const queryClient = useQueryClient();
  const {
    data: staffData,
    isLoading: isStaffLoading,
    isError: isStaffError,
    error: staffError,
  } = useQuery(["person", "events", id, isPast], () =>
    getPersonEventsRoles(id, !isPast)
  );

  const {
    data: buyerData,
    isLoading: isBuyerLoading,
    isError: isBuyerError,
    error: buyerError,
  } = useQuery(["buyer", "events", id, isPast], () =>
    getBuyerEvents(id, !isPast)
  );

  const filteredStaffEvents = useMemo(() => {
    if (!staffData || !staffData.events_roles) return [];
    return staffData.events_roles.map((item) => ({
      event_id: item.event_id,
      event_name: item.event_name,
      event_date: item.event_date,
      role_name: item.role_name,
      role_id: item.role_id,
      venue_name: item.venue_name || item.venue_name || "",
      event_people_id: item.event_id, // Use the event_id for removal
    }));
  }, [staffData]);

  const filteredBuyerEvents = useMemo(() => {
    if (!buyerData || !buyerData.events) return [];
    return buyerData.events.map((item) => ({
      event_id: item.event_id,
      event_name: item.event_name,
      event_date: item.date,
      venue_name: item.venue_name || "",
    }));
  }, [buyerData]);

  if (isStaffLoading || isBuyerLoading)
    return <div className="text-center">Loading...</div>;
  if (isStaffError || isBuyerError)
    return (
      <div className="text-center text-red-500">
        Error loading events: {staffError?.message || buyerError?.message}
      </div>
    );

  return (
    <section className="border-t border-cave-white dark:border-gray-700">
      <div className="py-6">
        <h3 className="text-xl font-semibold mb-6 dark:text-cave-white">
          {isPast ? "Past Events" : "Future Events"}:
        </h3>

        {filteredStaffEvents.length > 0 && (
          <>
            <h4 className="text-lg font-semibold mb-4 dark:text-cave-white">
              Staffed:
            </h4>
            <EventTable
              events={filteredStaffEvents}
              defaultSortColumn="event_date"
              defaultSortDirection={isPast ? "desc" : "asc"}
              isPast={isPast}
              showDelete={true}
              showRole={true}
              onDelete={async (eventId) => {
                try {
                  await removePersonFromEvent(eventId, id);
                  // Invalidate all related queries to ensure complete refresh
                  queryClient.invalidateQueries(["person", "events", id, true]);
                  queryClient.invalidateQueries(["person", "events", id, false]);
                  queryClient.invalidateQueries(["buyer", "events", id, true]);
                  queryClient.invalidateQueries(["buyer", "events", id, false]);
                  queryClient.invalidateQueries(["people-detail", id]);
                } catch (error) {
                  console.error("Failed to remove person from event:", error);
                }
              }}
            />
          </>
        )}

        {filteredBuyerEvents.length > 0 && (
          <>
            <h4 className="text-lg font-semibold my-4 dark:text-cave-white">
              Buyer:
            </h4>
            <EventTable
              events={filteredBuyerEvents}
              defaultSortColumn="event_date"
              defaultSortDirection={isPast ? "desc" : "asc"}
              isPast={isPast}
            />
          </>
        )}

        {filteredStaffEvents.length === 0 &&
          filteredBuyerEvents.length === 0 && (
            <p className="text-center text-gray-500 dark:text-gray-400">
              No events found.
            </p>
          )}
      </div>
    </section>
  );
};

export const PeopleDetail = (props) => {
  const { id } = useParams();
  const { user } = useUser();
  const { perms } = usePerms();
  const navigate = useNavigate();
  const person = useQuery(["people-detail", id], () => getPerson(id));

  const files = useQuery(
    ["people", "files", id],
    () => getPeopleFiles(id),
    getPeopleFileSettings
  );
  const images = useQuery(
    ["people", "images", id],
    () => getPeopleImages(id),
    getPeopleSettings
  );

  const visitMutation = useRecordVisit();
  usePageTitle(
    person.isSuccess
      ? `/c${String.fromCharCode(92)}${person.data.last_name}, ${
          person.data.first_name
        }`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  // Temp section just to get a table up and going remove
  //  const { data:past_events } = useQuery(["person", "events", "past", id], () =>
  //    getPersonEventsRoles(id)
  //  );
  //////////

  useEffect(() => {
    if (person.isSuccess) {
      visitMutation.mutate({
        itemID: person.data.id,
        itemType: "person",
        itemURL: `/people/${person.data.id}`,
      });
    }
  }, [person.isSuccess]);

  if (person.isLoading) {
    return <LoadingPulse />;
  }

  if (person.isError) {
    return (
      <div className="text-center text-red-500">
        Error loading person: {person.error?.message || "Unknown error"}
      </div>
    );
  }

  if (!person.data) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        No person data found
      </div>
    );
  }

  const fullName = `${person.data.first_name} ${person.data.last_name}`;
  const handleCreateImage = (data) => {
    return createPeopleImages(id, data);
  };

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[{ text: "People", url: "/people" }, { text: fullName }]}
        />
        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <PersonImageDisplay person={person} />

            <h2 className="text-2xl font-bold leading-7 dark:text-cave-white sm:truncate sm:text-3xl sm:tracking-tight">
              {fullName}
            </h2>
            <ImageDisplay
              images={images}
              createImage={handleCreateImage}
              deleteQuery={deletePeopleImage}
              editable={perms.data.edit_people}
              entityType="people"
              id={person.data.id}
            />

            {perms.data.view_people_pii ? (
              <>
                {person.data.address_line_1 ||
                person.data.city ||
                person.data.state ||
                person.data.zipcode ? (
                  <>
                    {person.data.address_line_1 && (
                      <h3 className="my-2 text-gray-600">
                        {person.data.address_line_1}
                      </h3>
                    )}
                    <h3 className="my-2 text-gray-600">
                      {[
                        person.data.city,
                        person.data.state,
                        person.data.zipcode,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </h3>
                  </>
                ) : (
                  <div className="my-2 text-gray-600">No Address available</div>
                )}
              </>
            ) : (
              <div className="my-2 text-gray-600">No Address available</div>
            )}
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0 justify-start align-top">
            <button
              type="button"
              onClick={() => {
                navigate(`/people/edit/${person.data.id}`);
              }}
              className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-cave-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
            >
              Edit Person
            </button>
          </div>
        </div>
        <div className="py-6">
          <table>
            <tbody>
              <tr className="py-8">
                <th className="text-left mr-8 dark:text-cave-white">Email</th>
                <td className="pl-4 dark:text-cave-white">
                  {person.data.email}
                </td>
              </tr>
              <tr>
                <th className="text-left mr-8 dark:text-cave-white">
                  Description
                </th>
                <td className="mb-4 ml-8 dark:text-cave-white">
                  &nbsp;&nbsp;&nbsp;{person.data.description}
                </td>
              </tr>
              {perms.data.view_people_pii && (
                <>
                  <tr>
                    <th className="text-left mr-8 dark:text-cave-white">
                      Phone
                    </th>
                    <td className="pl-4 dark:text-cave-white">
                      {person.data.phone}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left mr-8 dark:text-cave-white">URL</th>
                    <td className="pl-4 dark:text-cave-white">
                      <a
                        href="{person.data.website_url}"
                        className="text-blue-500 underline hover:text-blue-700"
                      >
                        {person.data.website_url}
                      </a>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <PersonEventsSection id={id} isPast={false} />
        <PersonEventsSection id={id} isPast={true} />
        <FileDisplay
          files={files}
          id={id}
          entityType="people"
          editable={perms.data.edit_people}
          perms={perms}
        />
      </div>
    </>
  );
};
