import React, { useState } from "react";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";

export function PPeopleIcon({ person }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [ref, isIntersecting] = useIntersectionObserver({
    threshold: 0.1,
    rootMargin: "50px",
  });

  const getImageUrl = () => {
    if (person?.user?.photo) {
      return person.user.photo;
    }
    if (person?.images && person.images.length > 0) {
      return person.images[0].image;
    }
    return null;
  };

  const imageUrl = getImageUrl();

  const initials =
    person?.first_name && person?.last_name
      ? `${person.first_name[0]}${person.last_name[0]}`.toUpperCase()
      : person?.first_name
      ? person.first_name[0].toUpperCase()
      : "?";

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const handleError = () => {
    setHasError(true);
  };

  return (
    <div
      ref={ref}
      className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden"
    >
      {imageUrl ? (
        <>
          {isIntersecting && !hasError && (
            <img
              src={imageUrl}
              alt={initials}
              className={`w-full h-full object-cover transition-opacity duration-300 ${
                isLoaded ? "opacity-100" : "opacity-0"
              }`}
              onLoad={handleLoad}
              onError={handleError}
            />
          )}
          {!isLoaded && !hasError && (
            <div className="spinner" role="status" aria-label="Loading" />
          )}
          {hasError && (
            <span className="text-xl font-semibold text-gray-500">
              {initials}
            </span>
          )}
        </>
      ) : (
        <span className="text-xl font-semibold text-gray-500">{initials}</span>
      )}
    </div>
  );
}

// Keep the old version for backwards compatibility
export function PeopleIcon({ imageUrl }) {
  return (
    <div
      className="w-12 h-12 rounded-full bg-cover bg-center"
      style={{ backgroundImage: `url(${imageUrl})` }}
    ></div>
  );
}
