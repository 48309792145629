/* MoneyInputComponent.tsx */

import React, {
  useState,
  useEffect,
  ChangeEvent,
  ForwardRefRenderFunction,
} from "react";
import { useGlowEffect } from "@/hooks/useGlowEffect";

interface MoneyInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string | number | null;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  editable?: boolean;
  className?: string;
}

// Helper to format the value in dollars for display
const displayAsDollars = (amount: string | number) => {
  const cents = typeof amount === "string" ? parseInt(amount, 10) : amount;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(cents / 100);
};

// Helper to convert dollar string to cents integer for API
const convertToCents = (input: string) => {
  const sanitized = input.replace(/[^\d.-]/g, "");
  const convert = Math.round(parseFloat(sanitized) * 100);
  return isNaN(convert) ? 0 : convert;
};

const MoneyInputComponent: ForwardRefRenderFunction<
  HTMLInputElement,
  MoneyInputProps
> = (
  {
    value: initialValue,
    onChange,
    onBlur,
    onFocus,
    editable = true,
    className,
    ...rest
  },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState<string | number>(
    initialValue ? displayAsDollars(initialValue) : "$0.00"
  );
  const [changed, setChanged] = useState(false);
  const hasGlowEffect = useGlowEffect(changed);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters

    // Clear input if initial value is a zero placeholder
    if (
      value === "0" ||
      value === "$0.00" ||
      value === "0.00" ||
      value === ""
    ) {
      inputValue = inputValue.replace(/^0+/, ""); // Remove leading zeros
      setValue(inputValue);
    } else {
      setValue(inputValue);
    }

    if (onChange) onChange(e); // Pass the event back to the parent
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);

    // Convert input value to cents for the API
    const centsValue = convertToCents(value.toString());

    // Update state to display formatted dollar value
    setValue(displayAsDollars(centsValue));

    // Trigger onBlur callback with the updated cents value
    if (onBlur) {
      e.target.value = centsValue.toString();
      onBlur(e);
    }

    // Trigger glow effect
    setChanged(true);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) onFocus();
    const currentValue = e.target.value;

    // Select all text if it's a default or empty value
    if (
      !currentValue ||
      currentValue === "0" ||
      currentValue === "$0" ||
      currentValue === "$" ||
      currentValue === "$0.00"
    ) {
      setValue(""); // Clear the input entirely
      e.target.select(); // Highlight all text
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        const inputElement = document.activeElement as HTMLInputElement;
        inputElement.blur();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <input
        ref={ref} // Forward the ref to the input element
        type="text"
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={`${hasGlowEffect ? "glow-text" : ""}${
          className ? " " + className : ""
        }`}
        readOnly={!editable}
        {...rest} // Spread the rest of the props onto the input element
      />
    </div>
  );
};

export default React.forwardRef(MoneyInputComponent);
