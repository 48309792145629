import React from "react";
import dayjs from "dayjs";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  UserIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";

const WeekView = ({
  date,
  events,
  onDateChange,
  onEventClick,
  activeFilters,
  toggleFilter,
  EVENT_MAPPING,
  excludedStates,
  handleShowModal,
  perms,
}) => {
  const currentDate = dayjs(date);
  const startOfWeek = currentDate.startOf("week");

  const getDayEvents = (day) => {
    return events.filter(
      (event) =>
        dayjs(event.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")
    );
  };

  const renderDay = (day) => {
    const dayEvents = getDayEvents(day);
    const isToday = day.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");

    return (
      <div
        key={day.format("YYYY-MM-DD")}
        className={`flex-1 min-h-[200px] bg-gray-800 p-4 rounded-lg ${
          isToday ? "border-2 border-green-500" : ""
        }`}
      >
        <div className="text-sm font-medium text-gray-400 mb-4">
          {day.format("ddd, MMM D")}
        </div>

        <div className="space-y-3">
          {dayEvents.length === 0 ? (
            <div className="text-center text-gray-500 text-sm">No events</div>
          ) : (
            dayEvents.map((event, index) => (
              <div
                key={index}
                className={`border-l-4 bg-gray-900 p-3 rounded cursor-pointer`}
                style={{
                  borderColor: EVENT_MAPPING[event.state]["rgb-background"],
                }}
                onClick={() => onEventClick(event)}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center space-x-2 mb-1">
                      <span
                        className={`w-2 h-2 rounded-full`}
                        style={{
                          backgroundColor:
                            EVENT_MAPPING[event.state]["rgb-background"],
                        }}
                      ></span>
                      <span className="text-xs text-gray-400 uppercase">
                        {event.state}
                      </span>
                    </div>
                    <h3 className="text-sm font-bold mb-1">{event.name}</h3>
                    <div className="flex items-center space-x-2 text-gray-400 text-xs">
                      <UserIcon className="h-3 w-3" />
                      <span>{event.buyer_name}</span>
                    </div>
                    <div className="flex items-center space-x-2 text-gray-400 text-xs">
                      <MapPinIcon className="h-3 w-3" />
                      <span>{event.venue_name}</span>
                    </div>
                  </div>
                  <button>
                    <EllipsisHorizontalIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  const weekDays = Array.from({ length: 7 }, (_, i) =>
    startOfWeek.add(i, "day")
  );

  return (
    <div className="bg-[#fffef9] dark:bg-cave-gray-900 dark:text-cave-white p-4">
      <div className="grid grid-cols-7 gap-4">
        {weekDays.map((day) => renderDay(day))}
      </div>
    </div>
  );
};

export default WeekView;
