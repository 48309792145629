import React from "react";

export default function SettlementTableTitle({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="bg-gray-300 text-black font-bold underline">{children}</div>
  );
}
