import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { getEvent } from "@/queries/events";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Datepicker,
  FormBuilder,
  Input,
  Checkbox,
  TextInput,
  FormSelect,
  FormMultiSelect,
  selectOptionToValue,
  selectValueToOption,
} from "@/components/Form";
import { amountDisplay, amountStrToInt } from "@/utils/money";
import {
  getArtistCuts,
  updateArtistCuts,
  getEventRollups,
} from "@/queries/accounting";

import { updateEventAccounting } from "@/queries/events";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import { usePageTitle } from "@/utils/pagetitle";

const holdOptions = [
  { value: "mammoth", label: "Mammoth" },
  { value: "building", label: "Building" },
  { value: "exception", label: "Exception" },
];

export const EventEditAccounting = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const artist_cuts = useQuery(["event-artist-cuts", id], () =>
    getArtistCuts(id)
  );
  const rollups = useQuery(["event-rollups", id], () => getEventRollups(id));
  const queryClient = useQueryClient();
  usePageTitle(
    event.isSuccess
      ? `/c${String.fromCharCode(92)}${event.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating event");

      // Reformat money data
      data.charity = amountStrToInt(data.charity);
      data.facility_fee = amountStrToInt(data.facility_fee);
      data.credit_card_fees = amountStrToInt(data.credit_card_fees);
      data.insurance_amount = amountStrToInt(data.insurance_amount);
      data.concessions = amountStrToInt(data.concessions);
      data.merch = amountStrToInt(data.merch);
      data.rebate = amountStrToInt(data.rebate);
      data.platinum_lift = amountStrToInt(data.platinum_lift);
      data.vip_lift = amountStrToInt(data.vip_lift);
      data.ticket_commission = amountStrToInt(data.ticket_commission);

      // options
      data = selectOptionToValue("hold_tm", data);
      data = selectOptionToValue("hold_etix", data);
      data = selectOptionToValue("hold_venue", data);
      data = selectOptionToValue("hold_fan_club", data);
      data = selectOptionToValue("hold_outlets", data);
      data = selectOptionToValue("hold_vip", data);

      // merch
      data = selectOptionToValue("who_sells_merch", data);
      console.dir(data);
      return updateEventAccounting(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["events"]);
      queryClient.invalidateQueries(["event-detail"]);
      queryClient.invalidateQueries(["event-artist-cuts"]);
      queryClient.invalidateQueries(["event-rollups"]);
      navigate(`/events/${id}`);
    },
  });

  const cutMutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating cuts");
      let cutData = [];
      Object.keys(data).forEach((key) => {
        if (key.startsWith("cut_")) {
          let artistID = parseInt(key.replace("cut_", ""));
          cutData.push({
            event: id,
            artist: artistID,
            percentage: data[key],
          });
        }
      });
      console.dir(cutData);
      return updateArtistCuts(id, cutData);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["events"]);
      queryClient.invalidateQueries(["event-detail"]);
      queryClient.invalidateQueries(["event-artist-cuts"]);
      queryClient.invalidateQueries(["event-rollups"]);
    },
  });

  if (event.isLoading || artist_cuts.isLoading || rollups.isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  const updateSchema = yup.object().shape({});

  const fixOptions = (name, value, options) => {
    let newValue = null;
    if (value) {
      newValue = selectValueToOption(value, options);
    }
    return newValue;
  };
  const merchOptions = [
    { value: "mammoth", label: "Mammoth" },
    { value: "artist", label: "Artist" },
    { value: "venue", label: "Venue" },
  ];

  let merchObject = null;
  if (event.data.who_sells_merch) {
    merchObject = {
      value: event.data.who_sells_merch,
      label: merchOptions.find(
        (option) => option.value === event.data.who_sells_merch
      ).label,
    };
  }

  let values = {
    name: event.data.name || "",
    charity: event.data.charity / 100 || 0,
    facility_fee: event.data.facility_fee / 100 || 0,
    drop_count: event.data.drop_count || 0,
    ticket_commission: event.data.ticket_commission / 100 || 0,
    platinum_lift: event.data.platinum_lift / 100 || 0,
    vip_lift: event.data.vip_lift / 100 || 0,
    has_ascap: event.data.has_ascap || false,
    has_bmi: event.data.has_bmi || false,
    has_sesac: event.data.has_sesac || false,
    credit_card_fees: event.data.credit_card_fees / 100 || 0,
    insurance_amount: event.data.insurance_amount / 100 || 0,
    concessions: event.data.concessions / 100 || 0,
    merch: event.data.merch / 100 || 0,
    rebate: event.data.rebate / 100 || 0,
    mammoth_percentage: event.data.mammoth_percentage || 0,
    building_percentage: event.data.building_percentage || 0,
    artist_soft_merch_percentage: event.data.artist_soft_merch_percentage || 0,
    artist_hard_merch_percentage: event.data.artist_hard_merch_percentage || 0,
    who_sells_merch: merchObject,
  };

  values.hold_tm = fixOptions("hold_tm", event.data.hold_tm, holdOptions);
  values.hold_etix = fixOptions("hold_etix", event.data.hold_etix, holdOptions);
  values.hold_venue = fixOptions(
    "hold_venue",
    event.data.hold_venue,
    holdOptions
  );
  values.hold_fan_club = fixOptions(
    "hold_fan_club",
    event.data.hold_fan_club,
    holdOptions
  );
  values.hold_outlets = fixOptions(
    "hold_outlets",
    event.data.hold_outlets,
    holdOptions
  );
  values.hold_vip = fixOptions("hold_vip", event.data.hold_vip, holdOptions);

  Array.from(artist_cuts.data).forEach((artist_cut) => {
    values[`cut_${artist_cut.artist}`] = artist_cut.percentage;
  });

  const handleSubmit = (data) => {
    console.log("In submit");
    console.dir(data);
    // Update artist cuts first
    cutMutation.mutate(data);

    // Now update the event data
    mutation.mutate(data);
  };

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[
            { text: "Events", url: "/events" },
            {
              text: `Edit: ${event.data.name}`,
              url: `/events/edit/${event.data.id}`,
            },
            { text: event.data.name },
          ]}
        />

        <h3 className="text-xl font-bold mb-4 dark:text-cave-white">
          Edit Accounting - {event.data.name}
        </h3>
        <div className="py-6 min-w-full">
          <FormBuilder
            defaultValues={values}
            onSubmit={(data) => handleSubmit(data)}
            schema={updateSchema}
          >
            <Input name="concessions" label="Concessions Total" />
            <Input name="merch" label="Merch Total" />
            <Input name="rebate" label="Rebate" />
            <Input name="charity" label="Charity (per ticket)" />
            <Input name="facility_fee" label="Facility Fee (per ticket)" />
            <Input name="drop_count" label="Drop Count" />
            <Input name="ticket_commission" label="Ticket Commission" />
            <Input name="platinum_lift" label="Platinum Lift" />
            <Input name="vip_lift" label="VIP Lift" />
            <h2 className="mt-8 text-lg font-semibold border-b dark:text-cave-white">
              Variable Expenses
            </h2>
            <Checkbox name="has_ascap" label="ASCAP" />
            <Checkbox name="has_bmi" label="BMI" />
            <Checkbox name="has_sesac" label="SESAC" />
            <Input name="credit_card_fees" label="Credit Card Fees" />
            <p className="my-2 font-semibold dark:text-cave-white">
              Total Attendance: {rollups.data.total_attendance}
            </p>
            <Input name="insurance_amount" label="Insurance Amount" />
            <h2 className="mt-8 text-lg font-semibold border-b dark:text-cave-white">
              Who Retains Money for Ticket Types
            </h2>
            <FormSelect name="hold_tm" label="TM" options={holdOptions} />
            <FormSelect name="hold_etix" label="etix" options={holdOptions} />
            <FormSelect name="hold_venue" label="Venue" options={holdOptions} />
            <FormSelect
              name="hold_fan_club"
              label="Fan Club"
              options={holdOptions}
            />
            <FormSelect
              name="hold_outlets"
              label="Outlets"
              options={holdOptions}
            />
            <FormSelect name="hold_vip" label="VIP" options={holdOptions} />
            <h2 className="mt-8 text-lg font-semibold border-b dark:text-cave-white">
              Percentages
            </h2>
            <Input name="mammoth_percentage" label="Mammoth Percentage" />
            <Input name="building_percentage" label="Building Percentage" />

            <h2 className="mt-8 text-lg font-semibold border-b dark:text-cave-white">
              Artist Percentages
            </h2>
            {artist_cuts.data.map((cut) => (
              <Input
                key={`artist-cut-${cut.artist}`}
                name={`cut_${cut.artist}`}
                label={`${cut.artist_name} Artist Percentage`}
              />
            ))}
            <h2 className="mt-8 text-lg font-semibold border-b dark:text-cave-white">
              Merch
            </h2>
            <FormSelect
              name="who_sells_merch"
              label="Who Sells Merch"
              options={merchOptions}
            />
            <Input
              name="artist_soft_merch_percentage"
              label="Artist Soft Merch Percentage"
            />
            <Input
              name="artist_hard_merch_percentage"
              label="Artist Hard Merch Percentage"
            />
            <div className="flex-inline float-right mb-12 mt-8">
              <button
                type="button"
                onClick={() => navigate(`/events/${event.data.id}`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Cancel
              </button>
              <Button type="submit">Save</Button>
            </div>
          </FormBuilder>
        </div>

        <div className="mb-8">&nbsp;</div>
      </div>
    </>
  );
};
