/*
  react-select expects it's options to be an object like:

  {value: 'foo', label: 'Foo'}
  {value: 1334, label: 'Frank Wiles'}

  but our django-ninja API backend just needs the value portion of this.

  So we're replacing the property in data with just the value portion and
  returning a new data
*/
export const selectOptionToValue = (name, data) => {
  // Handle empty case
  if (!data || !data.hasOwnProperty(name) || !data[name]) {
    return data;
  }

  data[name] = data[name].value;
  return data;
};

// The reverse of above
export const selectValueToOption = (value, options) => {
  if (!value || !options) return null;
  const option = options.find((opt) => opt.value === value);
  if (!option) return null;
  return {
    value: option.value,
    label: option.label
  };
};
