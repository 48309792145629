import React, { useEffect, useState } from "react";

export const CompanyLogoAnimation = ({ isLoading, isFadingOut }) => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    if (isLoading && !isFadingOut) {
      const interval = setInterval(() => {
        setRotation((prev) => (prev + 1) % 360);
      }, 16);
      return () => clearInterval(interval);
    }
  }, [isLoading, isFadingOut]);

  return (
    <div
      className={`fixed top-0 right-0 w-1/5 h-full flex items-center justify-center bg-black transition-opacity duration-1000 ${
        !isLoading || isFadingOut ? "opacity-0" : "opacity-100"
      }`}
    >
      <div
        className="w-64 h-64 relative"
        style={{
          transform: `rotate(${rotation}deg)`,
          transition: "transform 0.2s ease-out",
        }}
      >
        <img
          src="/static/images/company-logo-light-bg.svg"
          alt="Company Logo"
          className="w-full h-full object-contain"
        />
      </div>
    </div>
  );
};
