import React, { useEffect, useState, useRef } from "react";

export const DashboardLoading = () => {
  const videoRef = useRef(null);
  
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div
      className="dashboard-loading flex items-center justify-center"
      style={{
        isolation: "isolate",
      }}
    >
      <div className="relative w-full h-full">
        <video
          ref={videoRef}
          className="w-full h-full object-cover"
          src="/static/images/cave_loading3.mp4"
          autoPlay
          muted
          playsInline
          loop
        />
      </div>
    </div>
  );
};
