import { useState, useEffect } from "react";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

export const useGlowEffect = (changed: boolean) => {
  const [hasGlowEffect, setHasGlowEffect] = useState(false);

  useEffect(() => {
    if (changed) {
      setHasGlowEffect(true);
      const timeout = setTimeout(() => {
        setHasGlowEffect(false);
      }, FIELD_UPDATE_ANIMATION_TIME);
      return () => clearTimeout(timeout);
    }
  }, [changed]);

  return hasGlowEffect;
};
