import React from "react";

interface LoadingState {
  isLoading: boolean;
  isError: boolean;
  errors: Error[];
}

interface LoadingScreenProps {
  message?: string;
}

interface ErrorScreenProps {
  errors: Error[];
}

export const LoadingScreen: React.FC<LoadingScreenProps> = ({
  message = "Loading Event Advance...",
}) => (
  <div className="flex justify-center items-center p-4">
    <div className="dark:text-cave-white text-lg">{message}</div>
  </div>
);

export const ErrorScreen: React.FC<ErrorScreenProps> = ({ errors }) => (
  <div className="flex justify-center items-center p-4 text-red-500">
    <div className="text-lg">
      Error loading event advance information. Please try again.
      {errors.map((error, index) => (
        <div key={index} className="text-sm mt-2">
          {error.message}
        </div>
      ))}
    </div>
  </div>
);

export const checkLoadingStates = (states: LoadingState[]): LoadingState => {
  return states.reduce(
    (acc, curr) => ({
      isLoading: acc.isLoading || curr.isLoading,
      isError: acc.isError || curr.isError,
      errors: [...acc.errors, ...curr.errors],
    }),
    {
      isLoading: false,
      isError: false,
      errors: [],
    }
  );
};
