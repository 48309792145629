import React, { useState } from "react";
import { createMessage } from "@/queries/bb";

export const ChallengeHold = ({ event, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState(null);

  const handleInterestClick = async () => {
    setIsLoading(true);
    setError(null);

    const messageData = {
      sender_id: user.data.id,
      recipient_id: event.data.buyer.id,
      content: `${user.data.first_name} is challenging your hold of ${event.data.name} at ${event.data.venue.name} on the ${event.data.date}`,
    };
    // 'Release Hold' button
    // FUTURE: hold will be released in 24hrs
    // 'Ask for Extension' --> gives them another 24hrs  -- only for 1st hold -- only beyond 24hrs
    // 'Contacting Agent' -- in 24hr peroiod
    // 'Acknowledgement' of seen
    // hold challenge colors  -- border around the profile is purple or pink
    // other border colors to match confirmed show
    // buttons above 'Josh is challenging'

    try {
      await createMessage(messageData);
      setIsSent(true);
    } catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to send your interest. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {event.isSuccess && event.data.buyer.id !== user.data.id && (
        <div className="w-full">
          {!isSent ? (
            <button
              onClick={handleInterestClick}
              disabled={isLoading}
              className={`mt-2 ml-4 rounded-md px-2.5 py-1.5 text-2xl font-normal shadow-sm ${
                isLoading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-cave-orange text-cave-white"
              }`}
            >
              {isLoading ? "Sending..." : "Challenge Hold"}
            </button>
          ) : (
            <div className="mt-2 ml-4 text-green-700">
              {event.data.buyer.first_name} has 24hrs before their hold is
              released
            </div>
          )}
          {error && <div className="mt-2 ml-4 text-red-700">{error}</div>}
        </div>
      )}
    </>
  );
};

export default ChallengeHold;
