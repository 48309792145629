import React, { useCallback, useMemo } from "react";
import { Header } from "@/components/Header";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { getOnSaleEvents, recordTickets } from "@/queries/events";
import { amountDisplay } from "@/utils/money";
import { usePageTitle } from "@/utils/pagetitle";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export const TicketsHome = () => {
  const queryClient = useQueryClient();
  const { data: events, isLoading } = useQuery(["events-on-sale"], () =>
    getOnSaleEvents()
  );

  usePageTitle(
    !isLoading
      ? `/c${String.fromCharCode(92)}ticketing`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  const mutation = useMutation({
    mutationFn: ({ eventId, manifestId, data }) => {
      const cleanData = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          value === "" ? 0 : value,
        ])
      );
      return recordTickets(eventId, manifestId, cleanData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["events-on-sale"]);
    },
  });

  const columnDefs = useMemo(
    () => [
      { field: "eventName", headerName: "Event", width: 200 },
      { field: "date", headerName: "Date", width: 120 },
      { field: "venueName", headerName: "Venue", width: 150 },
      { field: "sectionName", headerName: "Section", width: 150 },
      {
        field: "price",
        headerName: "Price",
        width: 100,
        valueFormatter: (params) => amountDisplay(params.value),
      },
      { field: "tm", headerName: "TM", width: 100, editable: true },
      { field: "etix", headerName: "Etix", width: 100, editable: true },
      {
        field: "venue",
        headerName: "Venue Tickets",
        width: 120,
        editable: true,
      },
      { field: "fan_club", headerName: "Fan Club", width: 120, editable: true },
      { field: "outlets", headerName: "Outlets", width: 100, editable: true },
      { field: "vip", headerName: "VIP", width: 100, editable: true },
    ],
    []
  );

  const rowData = useMemo(() => {
    if (!events) return [];
    return events.flatMap((event) =>
      event.manifests.map((manifest) => ({
        eventId: event.id,
        manifestId: manifest.id,
        eventName: event.name,
        date: event.date,
        venueName: event.venue.name,
        sectionName: manifest.name,
        price: manifest.price,
        tm: manifest.tm || 0,
        etix: manifest.etix || 0,
        venue: manifest.venue || 0,
        fan_club: manifest.fan_club || 0,
        outlets: manifest.outlets || 0,
        vip: manifest.vip || 0,
      }))
    );
  }, [events]);

  const onCellValueChanged = useCallback(
    (params) => {
      const { data } = params;
      // Calculate changes from previous values
      const oldData = params.oldValue;
      const field = params.colDef.field;
      const change = {
        [field]: data[field] - (oldData || 0),
      };

      mutation.mutate({
        eventId: data.eventId,
        manifestId: data.manifestId,
        data: change,
      });
    },
    [mutation]
  );

  if (isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto h-screen lg:py-0">
        <div className="w-full flex justify-between items-center mb-4">
          <BreadCrumbs links={[{ text: "Tickets", url: "/tickets" }]} />
        </div>
        <div className="my-4">
          <h2 className="text-xl font-bold dark:text-cave-white">
            All On-Sale Events
          </h2>
        </div>
        <div className="flex-grow ag-theme-alpine dark:ag-theme-alpine-dark">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            onCellValueChanged={onCellValueChanged}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
            }}
            domLayout="autoHeight"
          />
        </div>
      </div>
    </>
  );
};
