import { useEffect, useState } from "react";
import { SectionHeader } from "../../views/events/accountingComponents";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { amountDisplay } from "../../utils/money";
import { SearchBar } from "../Search/SearchBar";
import { PPeopleIcon, PeopleIcon } from "@/components/People/PeopleIcon";
import { AArtistIcon, ArtistIcon } from "@/components/Artist/Icon";

export const SortedTable = ({
  data,
  headers,
  total,
  title,
  customSort,
  onEditClick,
  calculateTotal,
  formatCell,
  hasFilter = false,
  hasHeader = false,
  handleRowClick,
  hasSorting = true,
  initiallyOpen = true,
  initialSortKey = null,
  initialSortOrder = "asc",
  excludeDarkMode = false,
}) => {
  const [sortedData, setSortedData] = useState([]);
  const [sortKey, setSortKey] = useState(initialSortKey);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);
  const [showTable, setShowTable] = useState(initiallyOpen);
  const [filters, setFilters] = useState([]);
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    const formatted = formatData(data);
    setRawData(data);
    if (sortKey) {
      const sorted = sortData(data, formatted, sortKey, sortOrder);
      setSortedData(sorted);
    } else {
      setSortedData(formatted);
    }
  }, [data, sortKey, sortOrder]);

  function localFormatCell(cellType, value) {
    if (value === null || value === undefined) return "";
    if (cellType === "money") {
      return amountDisplay(value);
    } else if (cellType === "number") {
      return typeof value === "string"
        ? value.replace(/\D/g, "")
        : value.toString();
    }
    return value;
  }

  function formatData(data) {
    return data.map((row, rowIndex) => {
      let formattedRow = {
        ...row,
        styles: row.styles || "",
      };
      Object.keys(row).forEach((propertyName) => {
        let headerObj = headers.find(
          (header) => header.accessor === propertyName
        );
        if (headerObj) {
          let output = formatCell && formatCell(propertyName, row, rowIndex);
          formattedRow[propertyName] =
            output !== undefined
              ? output
              : headerObj.type
              ? localFormatCell(headerObj.type, row[propertyName])
              : row[propertyName];
        }
      });
      return formattedRow;
    });
  }

  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder((currentOrder) => (currentOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  const getSortValue = (rawRow, key) => {
    if (customSort) {
      return customSort(key, rawRow);
    }

    if (key.includes(".")) {
      return getCellValue(rawRow, key);
    }

    const value = rawRow[key];
    if (value === null || value === undefined) return "";
    return typeof value === "string" ? value.toLowerCase() : value;
  };

  const sortData = (rawDataArray, formattedDataArray, key, order) => {
    const indices = Array.from({ length: rawDataArray.length }, (_, i) => i);

    indices.sort((a, b) => {
      const aValue = getSortValue(rawDataArray[a], key);
      const bValue = getSortValue(rawDataArray[b], key);

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      const comparison =
        order === "asc"
          ? aValue < bValue
            ? -1
            : aValue > bValue
            ? 1
            : 0
          : aValue > bValue
          ? -1
          : aValue < bValue
          ? 1
          : 0;

      return comparison;
    });

    return indices.map((i) => ({
      ...formattedDataArray[i],
      styles: formattedDataArray[i]?.styles || "",
    }));
  };

  const handleSearch = (searchedValue) => {
    const lowerCaseSearchedValue = searchedValue.toLowerCase();
    const formattedData = formatData(data);

    const filteredIndices = data
      .map((_, index) => index)
      .filter((index) => {
        const rawRow = data[index];
        const formattedRow = formattedData[index];

        return headers.some((header) => {
          const rawValue = getSortValue(rawRow, header.accessor);
          const formattedValue = getCellValue(formattedRow, header.accessor);

          return (
            (rawValue &&
              rawValue
                .toString()
                .toLowerCase()
                .includes(lowerCaseSearchedValue)) ||
            (formattedValue &&
              formattedValue
                .toString()
                .toLowerCase()
                .includes(lowerCaseSearchedValue))
          );
        });
      });

    const filteredData = filteredIndices.map((i) => ({
      ...formattedData[i],
      styles: formattedData[i]?.styles || "",
    }));

    setSortedData(
      sortKey ? sortData(data, filteredData, sortKey, sortOrder) : filteredData
    );
  };

  const getCellValue = (row, accessor) => {
    const keys = accessor.split(".");
    let value = row;
    for (const key of keys) {
      if (value && typeof value === "object" && key in value) {
        value = value[key];
      } else {
        return null;
      }
    }
    return value;
  };

  // Enhanced renderCell function with width control
  const renderCell = (row, header) => {
    const value = getCellValue(row, header.accessor);

    // Handle special cases first
    if (header.accessor.endsWith("photo")) {
      return value ? <PeopleIcon imageUrl={value} /> : "";
    }
    if (header.accessor.endsWith("images")) {
      return value ? <PPeopleIcon person={row} /> : "";
    }
    if (header.accessor === "name" && row.image_url) {
      return value ? (
        <div className="flex items-center space-x-2">
          <AArtistIcon imageUrl={row.image_url} />
          <span className="truncate">{value}</span>
        </div>
      ) : (
        ""
      );
    }

    // Handle cell content with width control
    return (
      <div
        className="truncate"
        style={{
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </div>
    );
  };

  // Get combined styles for cell
  const getCellStyles = (header) => {
    const baseStyle = {};
    if (header.style?.width) baseStyle.width = header.style.width;
    if (header.style?.maxWidth) baseStyle.maxWidth = header.style.maxWidth;
    if (header.style?.minWidth) baseStyle.minWidth = header.style.minWidth;
    return baseStyle;
  };

  return (
    <div className="w-full">
      {hasHeader && (
        <div className="flex justify-between items-center">
          <SectionHeader
            className={excludeDarkMode ? "" : "dark:text-gray-500"}
            title={title}
            collapsible
            bgPress={() => setShowTable(!showTable)}
          />
        </div>
      )}
      {showTable && (
        <>
          {hasFilter && (
            <div className="flex justify-left pl-2 items-center mb-4">
              <SearchBar width="300px" handleSearch={handleSearch} />
            </div>
          )}
          <div className="overflow-x-auto">
            <table
              className={`w-full table-fixed text-sm text-left text-gray-500 mb-4 rounded-lg overflow-hidden block md:table ${
                excludeDarkMode ? "" : "dark:text-cave-white"
              }`}
            >
              <thead
                className={`text-xs text-gray-700 uppercase bg-cave-green-700 ${
                  excludeDarkMode ? "" : "dark:text-gray-500"
                }`}
              >
                <tr className="cursor-pointer w-full hidden md:table-row">
                  {headers.map((headerObj) => (
                    <th
                      key={headerObj.accessor}
                      className={`text-cave-white px-6 py-3 ${
                        hasSorting
                          ? "cursor-pointer hover:bg-cave-green-800"
                          : ""
                      } select-none ${headerObj.styles || ""}`}
                      style={getCellStyles(headerObj)}
                      onClick={() =>
                        hasSorting && handleSort(headerObj.accessor)
                      }
                    >
                      <div className="truncate">
                        {headerObj.header}
                        <span className="text-cave-white ml-2 text-[9px]">
                          {sortKey === headerObj.accessor &&
                            (sortOrder === "asc" ? "▲" : "▼")}
                        </span>
                      </div>
                    </th>
                  ))}
                  {onEditClick && (
                    <th className="px-6 py-3 cursor-pointer w-16"></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {sortedData.length > 0 ? (
                  sortedData.map((row, index) => (
                    <tr
                      className={`bg-cave-white border-b flex flex-col md:table-row ${
                        excludeDarkMode
                          ? ""
                          : "dark:bg-gray-800 dark:border-gray-700"
                      } ${
                        handleRowClick
                          ? "cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                          : ""
                      }`}
                      key={index}
                      onClick={() =>
                        handleRowClick && handleRowClick(row, index)
                      }
                    >
                      {headers.map((header, cellIndex) => (
                        <td
                          key={cellIndex}
                          className={`px-6 py-4 flex md:table-cell before:content-[attr(data-label)] before:md:hidden before:font-bold before:mr-2 ${
                            header.styles || ""
                          } ${row?.styles || ""}`}
                          data-label={header.header}
                          style={getCellStyles(header)}
                        >
                          {renderCell(row, header)}
                        </td>
                      ))}
                      {onEditClick && (
                        <td className="px-6 py-4 w-16">
                          <div className="p-1 hover:bg-blue-200 w-fit">
                            <PencilSquareIcon
                              className="h-4 w-4 hover:text-blue-700"
                              onClick={(e) => {
                                e.stopPropagation();
                                onEditClick(row, index);
                              }}
                            />
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={headers.length + (onEditClick ? 1 : 0)}
                      className="p-4"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};
