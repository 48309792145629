export const amountDisplay = (amount, reverse) => {
  // if already formatted, return
  if (typeof amount === "string" && amount.includes("$")) {
    return amount;
  }
  if (amount === null || amount === 0) {
    return "$0.00";
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  let formattedAmount = formatter.format(amount / 100);

  // Handle reverse display
  if (reverse) {
    if (amount >= 0) {
      return `(${formattedAmount})`;
    } else {
      return formattedAmount;
    }
  }

  if (amount >= 0) {
    return formattedAmount;
  } else {
    return `(${formattedAmount})`;
  }
};

/* Take '4.01' and make it 401 */
export const amountStrToInt = (amount) => {
  amount = (amount + "").replace(/[,$]/g, "");
  let f = parseFloat(amount);
  f = f * 100;
  return parseInt(f);
};

export const formatDollarsToCents = (value) => {
  value = (value + "").replace(/[^\d.-]/g, "");
  if (value && value.includes(".")) {
    value = value.substring(0, value.indexOf(".") + 3);
  }

  return value ? Math.round(parseFloat(value) * 100) : 0;
};

export const formatCentsToDollars = (value) => {
  value = (value + "").replace(/[^\d.-]/g, "");
  value = parseFloat(value);
  return value ? value / 100 : 0;
};

/**
 * Formats a number or string to a currency string in US dollars.
 * The output is formatted with a dollar sign, commas as thousand separators, and exactly two decimal places.
 *
 * @param {string} value - The number or string to format as currency.
 * @returns {string} The formatted currency string.
 *
 * @example
 * formatToMoney(401)           // "$401.00"
 * formatToMoney(4)             // "$4.00"
 * formatToMoney('4.00')        // "$4.00"
 * formatToMoney(20000)         // "$20,000.00"
 * formatToMoney("$20,000")     // "$20,000.00"
 * formatToMoney("$20,000.07")  // "$20,000.07"
 * formatToMoney(2419)          // "$2,419.00"
 */
export function formatInputToMoney(value) {
  if (!value || value?.trim() === "") {
    return "$0.00";
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(parseFloat(value.replace(/[$,]/g, "")));
}
