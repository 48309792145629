import { useState } from "react";
import Select from "react-select";
import Button from "@/components/Button";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { TrashIcon } from "@heroicons/react/24/outline";
import { getEventPDFs, createEventPDF } from "@/queries/events";
import displayDateTime from "@/utils/datetime";
import classnames from "classnames";

const PDFRow = (props) => {
  const { pdf } = props;

  const rowClasses = classnames("p-4", {
    "bg-yellow-100 italic": pdf.state == "waiting",
    "bg-green-100 italic": pdf.state == "generating",
    "bg-red-200 italic": pdf.state == "failed",
    "bg-cave-white": pdf.state == "done",
  });

  let prefixText = "";
  if (pdf.state == "waiting") {
    prefixText = "Queued";
  }
  if (pdf.state == "generating") {
    prefixText = "Creating";
  }

  let nameColumn = `${prefixText} ${pdf.name} #${pdf.file_number}`;
  if (pdf.file_url) {
    nameColumn = (
      <a
        href={pdf.file_url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:text-blue-600 underline cursor-pointer"
      >
        {nameColumn}
      </a>
    );
  }

  return (
    <tr className="hover:bg-gray-100 dark:hover:bg-gray-800">
      <td className={rowClasses}>{nameColumn}</td>
      <td className="p-4">{pdf.creator_name}</td>
      <td className="p-4">{displayDateTime(pdf.created)}</td>
    </tr>
  );
};

const EventPDF = (props) => {
  const { event, canAdd } = props;
  const queryClient = useQueryClient();

  // Refetch Event PDFs every 500ms for 20 seconds after one is requested
  // so we start our state at 40 to not be refetching
  const [counter, setCounter] = useState(40);
  const pdfs = useQuery(
    ["event-pdfs", event.id],
    () => {
      setCounter(counter + 1);
      return getEventPDFs(event.id);
    },
    {
      refetchInterval: () => (counter < 40 ? 500 : false),
    }
  );

  const [type, setType] = useState(null);

  const createMutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating event pdf");
      console.dir(data);
      return createEventPDF(event.id, data);
    },
    onError: (error, variables, context) => {
      console.log("ERROR:");
      console.dir(error);
      console.dir(variables);
      console.dir(context);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(["event-pdfs", event.id]);
      // Restart our countdown
      setCounter(0);
      pdfs.refetch();
    },
  });

  let typeOptions = [{ value: "offer", label: "Offer" }];

  if (event.state == "confirmed") {
    typeOptions.push({
      value: "artist_settlement",
      label: "Artist Settlement",
    });
    typeOptions.push({ value: "copro_settlement", label: "CoPro Settlement" });
    typeOptions.push({ value: "final_settlement", label: "Final Settlement" });
  }

  const createPDF = () => {
    // Bail out if we don't know the type yet
    if (type === null) {
      return;
    }

    let option = typeOptions.find((o) => o.value === type);
    const data = {
      name: option.label,
      file_type: type,
      note: "",
    };

    console.log("creating offer pdf", data);

    createMutation.mutate(data);
  };

  if (pdfs.isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  // Disable our button if they haven't chosen a type yet
  let disabled = false;
  if (type === null) {
    disabled = true;
  }

  return (
    <div className="flex flex-col mb-6 rounded-lg shadow-md">
      {" "}
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">PDFs</h3>
        {canAdd && (
          <div className="inline-flex flex-wrap gap-4">
            {" "}
            <Select
              name="type"
              options={typeOptions}
              className="w-full sm:w-[150px] mb-2 sm:mb-0"
              onChange={(selectedOption) => setType(selectedOption.value)}
            />
            <Button onClick={() => createPDF()} disabled={disabled}>
              Create New PDF
            </Button>
          </div>
        )}
      </div>
      <div className="overflow-x-auto">
        {" "}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3">
                {" "}
                File
              </th>
              <th scope="col" className="px-4 py-3">
                {" "}
                Created By
              </th>
              <th scope="col" className="px-4 py-3">
                {" "}
                Created
              </th>
            </tr>
          </thead>
          <tbody className="bg-cave-white">
            {pdfs.data.length == 0 && (
              <tr>
                <td colSpan="3" className="p-4 text-center">
                  <em>No PDFs have been created yet</em>
                </td>
              </tr>
            )}
            {pdfs.data.map((pdf, index) => (
              <PDFRow pdf={pdf} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventPDF;
