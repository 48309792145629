import React from "react";
import { useRouteError } from "react-router-dom";
import Button from "../Button";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);

    // Log to Sentry if available
    if (window.Sentry) {
      // Add validation error context
      const errorContext = {
        ...errorInfo,
        isValidationError:
          error.message && error.message.includes("validation error"),
        errorType: "ValidationError",
      };
      window.Sentry.captureException(error, {
        extra: errorContext,
        tags: {
          errorType: "ValidationError",
          component: "StaffDirectory",
        },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      const isLocalhost = window.location.hostname === "localhost";

      return (
        <div className="min-h-screen bg-cave-black flex flex-col justify-center items-center p-4">
          <div className="relative w-48 h-48 mb-8">
            <img
              src="/static/images/company-logo-black-bg.svg"
              alt="Cave Logo"
              className="w-full h-full"
            />
          </div>

          <div className="bg-cave-black p-8 rounded-lg shadow-lg shadow-white/10 max-w-md w-full space-y-4 border border-cave-blue-3">
            <h1 className="text-2xl font-bold text-cave-white text-center">
              cave find something not good
            </h1>

            <div className="text-cave-white/90 space-y-2">
              <p className="text-center">
                'umans have been informed
                <br />
              </p>
              {isLocalhost && this.state.error?.message && (
                <p className="p-2 bg-cave-blue-4/30 rounded text-sm font-mono text-cave-white border-2 border-color-white">
                  {this.state.error.message}
                </p>
              )}
            </div>

            <div className="content-center">
              <div className="content-center text-center flex ml-16 gap-4 pt-4">
                <Button
                  onClick={() => window.location.reload()}
                  className="bg-cave-blue-3 hover:bg-cave-blue-2 text-cave-white px-4 py-2 border border-cave-blue-2 hover:border-white transition-all duration-200"
                >
                  Reload Page
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => window.history.back()}
                  className="bg-cave-orange hover:bg-cave-orange/80 text-cave-white px-4 py-2 transition-colors duration-200"
                >
                  Go Back
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
