import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from "./base";

export const getPeopleSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getPeople = async () => {
  const url = `/api/people/`;
  return await getApiRequest(url);
};

export const getStaff = async () => {
  const url = `/api/people/staff/`;
  return await getApiRequest(url);
};

export const getPerson = async (id) => {
  const url = `/api/people/${id}/`;
  return await getApiRequest(url);
};

export const updatePerson = async (id, data) => {
  const url = `/api/people/${id}/`;
  return await putApiRequest(url, data);
};

export const createPerson = async (data) => {
  const url = `/api/people/`;
  return await postApiRequest(url, data);
};

export const getPersonEventsRoles = async (id, upcoming = true) => {
  if (!id) return null;
  const url = `/api/people/${id}/events-roles?upcoming=${upcoming}`;
  try {
    console.log('Requesting Person Events:', {
      url,
      personId: id,
      upcoming,
      time: new Date().toISOString()
    });
    const response = await getApiRequest(url);
    console.log('Person Events Response:', {
      url,
      personId: id,
      eventCount: response?.events_roles?.length,
      events: response?.events_roles,
      timestamp: new Date().toISOString(),
      firstEvent: response?.events_roles?.[0],
      firstEventVenue: response?.events_roles?.[0]?.venue_name
    });
    return response;
  } catch (error) {
    console.error('Error fetching person events:', {
      url,
      error: error.message,
      errorStack: error.stack,
      id,
      timestamp: new Date().toISOString()
    });
    throw error;
  }
};

export const getPeopleFileSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getPeopleFiles = async (id) => {
  const url = `/api/people/files/${id}/`;
  return await getApiRequest(url);
};

export const createPeopleFile = async (id, data) => {
  const url = `/api/people/files/${id}/`;
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  const resp = await makeRequest(url, "POST", headers, data);
  return resp.json();
};

export const deletePeopleFile = async (id) => {
  const url = `/api/people/file/${id}/`;
  return await deleteApiRequest(url);
};

export const getPeopleImages = async (id) => {
  const url = `/api/people/images/${id}/`;
  return await getApiRequest(url);
};

export const deletePeopleImage = async (id) => {
  const url = `/api/people/images/delete/${id}/`;
  return await deleteApiRequest(url);
};

export const createPeopleImages = async (peopleId, data) => {
  console.log("createPeopleImage called with peopleId:", peopleId);
  console.log("Data to be sent:", data);
  const url = `/api/people/images/${peopleId}/`;
  console.log("Request URL:", url);
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  try {
    const resp = await makeRequest(url, "POST", headers, data);
    const jsonResponse = await resp.json();
    console.log("createPeopleImage response:", jsonResponse);
    return jsonResponse;
  } catch (error) {
    console.error("Error in createPeopleImage:", error);
    throw error;
  }
};

export const removePersonFromEvent = async (eventId, personId) => {
  const url = `/api/events/people/remove/${eventId}/${personId}/`;
  return await deleteApiRequest(url);
};
