import { EventsLoading } from "@/components/Events/EventsLoading";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getEventsList, getEventsSettings, getEventRoles, getEventPeople } from "@/queries/events";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import EventState from "@/components/EventState";
import Button from "@/components/Button";
import { usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import { EventRow } from "./EventsList";
import { useState } from "react";

export const TouringList = () => {
  const [activeFilters, setActiveFilters] = useState(["mammoth_touring", "sabertooth", "grizzly"]);
  
  const events = useQuery(
    ["events", "list"],
    () => getEventsList(),
    getEventsSettings
  );

  const filteredEvents = events.data?.filter(event => 
    event && event.company && activeFilters.includes(event.company)
  ) || [];
  const roles = useQuery(["event-roles"], () => getEventRoles());
  const navigate = useNavigate();
  const { perms } = usePerms();
  usePageTitle(events.isSuccess ? "/c/touring" : "/c/ave_good...");

  const [searchTerm, setSearchTerm] = useState("");

  const authorizedRoles = ["admin", "event_manager"];
  const isAuthorized = perms.data && authorizedRoles.includes(perms.data.role);

  if (events.isLoading || roles.isLoading) {
    return (
      <>
        <Header />
        <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full">Loading...</div>
        </div>
      </>
    );
  }

  // Apply both company and search filters
  const searchFilteredEvents = filteredEvents?.filter((event) =>
    event && event.name && event.name.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const eventRoleChoices = roles.data
    .filter((role) => role.active)
    .map((role) => ({ id: role.id, name: role.name }));

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full flex justify-between items-center mt-[.5rem]">
          <div className="flex items-center gap-4">
            <BreadCrumbs links={[{ text: "Touring Events", url: "/touring" }]} />
            <div className="flex space-x-2">
              <button
                className={`px-2 py-1 rounded text-xs transition-colors duration-200 ${
                  activeFilters.includes("mammoth_touring") 
                    ? "bg-blue-600 text-white" 
                    : "bg-gray-500 text-white"
                }`}
                onClick={() => setActiveFilters(prev => 
                  prev.includes("mammoth_touring") 
                    ? prev.filter(f => f !== "mammoth_touring")
                    : [...prev, "mammoth_touring"]
                )}
              >
                Mammoth Touring
              </button>
              <button
                className={`px-2 py-1 rounded text-xs transition-colors duration-200 ${
                  activeFilters.includes("sabertooth")
                    ? "bg-blue-600 text-white"
                    : "bg-gray-500 text-white"
                }`}
                onClick={() => setActiveFilters(prev => 
                  prev.includes("sabertooth")
                    ? prev.filter(f => f !== "sabertooth")
                    : [...prev, "sabertooth"]
                )}
              >
                Sabertooth
              </button>
              <button
                className={`px-2 py-1 rounded text-xs transition-colors duration-200 ${
                  activeFilters.includes("grizzly")
                    ? "bg-blue-600 text-white"
                    : "bg-gray-500 text-white"
                }`}
                onClick={() => setActiveFilters(prev => 
                  prev.includes("grizzly")
                    ? prev.filter(f => f !== "grizzly")
                    : [...prev, "grizzly"]
                )}
              >
                Grizzly
              </button>
            </div>
          </div>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Filter Events by Name"
            className="w-72 p-2 border border-gray-300 rounded-md"
          />
          <Button onClick={() => navigate("/touring")}>Event Cards</Button>
          <span>
            {perms.data.create_events && (
              <Button onClick={() => navigate("/events/add?company=touring")}>
                Create Touring Draft
              </Button>
            )}
          </span>
        </div>
        <div className="my-2 w-full shadow-md sm:rounded-lg">
          {events.isSuccess && (
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 w-1/6">Event Name</th>
                  <th scope="col" className="px-6 py-3 w-1/6">Date</th>
                  <th scope="col" className="px-6 py-3 w-1/6">Venue</th>
                  <th scope="col" className="px-6 py-3 w-1/6">Staff</th>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    {isAuthorized ? "Select Option" : "Status"}
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/12">Sold / Available</th>
                  <th scope="col" className="px-6 py-3 w-1/12"></th>
                </tr>
              </thead>
              <tbody>
                {searchFilteredEvents.map((event) => (
                  <EventRow
                    key={event.id}
                    event={event}
                    showSelectMenu={!isAuthorized}
                    roles={eventRoleChoices}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};
