import React, { useEffect, useState } from "react";
import MoneyInputComponent from "../Common/MoneyInput";

interface SettlementInputFieldProps {
  value: string | number; // Controlled value from parent
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (newValue: string | number) => void; // Changed to send value up
  className?: string;
  editable?: boolean;
  format?: "text" | "money";
}

const SettlementInputField: React.FC<SettlementInputFieldProps> = ({
  value,
  onBlur,
  onChange,
  className = "",
  editable = true,
  format = "text",
}) => {
  const [internalValue, setInternalValue] = useState<string | number>(value);

  useEffect(() => {
    setInternalValue(value); // Sync local value with props when value changes
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // setInternalValue(inputValue);
    if (onChange) onChange(inputValue); // Send updated value to parent
  };

  const RenderInput = () => {
    if (format === "money") {
      return (
        <MoneyInputComponent
          value={internalValue}
          onChange={(e) => handleChange(e)} // Ensure value is updated
          onBlur={onBlur}
          className={`w-full block focus:outline-none ${editable ? "bg-input-blue" : ""} focus:ring text-xs self-start ${className}`}
          editable={editable}
        />
      );
    }

    return (
      <input
        defaultValue={internalValue}
        onChange={handleChange}
        onBlur={onBlur}
        type="text"
        className={`w-full block focus:outline-none ${editable ? "bg-input-blue" : ""} focus:ring text-xs self-start ${className}`}
        readOnly={!editable}
      />
    );
  };

  return <RenderInput />;
};

export default SettlementInputField;
