import React, { useState, useEffect } from "react";

export const Logo = () => {
  const [logoSrc, setLogoSrc] = useState(
    "/static/images/company-logo-light-bg.svg"
  ); // default logo

  useEffect(() => {
    const theme = localStorage.getItem("color-theme");
    const prefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    if (theme === "dark" || (!theme && prefersDark)) {
      setLogoSrc("/static/images/company-logo-black-bg.svg");
    } else {
      setLogoSrc("/static/images/company-logo-light-bg.svg");
    }
  }, []);

  return (
    <a href="/" className="flex mr-6">
      <img src={logoSrc} className="mr-3 h-20" alt="Company Logo" />
      <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-cave-white"></span>
    </a>
  );
};
