import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { FormBuilder, Input, TextInput } from "@/components/Form";
import { createArtist } from "@/queries/artists";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";

export const ArtistAdd = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating artist");
      console.dir(data);

      return createArtist(data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["artists"]);
      navigate(`/artists/${data.id}`);
    },
  });

  const updateSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    abbr: yup.string(),
    url: yup.string().url(),
    email: yup.string().email(),
    phone: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
    twitter: yup.string(),
    instagram: yup.string(),
    facebook: yup.string(),
  });

  const handleSubmit = (data) => {
    console.log("In submit");
    console.dir(data);
    mutation.mutate(data);
  };

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[{ text: "Artists", url: "/artists" }, { text: "Add" }]}
        />

        <h3 className="text-xl font-bold mb-4 dark:text-cave-white">
          Add New Artist
        </h3>
        <div className="min-w-full">
          <FormBuilder
            onSubmit={(data) => handleSubmit(data)}
            schema={updateSchema}
          >
            <Input name="name" label="Name" autoFocus={true} />
            <Input name="abbr" label="Abbreviation" />
            <TextInput name="description" label="Description" />
            <Input name="url" label="URL" />
            <Input name="email" label="Email" />
            <Input name="phone" label="Phone" />
            <Input name="address" label="Address" />
            <Input name="city" label="City" />
            <Input name="state" label="State" />
            <Input name="zipcode" label="Zipcode" />
            <Input name="twitter" label="Twitter" />
            <Input name="instagram" label="Instagram" />
            <Input name="facebook" label="Facebook" />

            <div className="flex-inline float-right mb-12">
              <button
                type="button"
                onClick={() => navigate(`/artists/`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Cancel
              </button>
              <Button type="submit">Add Artist</Button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
