import { useQuery } from "@tanstack/react-query";
import { Header } from "@/components/Header";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { useParams } from "react-router-dom";
import { getEvent, getEventSettlementInfo } from "../../queries/events";
import { amountDisplay, formatInputToMoney } from "../../utils/money";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import {
  getArtistEventExpenses,
  getEventExpensesSettings,
  getEventRollups,
  getExpenseGroupSettings,
  getExpenseGroups,
} from "../../queries/accounting";

const MultiLineTable = ({ rows: rowsProp, headers, onInputChange }) => {
  const [rows, setRows] = useState(addIDsToRows(rowsProp));
  const [draggedOverRowId, setDraggedOverRowId] = useState(null);
  const tableRef = useRef(null);
  const originalContentRef = useRef("");
  const lastFocusedElementRef = useRef(null);

  useEffect(() => {
    setRows(rowsProp);
  }, [rowsProp]);

  function addIDsToRows(rows) {
    if (!rows) return [];
    rows.map((row) =>
      Object.assign(row, { id: Math.floor(100000 + Math.random() * 900000) })
    );
    return rows;
  }

  const handleKeyDown = (e, rowIndex, colIndex) => {
    const numCols = headers.length;
    let nextRow = rowIndex;
    let nextCol = colIndex;

    switch (e.key) {
      case "Enter":
        e.preventDefault();
        e.target.blur();
      default:
        return;
    }
  };

  const handleBlur = (e, colIndex, row, rowIndex) => {
    const currentContent = e.target.textContent;

    // don't make requests on each blur, only if the text content was changed
    if (!e.target.textContent || currentContent === originalContentRef.current)
      return;

    console.log("THE CONTENT IS DIFFERENT");

    if (headers[colIndex].format === "numbers") {
      e.target.textContent = e.target.textContent.replace(/\D/g, "");
    } else if (headers[colIndex].format === "money") {
      e.target.textContent = formatInputToMoney(e.target.textContent);
      if (e.target.textContent === "Invalid Input") return;
    }

    /* onInputChange && onInputChange(colIndex && headers[colIndex].accessor, e.target.textContent, rowIndex, () => {
      console.log("CALLING CALLBACK FUNCTION _---------")
      lastFocusedElementRef.current?.focus();
    }); */
  };

  const handleFocus = (e, colIndex) => {
    console.log("onfocus", e, colIndex);
    console.log("isfocus", headers[colIndex]);
    if (!(headers[colIndex].editable === false)) {
      originalContentRef.current = e.target.textContent;
    }
  };

  if (!rows || !headers) return <></>;

  return (
    <>
      <table
        ref={tableRef}
        className="w-full grid border border-dashed border-gray-300"
      >
        <thead>
          <tr className="grid grid-cols-[repeat(7,1fr)] bg-gray-300">
            {headers &&
              headers.map((column, index) => (
                <th
                  key={index}
                  className="border-gray-200 border text-left px-1 text-sm font-montserrat"
                >
                  {column.title}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr
              key={row.id}
              className={classNames("grid grid-cols-[repeat(7,1fr)]", {
                "border-t-2 border-black": draggedOverRowId === row.id,
                "bg-gray-300 ":
                  row.row_type === "title" || row.row_type === "footer",
              })}
            >
              {Object.keys(row)
                .filter((key) => key !== "id" && key !== "row_type")
                .map((key, colIndex) => (
                  <td
                    key={key}
                    className="border-gray-200 border p-1 text-sm font-montserrat"
                    contentEditable={!(headers[colIndex]?.editable === false)}
                    disabled={!(headers[colIndex]?.editable === false)}
                    suppressContentEditableWarning={true}
                    tabIndex={0}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
                    onFocus={(e) => handleFocus(e, colIndex)}
                    onBlur={(e) => handleBlur(e, colIndex, row, rowIndex)}
                  >
                    {headers[colIndex]?.format === "money"
                      ? amountDisplay(row[key])
                      : row[key]}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const EditableTable = ({
  rows,
  header,
  solidBorder,
  noBlue,
  onInputChange,
}) => {
  const handleBlur = (index, e, row) => {
    if (row.format === "money") {
      console.log(
        "MONEY BLUR",
        e.target.textContent,
        formatInputToMoney(e.target.textContent)
      );
      e.target.textContent = formatInputToMoney(e.target.textContent);
    } else if (row.format === "time") {
      e.target.textContent = formatTimeString(e.target.textContent);
    }

    onInputChange &&
      onInputChange(row.accessor, e.target.textContent, row.otherParams);
  };

  const handleSelection = (accessor, value, otherParams) => {
    onInputChange && onInputChange(accessor, value, otherParams);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    } else if (e.key === "ArrowUp" && index > 0) {
      e.preventDefault();
      const previousRow =
        e.target.parentElement.previousElementSibling.lastElementChild;
      previousRow.focus();
    } else if (e.key === "ArrowDown" && index < rows.length - 1) {
      e.preventDefault();
      const nextRow =
        e.target.parentElement.nextElementSibling.lastElementChild;
      nextRow.focus();
    }
  };

  if (!rows) return <></>;

  return (
    <table
      className={`min-w-full border border-gray-300 ${
        !solidBorder && "border-dashed"
      }`}
    >
      <thead>
        {header && (
          <tr>
            <th
              className={`bg-gray-300 ${
                header.value ? "text-left" : "text-center"
              } font-bold text-sm font-montserrat`}
              colSpan={header.value ? 1 : 2} // Span the entire width if header.value is not provided
            >
              {header.title}
            </th>
            {header.value && (
              <th className="bg-gray-300 text-sm text-right font-montserrat">
                {header.value}
              </th>
            )}
          </tr>
        )}
      </thead>
      <tbody className="w-full">
        {rows.map((row, index) => {
          if (row.highlight) row.editable = false;
          return (
            <tr
              className={`${row.highlight ? "bg-gray-300" : "bg-transparent"}`}
              key={index}
            >
              <th
                colSpan={row.highlight ? 2 : 1}
                className={`border-gray-300 border w-1/3 font-montserrat ${
                  row.highlight ? "font-extrabold" : "font-normal"
                } text-left px-1 text-sm`}
              >
                {row.header}
              </th>
              <td
                className={`border-gray-300 border w-2/3 p-1 text-sm font-montserrat `}
                contentEditable={!(row.editable === false)}
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                  row.format !== "select" && handleBlur(index, e, row)
                }
                onKeyDown={(e) => handleKeyDown(e, index)}
                onFocus={(e) =>
                  (e.target.style.outline = "outline 2px solid #3182c")
                }
                tabIndex={0}
                colSpan={row.highlight ? 2 : 1}
              >
                {row.format === "select" && row.options ? (
                  <EditableTableSelect
                    options={row.options}
                    onSelect={(option) =>
                      handleSelection(
                        row.accessor,
                        option.value,
                        row.otherParams
                      )
                    }
                    parentSelection={row.value}
                  />
                ) : (
                  row.value
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const TicketingTable = ({ handleChangeInput, id }) => {
  const event = useQuery(["event-detail", id], () => getEvent(id));

  console.log("event query table", id, event);

  if (event.isLoading)
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (event.isError) return <div>Error loading event data</div>;

  const headers = [
    { title: "MANIFEST", accessor: "manifest", editable: false },
    { title: "PRICE", format: "money", accessor: "price", editable: false },
    { title: "AVAILABLE", accessor: "available", editable: false },
    { title: "COMPS", accessor: "comps", editable: false },
    { title: "PAID", accessor: "paid", editable: false },
    { title: "GROSS", format: "money", accessor: "gross", editable: false },
    { title: "TICKET TYPE", accessor: "ticket_type", editable: false },
  ];

  const totals = event.data?.manifests?.reduce(
    (acc, manifest) => {
      acc.qty += manifest.qty;
      acc.price += manifest.price;
      acc.available += manifest.available;
      acc.comps += manifest.comps;
      acc.paid += manifest.paid;
      acc.gross += manifest.paid * manifest.price;
      return acc;
    },
    { qty: 0, price: 0, available: 0, comps: 0, paid: 0, gross: 0 }
  );

  const data =
    event.data?.manifests
      ?.filter((m) => m.active === true)
      .map((manifest) => ({
        manifest: manifest.qty,
        price: manifest.price,
        available: manifest.available,
        comps: manifest.comps,
        paid: manifest.paid,
        gross: manifest.paid * manifest.price,
        ticket_type: manifest.name,
      })) || [];

  console.log("data", Object.assign(totals, { row_type: "footer" }), totals);

  const rows = [...data, Object.assign(totals, { row_type: "footer" })];

  console.log("resulting rows", rows);

  return (
    <MultiLineTable
      headers={headers}
      rows={rows}
      onInputChange={(accessor, value, rowIndex) =>
        handleChangeInput(
          accessor,
          value,
          event.data.manifests[rowIndex],
          event.data.id
        )
      }
    />
  );
};

const EventInfoTable = ({ id }) => {
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const settlement = useQuery(["event-settlement-info", id], () =>
    getEventSettlementInfo(id)
  );

  if (event.isLoading || settlement.isLoading)
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (event.isError || settlement.isError) return <div>Error Loading Data</div>;

  let headlinerNames = settlement.data.headliners
    .map((he) => he.name)
    .join(", ");
  let supportingNames = settlement.data.supporting_acts
    .map((he) => he.name)
    .join(", ");

  return (
    <EditableTable
      rows={[
        { header: "HEADLINER", value: headlinerNames, editable: false },
        { header: "SUPPORT", value: supportingNames, editable: false },
        {
          header: "DATE",
          value: dayjs(event.data.date).format("dddd MMM D, YYYY"),
          editable: false,
        },
        { header: "VENUE", value: event.data.venue.name, editable: false },
        { header: "BUYER", value: settlement.data.buyer.name, editable: false },
        {
          header: "PROMOTER SETTLE",
          value: settlement.data.promoter_settle?.name,
          editable: false,
        },
        {
          header: "ARTIST SETTLE",
          value: settlement.data.artist_settle?.name,
          editable: false,
        },
      ]}
    />
  );
};

const ExpenseMultilineTable = ({ id }) => {
  const expenses = useQuery(
    ["expenses", "artist", id],
    () => getArtistEventExpenses(id),
    getEventExpensesSettings
  );
  const groups = useQuery(
    ["expense-groups"],
    () => getExpenseGroups(),
    getExpenseGroupSettings
  );
  const rollups = useQuery(["event-rollups", "artist", id], () =>
    getEventRollups(id)
  );
  const event = useQuery(["event-detail", id], () => getEvent(id));

  if (
    expenses.isLoading ||
    groups.isLoading ||
    rollups.isLoading ||
    event.isLoading
  )
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (expenses.isError || groups.isError || rollups.isError || event.isError)
    return <div>Error</div>;

  console.log("groups", event.data);

  const headers = [
    { title: "EXPENSE", accessor: "expense", editable: false },
    { title: "CASH PAYOUT", accessor: "cash_payout", editable: false },
    { title: "CHECK PAYOUT", accessor: "check_payout", editable: false },
    { title: "OFFICE PAYOUT", accesor: "office_payout", editable: false },
    { title: "BUILDING PAYOUT", accessor: "building_payout", editable: false },
    { title: "COPRO PAYOUT", accessor: "copro_payout", editable: false },
    { title: "TOTAL", format: "money", accessor: "total", editable: false },
  ];

  const rows = [
    ...groups.data
      .map((group) => {
        if (group.name === "Artist") return [];
        return [
          {
            row_type: "title",
            expense: group.name,
            total: 0,
            cash_payout: "",
            check_payout: "",
            office_payout: "",
            building_payout: "",
            copro_payout: "",
          },
          ...group.subgroups.map((subgroup) => {
            return {
              expense: subgroup.name,
              cash_payout: "",
              check_payout: "",
              office_payout: "",
              building_payout: "",
              copro_payout: "",
              total: 0,
            };
          }),
        ];
      })
      .flat(),
    ...[
      {
        expense: "Variable Expenses",
        cash_payout: "",
        check_payout: "",
        office_payout: "",
        building_payout: "",
        copro_payout: "",
        total: rollups.data.variable_expenses,
        row_type: "title",
      },
      {
        expense: "Credit Cards",
        cash_payout: "",
        check_payout: "",
        office_payout: event.data.credit_card_fees,
        building_payout: "",
        copro_payout: "",
        total: event.data.credit_card_fees,
      },
      {
        expense: "Ticket Comission",
        cash_payout: "",
        check_payout: "",
        office_payout: event.data.ticket_commission,
        building_payout: "",
        copro_payout: "",
        total: event.data.ticket_commission,
      },
      {
        expense: "ASCAMP",
        cash_payout: "",
        check_payout: "",
        office_payout: rollups.data.ascap_total,
        building_payout: "",
        copro_payout: "",
        total: rollups.data.ascap_total,
      },
      {
        expense: "BMI",
        cash_payout: "",
        check_payout: "",
        office_payout: rollups.data.bmi_total,
        building_payout: "",
        copro_payout: "",
        total: rollups.data.bmi_total,
      },
      {
        expense: "SESAC",
        cash_payout: "",
        check_payout: "",
        office_payout: rollups.data.sesac_total,
        building_payout: "",
        copro_payout: "",
        total: rollups.data.sesac_total,
      },
      {
        expense: "Insurance",
        cash_payout: "",
        check_payout: "",
        office_payout: event.data.insurance_amount,
        building_payout: "",
        copro_payout: "",
        total: event.data.insurance_amount,
      },
    ],
  ];

  console.log("rows", rows);

  return <MultiLineTable rows={rows} headers={headers} />;
};

const FinalFiguresTable = ({ id }) => {
  const rollups = useQuery(["event-rollups", "artist", id], () =>
    getEventRollups(id)
  );
  const event = useQuery(["event-detail", id], () => getEvent(id));

  if (rollups.isLoading || event.isLoading)
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (rollups.isError || event.isError) return <div>Error</div>;

  return (
    <EditableTable
      rows={[
        { header: "TOTAL SHOW EXPENSE", value: "", highlight: true },
        {
          header: "Expenses",
          value: amountDisplay(rollups.data.total_expenses),
        },
        {
          header: "Variables",
          value: amountDisplay(rollups.data.variable_expenses),
        },
        {
          header: "Total Expenses",
          value: amountDisplay(
            rollups.data.total_expenses + rollups.data.variable_expenses
          ),
        },
        {
          header: "Net Expenses",
          value: amountDisplay(rollups.data.net_expenses),
        },
        { header: "ATTENDANCE", value: "", highlight: true },
        { header: "Paid Attendance", value: rollups.data.paid_tickets },
        { header: "Comps", value: rollups.data.comps },
        { header: "Total Attendance", value: rollups.data.total_attendance },
        { header: "Capacity", value: rollups.data.capacity },
        { header: "Drop Count", value: rollups.data.drop_count },
        { header: "FINAL FIGURES", value: "", highlight: true },
        {
          header: "Gross",
          value: amountDisplay(rollups.data.tickets_gross),
          bold: true,
        },
        {
          header: "Less Facility Fee",
          value: amountDisplay(rollups.data.total_facility_fee),
        },
        {
          header: "Subtotal",
          value: amountDisplay(
            rollups.data.tickets_gross - rollups.data.total_facility_fee
          ),
          bold: true,
        },
        {
          header: "Less Charity",
          value: amountDisplay(rollups.data.charity, true),
        },
        { header: "Less VIP", value: amountDisplay(event.data.vip_lift, true) },
        {
          header: "Plus Platinum",
          value: amountDisplay(rollups.data.platinum_lift),
        },
        {
          header: "Adjusted Gross",
          value: amountDisplay(rollups.data.adjusted_gross),
          bold: true,
        },
        { header: "Tax Percent", value: `${rollups.data.tax_percent} %` },
        { header: "Total Tax", value: amountDisplay(rollups.data.tax_amount) },
        {
          header: "NET",
          value: amountDisplay(rollups.data.net_income),
          bold: true,
        },
        {
          header: "Expenses - Artist",
          value: amountDisplay(rollups.data.net_expenses),
        },
        {
          header: "Amount to Split",
          value: amountDisplay(
            rollups.data.net_income - rollups.data.net_expenses
          ),
        },
        { header: "% Overages Due", value: `${event.data.offer_percentage}%` },
        { header: "Overages VS", value: amountDisplay(rollups.data.vs_amount) },
        {
          header: "Guarantee",
          value: amountDisplay(rollups.data.artist_guarantee),
        },
        {
          header: "Overages To Artist",
          value: amountDisplay(rollups.data.overages_to_artist),
        },
        {
          header: "Payment",
          value: amountDisplay(rollups.data.artist_payment),
          bold: true,
        },
      ]}
    />
  );
};

const ArtistSettlementTable = ({ id }) => {
  const rollups = useQuery(["event-rollups", "artist", id], () =>
    getEventRollups(id)
  );

  const rows = [
    { header: "ARTIST SETTLEMENT", highlight: true },
    {
      header: "Guarantee",
      value: amountDisplay(rollups.data.artist_guarantee),
    },
    {
      header: "Plus Percentage",
      value: amountDisplay(rollups.data.artist_production),
    },
    {
      header: "Plus Artist Prod",
      value: amountDisplay(rollups.data.artist_production),
    },
    {
      header: "Plus Support",
      value: amountDisplay(rollups.data.artist_support),
    },
    {
      header: "Plus Travel Buyout",
      value: amountDisplay(rollups.data.artist_travel_buyout),
    },
    {
      header: "Less Deposit",
      value: amountDisplay(rollups.data.artist_deposit),
    },
    {
      header: "Less State Withholding",
      value: amountDisplay(rollups.data.artist_withholding),
    },
    {
      header: "Total Due Artist",
      value: amountDisplay(rollups.data.artist_due),
    },
  ];

  return <EditableTable rows={rows} />;
};

const PromoterSettlementTable = ({ id }) => {
  const rollups = useQuery(["event-rollups", "artist", id], () =>
    getEventRollups(id)
  );

  if (rollups.isLoading)
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (rollups.isError) return <div>Error loading data</div>;

  const rows = [
    { header: "PROMOTER SETTLEMENT", highlight: true },
    { header: "Profit/Loss", value: amountDisplay(rollups.data.profit_loss) },
    { header: "Total Revenue", value: amountDisplay(rollups.data.profit_loss) },
  ];

  return <EditableTable rows={rows} />;
};

export const EventEditSettlement = () => {
  const { id } = useParams();
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const settlement = useQuery(["event-settlement-info", id], () =>
    getEventSettlementInfo(id)
  );
  const expenses = useQuery(
    ["expenses", "artist", id],
    () => getArtistEventExpenses(id),
    getEventExpensesSettings
  );
  const groups = useQuery(
    ["expense-groups"],
    () => getExpenseGroups(),
    getExpenseGroupSettings
  );
  const rollups = useQuery(["event-rollups", "artist", id], () =>
    getEventRollups(id)
  );

  console.log("id, data", id, event);

  if (
    event.isLoading ||
    settlement.isLoading ||
    expenses.isLoading ||
    groups.isLoading ||
    rollups.isLoading
  ) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  if (
    event.isError ||
    settlement.isError ||
    expenses.isError ||
    groups.isError ||
    rollups.isError
  ) {
    return <div>Error</div>;
  }

  return (
    <div className="flex flex-col min-w-[1500px] p-5">
      <div className="fixed top-0 left-0 right-0">
        <Header />
      </div>
      <div className="mt-[180px]">
        <BreadCrumbs
          links={[
            { text: "Events", url: "/events" },
            {
              text: `Edit: ${event.data.name}`,
              url: `/events/edit/${event.data.id}`,
            },
            { text: "Settlement" },
          ]}
        />
      </div>
      <div className="flex flex-row w-full mt-8">
        <div className="w-3/4">
          <TicketingTable id={id} handleChangeInput={() => {}} />
        </div>
        <div className="w-1/4">
          <EventInfoTable id={id} />
        </div>
      </div>
      <div className="flex flex-row mt-2">
        <div className="w-3/4">
          <ExpenseMultilineTable id={id} />
        </div>
        <div className="w-1/4">
          <FinalFiguresTable id={id} />
          <div className="mt-2">
            <ArtistSettlementTable id={id} />
          </div>
          <div className="mt-2">
            <PromoterSettlementTable id={id} />
          </div>
        </div>
      </div>
    </div>
  );
};
