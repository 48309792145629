import React, { useEffect, useState } from "react";
import { usePerms } from "@/components/Auth";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import { getLinks, getLinkSettings, deleteLink } from "@/queries/links";
import LinkDisplay from "@/components/Links/LinkDisplay";
import { usePageTitle } from "@/utils/pagetitle";

const LinkRow = (props) => {
  const { link } = props;
  const navigate = useNavigate();

  let linkType = "Event";
  if (link.type === "artist") {
    linkType = "Artist";
  }
  if (link.type === "Venue") {
    linkType = "Venue";
  }

  return (
    <tr
      className="bg-cave-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      onClick={() => navigate(`/links/${link.id}`)}
    >
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-cave-white"
      >
        {link.name}
      </th>
      <td className="px-6 py-4">{link.description}</td>
      <td className="px-6 py-4">
        <a
          href={link.url}
          target="_blank"
          className="text-blue-400 hover:text-blue-600 underline"
        >
          {link.code}
        </a>
      </td>
      <td className="px-6 py-4">{link.type}</td>
    </tr>
  );
};

export const LinksHome = () => {
  const links = useQuery(["links"], getLinks, getLinkSettings);
  const navigate = useNavigate();
  const { perms } = usePerms();
  usePageTitle(
    links.isSuccess
      ? `/c${String.fromCharCode(92)}links`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  if (links.isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  return (
    <>
      <Header />

      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <div className="w-full flex justify-between items-center">
          <BreadCrumbs links={[{ text: "TicketCount Links", url: "/links" }]} />
          <span>
            <Button onClick={() => navigate("/links/add")}>Add Link</Button>
          </span>
        </div>
        <div className="my-2 w-full overflow-x-auto shadow-md sm:rounded-lg">
          <LinkDisplay
            links={links}
            addURL={`/link/${links.link}`}
            deleteQuery={deleteLink}
            editable={perms.data.edit_links}
          />
        </div>
      </div>
    </>
  );
};
