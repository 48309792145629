import React, { useEffect, useState, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Select from "react-select";
import { stateChoices } from "@/utils/states.js";
import { getEventState, postEventState } from "@/queries/events.js";

const buttonColor = {
  confirmed: "bg-green-500 hover:bg-green-600",
  offer: "bg-orange-500 hover:bg-orange-600",
  draft: "bg-blue-500 hover:bg-blue-600",
};

export const StateSwitcher = ({ eventId }) => {
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    data: currentStateData,
    isLoading,
    isError,
  } = useQuery(["eventState", eventId], () => getEventState(eventId));

  const mutation = useMutation(
    (newState) => postEventState(eventId, { state: newState }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["eventState", eventId]);
        setErrorMessage(null);
      },
      onError: (error) => {
        console.error("Mutation error:", error);
        if (error.response?.data?.message === "TransitionNotAllowed") {
          setErrorMessage("Transition Not Allowed");
        } else {
          setErrorMessage("Transition Not Allowed");
        }
      },
    }
  );

  const handleStateChange = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      mutation.mutate(selectedOption.value);
    }
  };

  const currentState = currentStateData?.state;
  const currentStateOption = stateChoices.find(
    (choice) => choice.value === currentState
  );

  const dropdownOptions = useMemo(
    () => stateChoices.filter((choice) => choice.value !== currentState),
    [currentState, stateChoices]
  );

  const getButtonColor = (state) => {
    return buttonColor[state?.toLowerCase()] || "bg-gray-500 hover:bg-gray-600";
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? getButtonColor(state.value) : "white",
      color: state.isSelected ? "white" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
  };

  if (isLoading)
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (isError) return <div>Error fetching event state</div>;

  return (
    <div className="w-48 z-[10001]">
      <Select
        value={currentStateOption || null}
        onChange={handleStateChange}
        options={dropdownOptions}
        isDisabled={mutation.isLoading}
        styles={customStyles}
        className={`rounded  z-[100] px-4 py-2 text-cave-white font-bold ${getButtonColor(
          currentState
        )}`}
        classNamePrefix="react-select"
      />
      {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}
    </div>
  );
};
