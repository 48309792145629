import React, { useState } from "react";
import classNames from "classnames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import styles from "@/views/events/EventDetail.module.css";
import { TableProps } from "@/types/advanceSheet";

export const ItemTable: React.FC<TableProps> = ({
  rows = [],
  header,
  info,
  columns = [],
  color = "gray",
  highlightedColumn,
  onUpdate,
  tableId,
  showTable,
  columnWidths = ["200px", "auto"],
  showChevrons = true,
  isEditable = true,
}) => {
  const [editableRows, setEditableRows] = useState(rows);

  const thClasses = classNames(
    "cursor-pointer text-center font-bold text-cave-white text-md font-inter",
    { "bg-[#4E6B85] hover:bg-[#3A4F61]": color === "gray" },
    { "bg-red-500 hover:bg-red-700": color === "red" }
  );

  const handleBlur = (
    accessor: string,
    e: React.FocusEvent<HTMLTableDataCellElement>,
    row: any,
    header?: { value: string }
  ) => {
    if (row.editable && onUpdate) {
      const newValue = e.target.textContent?.trim() || "";
      if (newValue !== row.value) {
        onUpdate(header?.value || "", row.header.toLowerCase(), newValue);
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLTableDataCellElement>,
    index: number,
    accessor: string,
    row: any,
    header?: { value: string }
  ) => {
    if (row.editable && onUpdate && (e.key === "Enter" || e.key === "Tab")) {
      e.preventDefault();
      const newValue = e.currentTarget.textContent?.trim() || "";
      if (newValue !== row.value) {
        onUpdate(header?.value || "", row.header.toLowerCase(), newValue);
      }
      if (e.key === "Tab") {
        const nextCell =
          e.currentTarget.parentElement?.nextElementSibling?.querySelector(
            '[contenteditable="true"]'
          );
        if (nextCell instanceof HTMLElement) {
          nextCell.focus();
        }
      } else {
        e.currentTarget.blur();
      }
    }
  };

  const handleHeaderChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ) => {
    const newRows = [...editableRows];
    newRows[rowIndex].header = event.target.value;
    setEditableRows(newRows);
  };

  return (
    <table
      className="min-w-full rounded-lg overflow-hidden table-fixed"
      style={{ tableLayout: "fixed", width: "100%" }}
    >
      <colgroup>
        {columnWidths.map((width, idx) => (
          <col key={idx} style={{ width }} />
        ))}
      </colgroup>

      <thead>
        {header && (
          <tr className="h-10">
            <th className={thClasses} colSpan={columns.length || 1}>
              <div className="flex justify-center items-center">
                <span>{header.value}</span>
                {showChevrons &&
                  (showTable ? (
                    <ChevronDownIcon className={styles.chevronAccordionDown} />
                  ) : (
                    <ChevronUpIcon className={styles.chevronAccordionUp} />
                  ))}
              </div>
            </th>
          </tr>
        )}
        {showTable && columns.length > 0 && (
          <tr className="bg-[#f3836f]">
            {columns.map((col, idx) => (
              <th
                key={idx}
                className={`border-r border-black text-center dark:text-cave-white uppercase ${styles.heavyFont}`}
              >
                {col.header}
              </th>
            ))}
          </tr>
        )}
      </thead>
      {showTable && (
        <tbody className="w-full">
          {info && (
            <tr className="w-full h-[240px]">
              <td
                colSpan={columns.length || 1}
                className="bg-cave-white border-b border-[black] text-center text-sm font-inter whitespace-pre-line fton-[900] text-[1.2rem] leading-[1.5]"
              >
                {info}
              </td>
            </tr>
          )}
          {editableRows.length > 0 ? (
            editableRows.map((row, index) => (
              <tr
                className={`${
                  index % 2 !== 0 ? "bg-gray-100" : "bg-cave-white"
                } h-12`}
                key={index}
              >
                {columns.map((col, colIdx) => (
                  <td
                    key={colIdx}
                    className={`border-r border-black p-1 px-3 text-sm font-inter ${
                      colIdx === 0
                        ? "text-black font-[900]"
                        : "text-black font-[400]"
                    }`}
                    contentEditable={colIdx !== 0 && row.editable && isEditable}
                    suppressContentEditableWarning={true}
                    onBlur={(e) => handleBlur(col.accessor, e, row, header)}
                    onKeyDown={(e) =>
                      handleKeyDown(e, index, col.accessor, row, header)
                    }
                    dangerouslySetInnerHTML={
                      colIdx !== 0 && row[col.accessor]
                        ? { __html: row[col.accessor] }
                        : undefined
                    }
                  >
                    {col.accessor === "header" && row.isEditableHeader ? (
                      <input
                        type="text"
                        value={row.header}
                        onChange={(e) => handleHeaderChange(e, index)}
                        className="bg-transparent border-none p-0 m-0 text-center text-black font-[900]"
                        style={{
                          width: "100%",
                          outline: "none",
                          textAlign: "left",
                          textTransform: "uppercase",
                        }}
                      />
                    ) : col.accessor === "header" ? (
                      row[col.accessor]
                    ) : undefined}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length || 1}
                className="bg-cave-white border-b border-black text-center text-sm font-normal whitespace-pre-line h-12"
              >
                NO DATA
              </td>
            </tr>
          )}
        </tbody>
      )}
    </table>
  );
};

export default ItemTable;
