import React, {
  useState,
  useEffect,
  useRef,
  KeyboardEvent,
  useCallback,
  useMemo,
} from "react";
import {
  useDrag,
  useDrop,
  DragSourceMonitor,
  DropTargetMonitor,
} from "react-dnd";
import { Bars3Icon as MenuIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import MoneyInputComponent from "./Common/MoneyInput";
import { amountDisplay } from "@/utils/money";
import { debounce } from "@/helpers";

export type AlignOption = "start" | "center" | "end" | "left" | "right";

export interface Column {
  title?: string;
  accessor: string;
  key?: string;
  textAlign?: AlignOption;
  className?: string;
  editable?: boolean | ((row: any, col: Column) => boolean);
  render?: (data: any, row: Record<string, any>) => JSX.Element;
  onBlur?: (value: any, rowIndex: number, row: any, colKey?: string) => void;
  format?: "money" | "input" | "select" | "checkbox" | "actions";
  options?: { label: string; value: any }[]; // For select options
  width?: string; // e.g., "50px", "20%"
  placeholder?: string; // For configurable placeholders
}

interface CustomRow {
  render: () => JSX.Element;
}

interface TableProps {
  className?: string;
  textCenter?: boolean;
  tableTitle?: string;
  columns: Column[];
  data: Record<string, any>[];
  totalsRow?: {
    title: string;
    textAlign?: AlignOption;
    values: (string | number)[];
  };
  customRows?: CustomRow[];
  hideHeaders?: boolean;
  onChange?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onCellBlur?: (
    rowIndex: number,
    accessor: string,
    value: any,
    row: any
  ) => void;
  showNoDataMessage?: boolean;
  orderable?: boolean; // Enable row reordering
  reorderablePosition?: "first" | "last"; // Position of the reorder column
  onReorder?: (newData: Record<string, any>[]) => void;
  onDelete?: (rowIndex: number, rowData: Record<string, any>) => void; // Callback for deletion
  headerClassName?: string;
  rowClassName?: string | ((rowIndex: number) => string);
  rowContainerClass?: string | ((rowIndex: number) => string);
  inputClassName?: string | ((rowIndex: number) => string);
  unstyled?: boolean;
  enableAddRow?: boolean; // Enable add row functionality
  onAddRow?: (newRow: Record<string, any>) => void; // Callback when a new row is added
  addRowTitle?: string; // Configurable title for adding a new row
  addRowButtonTitle?: string; // Configurable title for the add row button
}

interface TableRowProps {
  columns: Column[];
  rowData: Record<string, any>;
  rowIndex: number;
  moveRow?: (dragIndex: number, hoverIndex: number) => void;
  onChange?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onBlur?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onDelete?: (rowIndex: number, rowData: Record<string, any>) => void; // Pass down the onDelete prop
  orderable?: boolean;
  tableId?: string; // Unique ID of the table
  className?: string;
  rowContainerClass?: string;
  inputClassName?: string;
  unstyled?: boolean;
  onDragEnd?: () => void; // New prop to handle drag end
}

/**
 * Item Types for react-dnd
 */
const ItemTypes = {
  ROW: "row",
};

/**
 * Helper function to map textAlign to Tailwind CSS classes
 */
const textAlignClass = (align: AlignOption | undefined) => {
  switch (align) {
    case "left":
    case "start":
      return "text-left";
    case "center":
      return "text-center";
    case "right":
    case "end":
      return "text-right";
    default:
      return "text-left";
  }
};

/**
 * TableRow Component with Drag-and-Drop and Delete Functionality
 */
const TableRow: React.FC<TableRowProps> = React.memo(
  ({
    columns,
    rowData,
    rowIndex,
    moveRow,
    onChange,
    onBlur,
    onDelete,
    orderable = false,
    tableId,
    className,
    rowContainerClass,
    inputClassName,
    unstyled = false,
    onDragEnd, // Receive onDragEnd prop
  }) => {
    const ref = useRef<HTMLDivElement>(null);

    // Drag and Drop Logic
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.ROW,
      item: { index: rowIndex },
      canDrag: orderable,
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (monitor.didDrop()) {
          onDragEnd && onDragEnd();
        }
      },
    });

    const [{ isOver }, drop] = useDrop({
      accept: ItemTypes.ROW,
      hover: (item: { index: number }, monitor: DropTargetMonitor) => {
        if (!ref.current || item.index === rowIndex) {
          return;
        }
        moveRow && moveRow(item.index, rowIndex);
        item.index = rowIndex; // Update the dragged item's index to avoid redundant moves
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
      }),
    });

    drag(drop(ref));

    const [localRowData, setLocalRowData] = useState(() => {
      // Initialize localRowData with default values
      const initialData = { ...rowData };
      columns.forEach((col) => {
        if (initialData[col.accessor] === undefined) {
          if (col.format === "money" && col.editable) {
            initialData[col.accessor] = 0;
          } else if (col.format === "checkbox") {
            initialData[col.accessor] = false;
          } else {
            initialData[col.accessor] = "";
          }
        }
      });
      return initialData;
    });
    const [isDeleting, setIsDeleting] = useState(false); // State to manage delete prompt

    useEffect(() => {
      if (rowIndex !== -1) {
        setLocalRowData((prevData) => ({ ...prevData, ...rowData }));
      }
    }, [rowData, rowIndex]);

    const handleInputChange = useCallback(
      (field: string, value: any) => {
        const updatedData = { ...localRowData, [field]: value };
        setLocalRowData(updatedData);
        if (onChange) {
          onChange(rowIndex, field, value, updatedData);
        }
      },
      [localRowData, onChange, rowIndex]
    );

    const handleInputBlur = useCallback(
      (col: Column, field: string, value: any) => {
        if (col.onBlur) {
          col.onBlur(value, rowIndex, localRowData, field);
        }

        if (onBlur) {
          onBlur(rowIndex, field, value, localRowData);
        }
      },
      [onBlur, rowIndex, localRowData]
    );

    /**
     * Handle Key Down Events for Each Cell
     */
    const handleKeyDown = useCallback(
      (
        e: KeyboardEvent<HTMLDivElement>,
        currentRowIndex: number,
        currentColIndex: number
      ) => {
        const activeElement = document.activeElement;
        if (
          activeElement &&
          (activeElement.tagName === "INPUT" ||
            activeElement.tagName === "TEXTAREA" ||
            activeElement.tagName === "SELECT" ||
            activeElement.getAttribute("contenteditable") === "true")
        ) {
          return;
        }

        const totalRows = document.querySelectorAll(
          `#${tableId} [data-row-index]`
        ).length;
        const totalCols = columns.length;

        let nextElement: HTMLElement | null = null;
        if (e.shiftKey && orderable) {
          if (e.key === "ArrowUp" && currentRowIndex > 0) {
            e.preventDefault();
            moveRow && moveRow(currentRowIndex, currentRowIndex - 1);
            return;
          } else if (e.key === "ArrowDown" && currentRowIndex < totalRows - 1) {
            e.preventDefault();
            moveRow && moveRow(currentRowIndex, currentRowIndex + 1);
            return;
          }
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          const prevRowIndex = currentRowIndex - 1;
          if (prevRowIndex >= 0) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${prevRowIndex}'] [data-col-index='${currentColIndex}']`
            ) as HTMLElement;
          }
        } else if (e.key === "ArrowDown") {
          e.preventDefault();
          const nextRowIndex = currentRowIndex + 1;
          if (nextRowIndex < totalRows) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${nextRowIndex}'] [data-col-index='${currentColIndex}']`
            ) as HTMLElement;
          }
        } else if (e.key === "ArrowLeft") {
          e.preventDefault();
          const prevColIndex = currentColIndex - 1;
          if (prevColIndex >= 0) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${currentRowIndex}'] [data-col-index='${prevColIndex}']`
            ) as HTMLElement;
          }
        } else if (e.key === "ArrowRight") {
          e.preventDefault();
          const nextColIndex = currentColIndex + 1;
          if (nextColIndex < totalCols) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${currentRowIndex}'] [data-col-index='${nextColIndex}']`
            ) as HTMLElement;
          }
        } else if (isPrintableKey(e) && columns[currentColIndex].editable) {
          // Focus the input field when typing on an editable cell
          e.preventDefault();
          const inputElement = document.querySelector(
            `#${tableId}-row-${currentRowIndex} [data-input-index='${currentColIndex}']`
          ) as HTMLInputElement | HTMLSelectElement;
          if (inputElement) {
            inputElement.focus();
            // Append the typed character to the input's value
            const char = e.key;
            if (
              inputElement instanceof HTMLInputElement ||
              inputElement instanceof HTMLSelectElement
            ) {
              const { selectionStart, selectionEnd } = inputElement;
              const value = inputElement.value;
              const newValue =
                value.substring(0, selectionStart || 0) +
                char +
                value.substring(selectionEnd || 0);
              handleInputChange(columns[currentColIndex].accessor, newValue);
              // Set cursor position after the inserted character
              setTimeout(() => {
                inputElement.setSelectionRange(
                  (selectionStart || 0) + 1,
                  (selectionStart || 0) + 1
                );
              }, 0);
            }
          }
          return;
        }
        nextElement?.focus();
      },
      [columns, handleInputChange, tableId, orderable, moveRow]
    );

    /**
     * Helper function to check if the key is a printable character
     */
    const isPrintableKey = (e: KeyboardEvent) => {
      return (
        e.key.length === 1 &&
        !e.ctrlKey &&
        !e.metaKey &&
        !e.altKey &&
        !e.key.startsWith("Arrow") &&
        e.key !== "Enter"
      );
    };

    /**
     * Handle Delete Actions
     */
    const handleDelete = useCallback(() => {
      if (onDelete) {
        onDelete(rowIndex, rowData);
      }
      setIsDeleting(false);
    }, [onDelete, rowIndex, rowData]);

    // Determine if the current row is the "Add Row" form
    const isAddRow = rowIndex === -1;

    return (
      <div
        ref={ref}
        className={classNames(
          "grid items-center",
          {
            "opacity-50": isDragging, // Visual feedback during drag
            "bg-gray-100": isOver, // Highlight when another row is dragged over
          },
          className,
          rowContainerClass
        )}
        style={{
          gridTemplateColumns: columns
            .map((col) => col.width || "1fr")
            .join(" "),
        }}
        data-row-index={rowIndex}
        id={`${tableId}-row-${rowIndex}`}
      >
        {columns.map((col, colIndex) => {
          const alignmentClass = textAlignClass(col.textAlign);

          // Handle Reorder Column
          if (col.accessor === "reorder") {
            return (
              <div
                key={`${col.accessor}-${colIndex}`} // Ensure unique key
                className={classNames(
                  col.className,
                  alignmentClass,
                  "flex justify-center"
                )}
                data-col-index={colIndex}
                tabIndex={-1} // Make it non-tabbable
              >
                {orderable && (
                  <MenuIcon
                    className="h-4 w-4 cursor-move text-gray-500"
                    aria-label="Drag to reorder"
                  />
                )}
              </div>
            );
          }

          // Handle Delete Column (Exclude from Add Row)
          if (col.accessor === "delete" && !isAddRow) {
            return (
              <div
                key={`${col.accessor}-${colIndex}`} // Ensure unique key
                className={classNames(
                  col.className,
                  alignmentClass,
                  "flex justify-center"
                )}
                data-col-index={colIndex}
              >
                {!isDeleting ? (
                  <button
                    onClick={() => setIsDeleting(true)}
                    className="bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded"
                    aria-label="Delete Row"
                  >
                    Delete
                  </button>
                ) : (
                  <div className="flex space-x-2">
                    <button
                      onClick={handleDelete}
                      className="bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded"
                      aria-label="Confirm Delete"
                    >
                      Confirm
                    </button>
                    <button
                      onClick={() => setIsDeleting(false)}
                      className="bg-gray-400 hover:bg-gray-600 text-cave-white py-1 px-2 text-xs rounded"
                      aria-label="Cancel Delete"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            );
          }

          // Handle "actions" format
          if (col.format === "actions" && col.render) {
            return (
              <div
                key={`${col.accessor}-${colIndex}`} // Ensure unique key
                className={classNames(
                  col.className,
                  alignmentClass,
                  "flex justify-center"
                )}
                data-col-index={colIndex}
                tabIndex={0}
                onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
              >
                {col.render(rowData[col.accessor], rowData)}
              </div>
            );
          }

          // Handle other formats
          let colContent: JSX.Element;

          if (col.render) {
            colContent = col.render(rowData[col.accessor], rowData);
          } else if (col.editable) {
            switch (col.format) {
              case "money":
                colContent = (
                  <MoneyInputComponent
                    value={localRowData[col.accessor] ?? ""}
                    onChange={(e) =>
                      handleInputChange(col.accessor, e.target.value)
                    }
                    onBlur={(e) =>
                      handleInputBlur(col, col.accessor, e.target.value)
                    }
                    className={classNames(
                      !unstyled && "w-full bg-input-blue text-black",
                      alignmentClass,
                      inputClassName
                    )}
                    placeholder={col.placeholder || ""}
                    data-input-index={colIndex}
                  />
                );
                break;
              case "select":
                if (col.options) {
                  colContent = (
                    <select
                      value={localRowData[col.accessor] ?? ""}
                      onChange={(e) =>
                        handleInputChange(col.accessor, e.target.value)
                      }
                      onBlur={(e) =>
                        handleInputBlur(col, col.accessor, e.target.value)
                      }
                      className={classNames(
                        !unstyled && "w-full bg-input-blue text-black",
                        alignmentClass,
                        inputClassName
                      )}
                      placeholder={col.placeholder || ""}
                      data-input-index={colIndex}
                    >
                      <option value="" disabled>
                        {col.placeholder || "Select..."}
                      </option>
                      {col.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  );
                } else {
                  colContent = (
                    <span className={alignmentClass}>
                      {localRowData[col.accessor]}
                    </span>
                  );
                }
                break;
              case "checkbox":
                colContent = (
                  <input
                    type="checkbox"
                    checked={!!localRowData[col.accessor]}
                    onChange={(e) =>
                      handleInputChange(col.accessor, e.target.checked)
                    }
                    onBlur={(e) =>
                      handleInputBlur(col, col.accessor, e.target.checked)
                    }
                    data-input-index={colIndex}
                    className={classNames(alignmentClass, inputClassName)}
                  />
                );
                break;
              default:
                colContent = (
                  <input
                    type="text"
                    value={localRowData[col.accessor] ?? ""}
                    onChange={(e) =>
                      handleInputChange(col.accessor, e.target.value)
                    }
                    onBlur={(e) =>
                      handleInputBlur(col, col.accessor, e.target.value)
                    }
                    className={classNames(
                      !unstyled && "w-full bg-input-blue text-black",
                      alignmentClass,
                      inputClassName
                    )}
                    placeholder={col.placeholder || ""}
                    data-input-index={colIndex}
                  />
                );
            }
          } else {
            colContent = (
              <span
                className={classNames(alignmentClass, {
                  "opacity-50": isDeleting,
                })}
              >
                {col.format === "money"
                  ? amountDisplay(localRowData[col.accessor])
                  : localRowData[col.accessor]}
              </span>
            );
          }

          return (
            <div
              key={`${col.accessor}-${colIndex}`} // Ensure unique key
              className={classNames(col.className, alignmentClass)}
              data-col-index={colIndex}
              tabIndex={0}
              onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
            >
              {colContent}
            </div>
          );
        })}
      </div>
    );
  }
);

TableRow.displayName = "TableRow";

/**
 * Table Component with Drag-and-Drop and Delete Functionality
 */
const Table: React.FC<TableProps> = ({
  className,
  textCenter,
  tableTitle,
  columns,
  data,
  totalsRow,
  customRows,
  hideHeaders = false,
  onChange,
  onCellBlur,
  showNoDataMessage = true,
  orderable = false,
  reorderablePosition = "first", // Default to "first"
  onReorder,
  onDelete,
  headerClassName,
  rowClassName,
  rowContainerClass,
  inputClassName,
  unstyled,
  enableAddRow = false,
  onAddRow,
  addRowTitle = "",
  addRowButtonTitle,
}) => {
  const [localData, setLocalData] = useState(data);
  const [isAdding, setIsAdding] = useState(false); // Control add row form visibility
  const [addRowData, setAddRowData] = useState<Record<string, any>>({});
  const tableId = useRef(
    `table-${Math.random().toString(36).substr(2, 9)}`
  ).current; // Unique table ID

  // Memoize preparedColumns to avoid unnecessary recalculations
  const preparedColumns = useMemo(() => {
    let updatedColumns = [...columns];
    if (orderable) {
      const reorderColumn = {
        accessor: "reorder",
        title: "",
        width: "40px",
      };
      if (reorderablePosition === "first") {
        updatedColumns = [reorderColumn, ...updatedColumns];
      } else {
        updatedColumns.push(reorderColumn);
      }
    }

    if (onDelete) {
      const deleteColumn = {
        accessor: "delete",
        title: "",
        width: "140px", // Adjust width as needed
      };
      updatedColumns.push(deleteColumn); // Always append delete column at the end
    }

    return updatedColumns;
  }, [columns, orderable, reorderablePosition, onDelete]);

  // Memoize gridTemplateColumns
  const gridTemplateColumns = useMemo(() => {
    return preparedColumns.map((col) => col.width || "1fr").join(" ");
  }, [preparedColumns]);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  // Track previous isAdding state to initialize addRowData only once
  const prevIsAddingRef = useRef<boolean>(false);

  // Initialize addRowData with default values
  useEffect(() => {
    if (isAdding && !prevIsAddingRef.current) {
      const initialAddRowData = preparedColumns.reduce((acc, col) => {
        if (col.accessor !== "reorder" && col.accessor !== "delete") {
          if (col.format === "money" && col.editable) {
            acc[col.accessor] = 0;
          } else if (col.format === "checkbox") {
            acc[col.accessor] = false;
          } else {
            acc[col.accessor] = "";
          }
        }
        return acc;
      }, {} as Record<string, any>);
      setAddRowData(initialAddRowData);
    }
    prevIsAddingRef.current = isAdding;
  }, [isAdding, preparedColumns]);

  const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
    setLocalData((prevData) => {
      const newData = [...prevData];
      const [removed] = newData.splice(dragIndex, 1);
      newData.splice(hoverIndex, 0, removed);
      return newData;
    });
  }, []);

  // Custom debounce implementation with a 300ms delay
  const debouncedOnChange = useMemo(
    () =>
      debounce((rowIndex: number, accessor: string, value: any, row: any) => {
        if (onChange) {
          onChange(rowIndex, accessor, value, row);
        }
      }, 300),
    [onChange]
  );
  const convertToCents = (input: string) => {
    const sanitized = input.replace(/[^\d.-]/g, "");
    const convert = Math.round(parseFloat(sanitized) * 100);
    return isNaN(convert) ? 0 : convert;
  };
  // Function to handle adding a new row
  const handleAddNewRow = useCallback(() => {
    if (onAddRow) {
      // find all money format columns and convert to cents
      const addRowDataCopy = { ...addRowData };
      preparedColumns.forEach((col) => {
        if (col.format === "money") {
          addRowDataCopy[col.accessor] = convertToCents(
            addRowDataCopy[col.accessor]
          );
        }
      });
      onAddRow(addRowDataCopy);
      setIsAdding(false);
      setAddRowData({});
    }
  }, [onAddRow, addRowData]);

  // Define the custom row for adding a new row
  const AddRow: CustomRow = useMemo(
    () => ({
      render: () => (
        <div className="w-full">
          {addRowTitle && (
            <div className="text-left font-bold bg-cave-white p-2">
              {addRowTitle}
            </div>
          )}
          <TableRow
            unstyled
            inputClassName={inputClassName}
            rowContainerClass={rowClassName}
            orderable={false} // Disable reordering for the add row
            onChange={(rowIndex, accessor, value, row) => {
              setAddRowData((prev) => ({
                ...prev,
                [accessor]: value,
              }));
            }}
            columns={preparedColumns.map((col) => {
              if (col.format === "money") {
                if (!col.editable) {
                  return {
                    ...col,
                    placeholder: col.placeholder || "n/a",
                    format: "input",
                  };
                }
              }
              return col;
            })}
            rowData={addRowData}
            rowIndex={-1} // Use -1 or another identifier for add row
          />
          <div className="flex justify-start space-x-2 mt-1.5">
            <button
              onClick={handleAddNewRow}
              className="bg-blue-500 hover:bg-blue-700 text-cave-white px-3 py-1 rounded"
              aria-label="Save New Row"
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsAdding(false);
                setAddRowData({});
              }}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-1.5 py-1 rounded"
              aria-label="Cancel Adding Row"
            >
              Cancel
            </button>
          </div>
        </div>
      ),
    }),
    [
      addRowTitle,
      inputClassName,
      rowClassName,
      preparedColumns,
      addRowData,
      handleAddNewRow,
    ]
  );

  // Manage conditional rendering of custom rows
  const generatedCustomRows: CustomRow[] = useMemo(() => {
    return [
      ...(customRows || []),
      ...(enableAddRow && isAdding ? [AddRow] : []),
    ];
  }, [customRows, enableAddRow, isAdding, AddRow]);

  // Handle cell changes in existing rows
  const handleCellChange = useCallback(
    (
      rowIndex: number,
      accessor: string,
      value: any,
      row: Record<string, any>
    ) => {
      setLocalData((prevData) => {
        const newData = [...prevData];
        newData[rowIndex][accessor] = value;
        return newData;
      });

      debouncedOnChange(rowIndex, accessor, value, row);
    },
    [debouncedOnChange]
  );

  // Handle cell blur in existing rows
  const handleCellBlur = useCallback(
    (
      rowIndex: number,
      accessor: string,
      value: any,
      row: Record<string, any>
    ) => {
      if (onCellBlur) {
        onCellBlur(rowIndex, accessor, value, row);
      }
    },
    [onCellBlur]
  );

  // Handle drag end to trigger onReorder
  const handleDragEnd = useCallback(() => {
    if (onReorder) {
      onReorder(localData);
    }
  }, [onReorder, localData]);

  return (
    <div
      className={classNames(
        "font-montserrat",
        {
          "text-center": textCenter,
        },
        className
      )}
      id={tableId}
    >
      {tableTitle && (
        <h2 className="text-center font-bold text-blue-600 border border-black mb-4">
          {tableTitle}
        </h2>
      )}
      {!hideHeaders && (
        <div
          className={classNames("grid font-bold p-2", headerClassName)}
          style={{
            gridTemplateColumns,
          }}
        >
          {preparedColumns.map((col, colIndex) => {
            const alignmentClass = textAlignClass(col.textAlign);
            // If it's the reorder or delete column, render an empty cell
            if (col.accessor === "reorder" || col.accessor === "delete") {
              return (
                <div
                  key={`${col.accessor}-${colIndex}`} // Ensure unique key
                  className={classNames(
                    col.className,
                    alignmentClass,
                    "flex justify-center"
                  )}
                  data-col-index={colIndex}
                  tabIndex={-1} // Make it non-tabbable
                ></div>
              );
            }

            return (
              <div
                key={`${col.accessor}-${colIndex}`} // Ensure unique key
                className={classNames(
                  col.className,
                  alignmentClass,
                  col.format === "actions" && "flex justify-center"
                )}
                data-col-index={colIndex}
              >
                {col.title}
              </div>
            );
          })}
        </div>
      )}
      <div>
        {localData.length === 0
          ? showNoDataMessage && (
              <span className="inline-block font-medium px-2">
                No Data Found.
              </span>
            )
          : localData.map((row, rowIndex) => (
              <TableRow
                key={row.id || `row-${rowIndex}`} // Prefer unique row.id
                columns={preparedColumns}
                rowData={row}
                rowIndex={rowIndex}
                moveRow={moveRow}
                onChange={handleCellChange}
                onBlur={handleCellBlur}
                onDelete={onDelete} // Pass down the onDelete prop
                orderable={orderable}
                tableId={tableId}
                className={
                  typeof rowClassName === "function"
                    ? rowClassName(rowIndex)
                    : rowClassName
                }
                rowContainerClass={
                  typeof rowContainerClass === "function"
                    ? rowContainerClass(rowIndex)
                    : rowContainerClass
                }
                inputClassName={
                  typeof inputClassName === "function"
                    ? inputClassName(rowIndex)
                    : inputClassName
                }
                unstyled={unstyled}
                onDragEnd={handleDragEnd} // Pass handleDragEnd to TableRow
              />
            ))}
      </div>
      {generatedCustomRows &&
        generatedCustomRows.map((customRow, index) => (
          <div key={`custom-${index}`} className="w-full">
            {customRow.render()}
          </div>
        ))}
      {totalsRow && (
        <div
          className={classNames(
            "grid font-bold p-2 bg-gray-200",
            textAlignClass(totalsRow.textAlign)
          )}
          style={{
            gridTemplateColumns,
          }}
        >
          <span className="font-bold">{totalsRow.title}</span>
          {totalsRow.values.map((value, index) => (
            <span key={index} className="font-bold">
              {value}
            </span>
          ))}
        </div>
      )}
      {enableAddRow && !isAdding && (
        <button
          onClick={() => setIsAdding(true)}
          className="bg-gray-400 text-cave-white py-1 px-2 text-sm rounded mt-1.5"
          aria-label="Add Row"
        >
          {addRowButtonTitle || "Add Row"}
        </button>
      )}
    </div>
  );
};

export { Table, TableRow };
