import React from "react";
import SettlementVerticalTable from "./SettlementVerticalTable";
import SettlementTableTitle from "./SettlementTableTitle";
import { Table, TableRow } from "../AppTable2";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { amountDisplay } from "@/utils/money";
import SettlementArtistPayment from "./SettlementArtistPayment";
import Button from "../Button";
import { toast } from "react-toastify";

// Example usage rendering the artist section with SettlementVerticalTables and Artist Deductions
const SettlementArtistAdditionals: React.FC = () => {
  const {
    variables,
    expensesQuery,
    eventSettlement,
    rollups,
    handleUpdateEventSettlement,
  } = useSettlementComponent({
    enableExpenses: true,
  });
  const [isAddingDeduction, setIsAddingDeduction] = React.useState(false);
  const [deductionForm, setDeductionForm] = React.useState({
    item: "",
    paid_by: "",
    amount: 0,
  });

  if (!variables.data || !eventSettlement.data) return null;

  const reimbursements = expensesQuery.data?.filter(
    (expense) => expense.artist
  );
  const chargebacks = expensesQuery.data?.filter(
    (expense) => expense.artist > 0 && expense.mammoth > 0
  );
  const settlementDeductions = eventSettlement.data.artist.deductions;
  console.log("settlementReimbursements", settlementDeductions);
  const data = {
    artistFeeCalculation: {
      data: [
        {
          key: "Artist_Guarantee",
          label: "Guarantee",
          value: amountDisplay(variables.data?.the_deal_amount ?? 0),
          editable: false,
        },
        {
          key: "Artist_PromoterProfit",
          label: "Promoter Profit",
          value: amountDisplay(variables.data.promoter_profit),
          editable: false,
        },
        {
          key: "Artist_SplitPoint",
          label: "Split Point",
          value: amountDisplay(variables.data.split_point),
          editable: false,
        },
        {
          key: "Artist_Overages",
          label: "Overages",
          value: amountDisplay(rollups.data.overages_to_artist),
          editable: false,
        },
      ],
      total:
        (variables.data?.the_deal_amount || 0) +
        (variables.data.promoter_profit || 0) +
        (variables.data.split_point || 0) +
        (rollups.data.overages_to_artist || 0),
    },
    artistDeductions: {
      headers: [
        {
          label: "Item",
          title: "Item",
          key: "item",
          accessor: "item",
        },
        {
          label: "Paid By",
          title: "Paid By",
          key: "paid_by",
          accessor: "paid_by",
          editable: true,
        },
        {
          label: "Amount",
          title: "Amount",
          key: "amount",
          accessor: "amount",
          editable: true,
          format: "money",
        },
      ],
      data: settlementDeductions ?? [],
    },
    artistReimbursements: {
      data: reimbursements?.map((reimbursement) => ({
        key: reimbursement.id,
        label: reimbursement.subgroup_name,
        value: reimbursement.artist,
        format: "money",
        isEditable: false,
      })),
      total: reimbursements?.reduce(
        (acc, reimbursement) => acc + reimbursement.artist,
        0
      ),
    },
    artistPayments: {
      data: [
        {
          key: "Artist_Guarantee",
          label: "Guarantee",
          value: 125000,
          isEditable: true,
        },
        {
          key: "Artist_Overage",
          label: "Overage",
          value: 0,
          isEditable: false,
        },
        {
          key: "Artist_NetAdjustments",
          label: "Net Adjustments",
          value: 63500,
          isEditable: true,
        },
        {
          key: "Artist_TaxableAmount",
          label: "Taxable Amount",
          value: 188500,
          isEditable: false,
        },
        {
          key: "Artist_TaxState",
          label: "Tax %: State / City",
          value: 0,
          isEditable: true,
        },
        {
          key: "Artist_LessDeductions",
          label: "Less Deductions",
          value: -23850,
          isEditable: true,
        },
      ],
    },
    artistChargebacks: {
      data: chargebacks?.map((chargeback) => ({
        key: chargeback.id,
        label: chargeback.subgroup_name,
        value: chargeback.artist + chargeback.mammoth,
        format: "money",
        isEditable: false,
      })),
      total: chargebacks?.reduce(
        (acc, chargeback) => acc + (chargeback.artist + chargeback.mammoth),
        0
      ),
    },
  };
  const setData = () => ({});
  const handleVerticalBlur = (
    sectionKey: keyof typeof data,
    key: string,
    newValue: string | number
  ) => {
    const updatedData = { ...data };
    updatedData[sectionKey].data = updatedData[sectionKey].data.map((row) =>
      row.key === key ? { ...row, value: newValue } : row
    );
    setData(updatedData);
  };

  const handleArtistDeductionsBlur = (
    index: number,
    field: string,
    newValue: string | number
  ) => {
    const updatedDeductions = [...data.artistDeductions.data];
    updatedDeductions[index][field] = newValue;
    setData({
      ...data,
      artistDeductions: { ...data.artistDeductions, data: updatedDeductions },
    });
  };

  const handleAddDeduction = async () => {
    // const updatedDeductions = [...data.artistDeductions.data];
    // updatedDeductions.push(deductionForm);
    // setData({
    //   ...data,
    //   artistDeductions: { ...data.artistDeductions, data: updatedDeductions },
    // });
    const currDeductions = data.artistDeductions.data ?? [];
    await handleUpdateEventSettlement({
      book: "artist",
      data: [
        ...currDeductions,
        { ...deductionForm, amount: Number(deductionForm.amount) },
      ],
      key: "deductions",
    });
    toast.success("Deduction added successfully");
    setIsAddingDeduction(false);
    setDeductionForm({
      item: "",
      paidBy: "",
      amount: 0,
    });
  };

  return (
    <div className="grid grid-cols-4">
      {/* Artist Fee Calculation as vertical table */}
      <div className="border border-black self-start">
        <SettlementVerticalTable
          title="Artist Fee Calculation"
          data={data.artistFeeCalculation.data}
          onBlur={(key, newValue) =>
            handleVerticalBlur("artistFeeCalculation", key, newValue)
          }
        />
        <TableRow
          columns={[
            {
              key: "name",
              accessor: "name",
              textAlign: "left",
            },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
            },
          ]}
          rowData={{
            name: "COVERAGES",
            value: amountDisplay(data.artistFeeCalculation.total),
          }}
          rowContainerClass="bg-blue-200"
        />
      </div>

      <div className="border border-black self-start">
        {/* Artist Deductions as custom table */}
        <SettlementTableTitle>Artist Deductions</SettlementTableTitle>
        <Table
          columns={data.artistDeductions.headers}
          data={data.artistDeductions.data}
        />
        {isAddingDeduction ? (
          <div>
            <TableRow
              columns={data.artistDeductions.headers.map((col) => ({
                ...col,
                editable: true,
                className: "!border-0",
              }))}
              rowData={[
                {
                  item: deductionForm.item,
                  paid_by: deductionForm.paidBy,
                  amount: deductionForm.amount,
                },
              ]}
              rowContainerClass="!border-0"
              inputClassName="border-dashed rounded-none hover:bg-orange-500 duration-300 ease-in-out"
              onBlur={(field, value) => {
                setDeductionForm({ ...deductionForm, [field]: value });
              }}
            />
            <div className="flex gap-1 items-center mt-2 mb-2">
              <Button onClick={handleAddDeduction} variant="success">
                Save
              </Button>
              <Button
                onClick={() => setIsAddingDeduction(false)}
                variant="danger"
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <Button
            className="mt-2 mb-2"
            onClick={() => setIsAddingDeduction(true)}
          >
            Add Deduction
          </Button>
        )}

        {/* Artist Chargebacks as vertical table */}
        <div className="border border-black w-full">
          <SettlementVerticalTable
            title="Artist Chargebacks"
            valueClass="text-right"
            className="w-full"
            data={data.artistChargebacks.data}
            onBlur={(key, newValue) =>
              handleVerticalBlur("artistChargebacks", key, newValue)
            }
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "NET ADJUSTMENTS",
              value: amountDisplay(data.artistChargebacks.total),
            }}
            rowContainerClass="bg-blue-200"
          />
        </div>
      </div>

      {/* Artist Reimbursements as vertical table */}
      <div className="border border-black self-start">
        <SettlementVerticalTable
          title="Artist Reimbursements"
          valueClass="text-right"
          data={data.artistReimbursements.data}
          onBlur={(key, newValue) =>
            handleVerticalBlur("artistReimbursements", key, newValue)
          }
        />
        <TableRow
          columns={[
            {
              key: "name",
              accessor: "name",
              textAlign: "left",
            },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
              format: "money",
            },
          ]}
          rowData={{
            name: "",
            value: data.artistReimbursements.total,
          }}
          rowContainerClass="bg-blue-200"
        />
      </div>

      {/* Artist Payments as vertical table */}
      <div className="border border-black self-start">
        <SettlementVerticalTable
          title="Artist Payments"
          data={data.artistPayments.data}
          onBlur={(key, newValue) =>
            handleVerticalBlur("artistPayments", key, newValue)
          }
        />
        <TableRow
          columns={[
            {
              key: "name",
              accessor: "name",
              textAlign: "left",
            },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
            },
          ]}
          rowData={{
            name: "TOTAL DUE ARTIST",
            value: "$94949",
          }}
          rowContainerClass="bg-blue-200"
        />
        <SettlementArtistPayment
          checkNumber={{
            value: 220,
            onUpdate: (value: string) => {
              // handleBlur(section.id, "check_number", value);
            },
          }}
          paymentMethod={{
            value: 555,
            onUpdate: (value: string) => {
              // handleBlur(section.id, "payment_method", value);
            },
          }}
          paidThrough={{
            value: 330,
            onUpdate: (value: string) => {
              // handleBlur(section.id, "paid_through", value);
            },
          }}
        />
      </div>
    </div>
  );
};

export default SettlementArtistAdditionals;
