export const NavigatorMenuItem = (props) => {
  const { children, icon, onClick, selected, id } = props;

  return (
    <li
      className="block border-b dark:border-gray-700 lg:inline lg:border-b-0"
      key={`header-${id}`}
    >
      {selected ? (
        <a
          onClick={onClick}
          className="cursor-pointer block py-3 px-4 text-gray-500 dark:text-gray-400 hover:text-primary-600 border-b-2 dark:hover:text-primary-800 border-primary-600 dark:border-primary-500"
        >
          {icon}
          {children}
        </a>
      ) : (
        <a
          onClick={onClick}
          className="cursor-pointer block py-3 px-4 text-gray-500 dark:text-gray-400 hover:text-primary-600 hover:border-b-2 dark:hover:text-primary-500 hover:border-primary-600 dark:hover:border-primary-500"
        >
          {icon}
          {children}
        </a>
      )}
    </li>
  );
};
