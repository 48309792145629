import React, { useState, useEffect } from "react";
import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    toggleVisibility();

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-16 ml-1 left-0 w-6 h-6 flex items-center justify-center text-blue-600 transition-all duration-300 hover:text-blue-800 hover:scale-150 focus:outline-none z-50 bg-cave-blue2 rounded-full shadow-lg"
          aria-label="Scroll to top"
        >
          <ArrowUpCircleIcon className="h-5 w-5" />
        </button>
      )}
    </>
  );
};

export default ScrollToTop;
