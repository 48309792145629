import React, { useState, useEffect } from "react";
import { FormBuilder, Input } from "./Form";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Logo } from "@/components/Header/TopHeader/Logo";
import { register } from "@/queries/auth";

const Register = (props) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [running, setRunning] = useState(false);

  const registerMutation = useMutation({
    mutationFn: (data) => {
      return register(data);
    },
    onMutate: (variables) => {
      setRunning(true);
    },
    onSuccess: (data, variables, context) => {
      setError(false);
      setRunning(false);
      setSuccess(true);
    },
    onError: (error, variables, context) => {
      setError(error.data.message);
      setRunning(false);
      setSuccess(false);
    },
  });

  const onSubmit = (data) => {
    setError(false);
    data.token = token;
    registerMutation.mutate(data);
  };

  const registerSchema = yup.object().shape({
    email: yup.string().email().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    password1: yup.string().required(),
    password2: yup.string().required(),
  });

  let headerText = "Create your new Cave Account";

  if (success) {
    headerText = "Your account has been created";
  }

  return (
    <section className="bg-gray-300 dark:bg-[#181818]">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="mb-6">
          <Logo />
        </div>
        <div className="w-full bg-cave-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-cave-white">
              {headerText}
            </h1>
            {error && <p className="text-sm text-red-600">{error}</p>}
            {!success && (
              <FormBuilder onSubmit={onSubmit} schema={registerSchema}>
                <Input
                  name="email"
                  type="email"
                  placeholder="name@company.com"
                  label="Email"
                  autoFocus
                />
                <Input
                  name="first_name"
                  placeholder="Your first name"
                  label="First Name"
                />
                <Input
                  name="last_name"
                  placeholder="Your last name"
                  label="Last Name"
                />
                <Input
                  name="password1"
                  type="password"
                  placeholder="••••••••"
                  label="Password"
                />
                <Input
                  name="password2"
                  type="password"
                  placeholder="••••••••"
                  label="Confirm Password"
                />
                <button
                  type="submit"
                  disabled={running}
                  className="mt-2 w-full text-cave-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {running ? "Creating..." : "Register"}
                </button>
              </FormBuilder>
            )}
            {success && (
              <div className="my-2">
                <p className="my-2 mb-6">
                  Your account has been created. Please login.
                </p>
                <div className="w-full text-center">
                  <a
                    href="/login"
                    className="mt-2 w-full text-cave-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Login to Cave
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
