import { useEffect, useState } from "react";
import { CompanyLogoAnimation } from "@/components/Animations/CompanyLogoAnimation";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { FormCreatableSelect } from "@/components/Form/CreatableSelect";
import {
  FormBuilder,
  Input,
  TextInput,
  Datepicker,
  FormSelect,
  FormMultiSelect,
  selectOptionToValue,
} from "@/components/Form";
import { companyChoices, stateChoices } from "@/utils/states";
import { createEvent } from "@/queries/events";
import { getVenues, getVenuesSettings, createVenue } from "@/queries/venues";
import {
  getArtists,
  getArtistsSettings,
  createArtist,
} from "@/queries/artists";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";

const Errors = (props) => {
  const { errors, setErrors } = props;
  if (errors.length === 0) {
    return null;
  }
  return (
    <div>
      <div
        className="bg-red-100 border-l-4 border-red-500 text-red-700 px-4 py-3 mb-3"
        role="alert"
      >
        {errors}
      </div>
      <Button onClick={() => setErrors([])}>Try Again</Button>
    </div>
  );
};

export const EventAdd = () => {
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings);
  const artists = useQuery(["artists"], getArtists, getArtistsSettings);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating event");
      console.dir(data);
      data.seating_chart_id = null;

      // If we don't have a date, set it to be null
      if (data.date === "") {
        data.date = null;
      }

      return createEvent(data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["events"]);
      navigate(`/events/edit/offer/${data.id}`);
    },
  });

  const addSchema = yup.object().shape({
    name: yup.string().required(),
    date: yup.string(),
    description: yup.string(),
  });

  const handleSubmit = (data) => {
    console.log("In submit");
    console.dir(data);
    data = selectOptionToValue("company", data);
    data.artists = data.artists
      .filter((artist) => artist)
      .map((artist) => artist.value);

    data = selectOptionToValue("venue_id", data);
    data = selectOptionToValue("state", data);
    data.state = "draft";
    console.log("After option");
    console.log("data", data);

    // Handle errors
    if (data.date === "" && data.state !== "draft") {
      setErrors([
        "Events can only be in Draft state if they do not have a date",
      ]);
      return;
    } else {
      setErrors([]);
    }

    mutation.mutate(data);
  };

  const [isFadingOut, setIsFadingOut] = useState(false);
  const [useLogoFallback, setUseLogoFallback] = useState(false);

  useEffect(() => {
    // Check if cave_loading3.mp4 exists
    fetch("/static/images/cave_loading3.mp4", { method: "HEAD" })
      .then((res) => {
        if (!res.ok) {
          setUseLogoFallback(true);
        }
      })
      .catch(() => setUseLogoFallback(true));
  }, []);

  useEffect(() => {
    if (!venues.isLoading && !artists.isLoading) {
      const timer = setTimeout(() => {
        setIsFadingOut(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [venues.isLoading, artists.isLoading]);
  const venueOptions =
    venues.data?.map((venue) => {
      return { value: venue.id, label: venue.name };
    }) || [];
  const artistOptions =
    artists.data?.map((artist) => {
      return { value: artist.id, label: artist.name };
    }) || [];

  return (
    <>
      {(venues.isLoading || artists.isLoading) &&
        (useLogoFallback ? (
          <CompanyLogoAnimation
            isLoading={venues.isLoading || artists.isLoading}
            isFadingOut={isFadingOut}
          />
        ) : (
          <video
            autoPlay
            muted
            loop
            className={`fixed top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
              (!venues.isLoading && !artists.isLoading) || isFadingOut
                ? "opacity-0"
                : "opacity-100"
            }`}
          >
            <source src="/static/images/cave_loading3.mp4" type="video/mp4" />
          </video>
        ))}
      <div
        className={`transition-opacity duration-1000 ${
          (venues.isLoading || artists.isLoading) && !isFadingOut
            ? "opacity-0"
            : "opacity-100"
        }`}
      >
        <Header />
        <div
          className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
        >
          <BreadCrumbs
            links={[{ text: "Events", url: "/events" }, { text: "Add Event" }]}
          />

          <h3 className="text-xl font-bold mb-4 dark:text-cave-white">
            Add New Event
          </h3>
          {errors.length > 0 && (
            <Errors errors={errors} setErrors={setErrors} />
          )}
          {!errors.length > 0 && (
            <div className="py-6 min-w-full">
              <FormBuilder
                onSubmit={(data) => handleSubmit(data)}
                schema={addSchema}
              >
                <Input name="name" label="Name" autoFocus={true} />
                <Datepicker
                  name="date"
                  label="Date"
                  className="flex justify-start px-4 py-2 rounded"
                />
                {/* <FormSelect
                name="state"
                label="Status"
                options={stateChoices}
                required={true}
              /> */}
                <FormSelect
                  name="company"
                  label="Company"
                  options={companyChoices}
                  defaultValue={
                    window.location.search.includes("company=touring")
                      ? "mammoth_touring"
                      : window.location.search.includes("company=block-booking")
                      ? "mammoth_bb"
                      : "mammoth"
                  }
                  required={true}
                  data-testid="company-select"
                />

                <FormCreatableSelect
                  name="venue_id"
                  label="Venue"
                  options={venueOptions}
                  required={true}
                  data-testid="venue-select"
                  placeholder="type: Venue Name to select or create new"
                  onCreateOption={async (name) => {
                    try {
                      const newVenue = await createVenue({
                        name,
                        active: true,
                        state: "active",
                      });
                      const newOption = {
                        value: newVenue.id,
                        label: newVenue.name,
                      };
                      queryClient.setQueryData(["venues"], (old) => [
                        ...(old || []),
                        newVenue,
                      ]);
                      return newOption;
                    } catch (error) {
                      console.error("Failed to create venue:", error);
                      return null;
                    }
                  }}
                />
                <FormCreatableSelect
                  name="artists"
                  label="Headliner(s)"
                  options={artistOptions}
                  required={true}
                  isMulti={true}
                  data-testid="artist-select"
                  placeholder="type: Artist Name to select or create new"
                  onCreateOption={async (name) => {
                    try {
                      const newArtist = await createArtist({
                        name,
                        active: true,
                        state: "active",
                      });
                      const newOption = {
                        value: newArtist.id,
                        label: newArtist.name,
                      };
                      queryClient.setQueryData(["artists"], (old) => [
                        ...(old || []),
                        newArtist,
                      ]);
                      return newOption;
                    } catch (error) {
                      console.error("Failed to create artist:", error);
                      return null;
                    }
                  }}
                />
                <TextInput name="description" label="Description" />
                <div className="flex-inline float-right mb-12">
                  <button
                    type="button"
                    onClick={() => navigate(`/events/`)}
                    className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Cancel
                  </button>
                  <Button type="submit">Add Event</Button>
                </div>
              </FormBuilder>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
