import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import { getArtists, getArtistsSettings } from "@/queries/artists";
import { usePageTitle } from "@/utils/pagetitle";
import dayjs from "dayjs";
import { SortedTable } from "@/components/SortedTable/SortedTable";

const FilteredArtistsTable = ({
  artists,
  headers,
  total,
  canEdit,
  title,
  handleEditClick,
}) => {
  const navigate = useNavigate();

  const formatData = (artists) => {
    const filteredArtists = artists.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    return filteredArtists;
  };

  const handleFormatCell = (accessor, rowData, rowIndex) => {
    if (accessor === "on_sale") {
      return rowData["on_sale"]
        ? dayjs(rowData["on_sale"]).format("MMM D, YYYY")
        : "No Date";
    }
  };

  const handleSort = (key, item) => {
    if (key === "total") {
      return item["price"] * item["qty"];
    }
    return item[key];
  };

  return (
    <SortedTable
      total={total}
      data={formatData(artists)}
      headers={headers}
      customSort={handleSort}
      formatCell={handleFormatCell}
      onEditClick={canEdit ? handleEditClick : null}
      handleRowClick={(row) => navigate(`/artists/${row.id}`)}
      hasSorting
      hasFilter
    />
  );
};

export const ArtistsHome = () => {
  const artists = useQuery(["artists"], getArtists, getArtistsSettings);
  const navigate = useNavigate();
  const [isFadingOut, setIsFadingOut] = useState(false);
  const filteredArtists = artists?.data || [];

  usePageTitle(
    artists.isSuccess
      ? `/c${String.fromCharCode(92)}artists`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  useEffect(() => {
    if (artists.isSuccess) {
      const timer = setTimeout(() => {
        setIsFadingOut(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [artists.isSuccess]);

  return (
    <>
      {artists && artists.isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <video
            className="w-full h-full object-cover"
            autoPlay
            muted
            playsInline
            loop
          >
            <source src="/static/images/cave_loading3.mp4" type="video/mp4" />
          </video>
        </div>
      )}
      <div
        className={`transition-opacity duration-1000 ${
          artists.isLoading && !isFadingOut ? "opacity-0" : "opacity-100"
        }`}
      >
        <Header />
        <div className="flex flex-col px-6 py-8 mx-auto lg:py-0">
          <div className="w-full flex justify-between items-center">
            <BreadCrumbs links={[{ text: "Artists", url: "/artists" }]} />
            <span>
              <Button onClick={() => navigate("/artists/add")}>
                Add Artist
              </Button>
            </span>
          </div>

          <div className="my-2 w-full overflow-x-auto shadow-md sm:rounded-lg">
            <FilteredArtistsTable
              artists={filteredArtists}
              headers={[
                { header: "NAME", accessor: "name" },
                { header: "POC NAME", accessor: "poc_name" },
                { header: "POC EMAIL", accessor: "poc_email" },
                { header: "POC PHONE", accessor: "poc_phone" },
                { header: "FUTURE EVENTS", accessor: "future_events" },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
