import React from "react";
import { Modal } from "flowbite-react";
import {
  FormBuilder,
  Input,
  TextInput,
  FormMultiSelect,
} from "@/components/Form";
import Button from "@/components/Button";
import { useQuery } from "@tanstack/react-query";
import { getVenues } from "@/queries/venues";
import { getArtists } from "@/queries/artists";
import * as yup from "yup";
import dayjs from "dayjs";

const HoldModal = ({ showModal, setShowModal, selectedDates, onSubmit }) => {
  const { data: venues } = useQuery(["venues"], getVenues);
  const { data: artists } = useQuery(["artists"], getArtists);
  const venueOptions =
    venues?.map((venue) => ({ value: venue.id, label: venue.name })) || [];
  const artistOptions =
    artists?.map((artist) => ({ value: artist.id, label: artist.name })) || [];

  const addSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    venues: yup.array().of(yup.object()),
    artists: yup.array().of(yup.object()),
  });

  const formatDate = (date) => {
    return date ? dayjs(date).format("MM/DD/YYYY") : "";
  };

  const getDateDisplay = () => {
    if (!selectedDates) return "";
    if (selectedDates.date) {
      // Single day selection
      return ` on ${formatDate(selectedDates.date)}`;
    } else if (selectedDates.start && selectedDates.end) {
      // Multi-day selection
      const startDate = formatDate(selectedDates.start);
      const endDate = formatDate(selectedDates.end);
      return startDate === endDate
        ? `On ${startDate}`
        : ` from ${startDate} to ${endDate}`;
    }
    return "";
  };

  return (
    <Modal dismissible show={showModal} onClose={() => setShowModal(false)}>
      <Modal.Header>
        Create Hold
        {getDateDisplay()}
      </Modal.Header>
      <FormBuilder onSubmit={onSubmit} schema={addSchema}>
        <Modal.Body>
          <Input name="name" label="Name" autoFocus />
          <FormMultiSelect
            name="venues"
            label="Venues"
            options={venueOptions}
          />
          <FormMultiSelect
            name="artists"
            label="Artists"
            options={artistOptions}
          />
          <TextInput name="description" label="Description" />
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Create Hold(s)</Button>
          <Button color="gray" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </FormBuilder>
    </Modal>
  );
};

export default HoldModal;
