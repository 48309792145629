import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from "./base";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const recentVisitsQuerySettings = {
  cacheTime: 15 * 60 * 1000,
  staleTime: 5 * 60 * 1000,
  retry: 3,
};

export const recordVisitAPI = async (data) => {
  const url = "/api/users/record-recent/";
  const newData = {
    item_id: data.itemID,
    item_type: data.itemType,
    url: data.itemURL,
  };
  return await postApiRequest(url, newData);
};

export const getRecentItems = async () => {
  const url = "/api/users/recent-items/";
  const data = await getApiRequest(url);
  
  return data.map(item => {
    // Process default images and ensure all fields are properly formatted
    const processedItem = {
      ...item,
      name: item.title || '',
      subtitle: item.subtitle || '',
      venue_name: item.venue_name || (item.item_type === 'event' ? 'No Venue' : ''),
      event_date: item.event_date ? new Date(item.event_date).toLocaleDateString() : '',
      event_image_url: item.event_image_url || '/static/images/cave_no_image_event.png',
      related_image_url: item.related_image_url || (
        item.item_type === 'artist' ? '/static/images/cave_no_image_artist.png' :
        item.item_type === 'venue' ? '/static/images/cave_no_image_venue.png' :
        item.item_type === 'person' ? '/static/images/cave_no_image_person.png' :
        '/static/images/cave_no_image_event.png'
      )
    };

    // Add type-specific metadata
    switch (item.item_type) {
      case 'event':
        processedItem.event_status = item.event_status || '';
        processedItem.event_date = item.event_date ? 
          new Date(item.event_date).toLocaleDateString() : '';
        processedItem.venue_name = item.venue_name || '';
        processedItem.ticket_info = item.tickets_sold && item.total_tickets ? 
          `${item.tickets_sold}/${item.total_tickets}` : '';
        break;
      case 'artist':
        processedItem.genre = item.genre || '';
        processedItem.upcoming_shows = item.upcoming_events_count || 0;
        processedItem.tags = Array.isArray(item.artist_tags) ? 
          item.artist_tags.slice(0, 2) : [];
        break;
      case 'venue':
        processedItem.location = item.city && item.state ? 
          `${item.city}, ${item.state}` : '';
        processedItem.capacity = item.capacity || '';
        processedItem.amenities = Array.isArray(item.venue_amenities) ? 
          item.venue_amenities.slice(0, 2) : [];
        break;
      case 'person':
        processedItem.role = item.role || '';
        break;
    }

    // Process arrays stored as JSON strings
    try {
      if (typeof item.artist_tags === 'string') {
        processedItem.artist_tags = JSON.parse(item.artist_tags);
      }
      if (typeof item.venue_amenities === 'string') {
        processedItem.venue_amenities = JSON.parse(item.venue_amenities);
      }
      if (typeof item.social_media === 'string') {
        processedItem.social_media = JSON.parse(item.social_media);
      }
      if (typeof item.accessibility === 'string') {
        processedItem.accessibility = JSON.parse(item.accessibility);
      }
    } catch (e) {
      console.error('Error parsing JSON fields:', e);
    }

    // Format currency values
    if (item.gross_potential) {
      processedItem.gross_potential = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(item.gross_potential);
    }
    if (item.net_income) {
      processedItem.net_income = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(item.net_income);
    }

    return processedItem;
  });
};

export const useRecordVisit = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-visit"], {
    mutationFn: (data) => {
      console.log("---> recording visit");
      return recordVisitAPI(data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["recent-visits"]);
    },
  });
  return useMutation(["add-visit"]);
};

export const getRecentVisits = async () => {
  const url = "/api/users/recent-items/";
  const data = await getApiRequest(url);
  
  // Process and validate image URLs
  const processedData = data.map(item => {
    // Ensure we have valid image URLs
    const event_image_url = item.event_image_url && item.event_image_url !== "" 
      ? item.event_image_url 
      : "/static/images/cave_no_image_event.png";
      
    const related_image_url = item.related_image_url && item.related_image_url !== ""
      ? item.related_image_url
      : event_image_url;

    return {
      ...item,
      event_image_url,
      related_image_url
    };
  });

  console.log('Processed recent visits data:', processedData);
  return processedData;
};
