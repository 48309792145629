import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import styles from "./Wires.module.css";

type Row = {
  header: string;
  value: string;
  editable: boolean;
  isEditableHeader?: boolean;
};

type Column = {
  header: string;
  accessor: keyof Row;
};

type WiresTableProps = {
  rows: Row[];
  header?: { value: string };
  info?: string;
  columns: Column[];
  color?: string;
  highlightedColumn?: string;
  onUpdate?: (accessor: keyof Row, value: string) => void;
  tableId?: string;
  showTable: boolean;
  columnWidths?: string[];
  showChevrons?: boolean;
};

export const WiresTable: React.FC<WiresTableProps> = ({
  rows = [],
  header,
  info,
  columns = [],
  color = "gray",
  highlightedColumn,
  onUpdate,
  tableId,
  showTable,
  columnWidths = ["200px", "auto"],
  showChevrons = true,
}) => {
  const [editableRows, setEditableRows] = useState(rows);

  const thClasses = classNames(
    "cursor-pointer text-center font-bold text-cave-white text-md font-inter",
    { "bg-[#4E6B85] hover:bg-[#3A4F61]": color === "gray" },
    { "bg-red-500 hover:bg-red-700": color === "red" }
  );

  const handleBlur = (accessor, e, row) => {
    if (onUpdate) {
      onUpdate(accessor, e.target.textContent || "");
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  const handleHeaderChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ) => {
    const newRows = [...editableRows];
    if (newRows[rowIndex] && "header" in newRows[rowIndex]) {
      newRows[rowIndex].header = event.target.value;
      setEditableRows(newRows);
    }
  };

  const renderSection = (
    sectionTitle: string,
    rows: Row[],
    backgroundColor: string,
    transferColumns: Column[] | null = null,
    isTransfersOnly: boolean = false
  ) => (
    <>
      <tr style={{ backgroundColor }} className="h-12">
        <td
          colSpan={columns.length}
          className="text-center font-bold"
          style={{ color: isTransfersOnly ? "black" : "white" }}
        >
          {sectionTitle}
        </td>
      </tr>
      <tr className="bg-cave-white">
        {(isTransfersOnly ? transferColumns : columns)?.map((col, idx) => (
          <th
            key={idx}
            className={`border-r border-black text-center text-[black] uppercase p-[4px] text-[0.875rem] border-b ${styles.heavyFont}`}
          >
            {col.header}
          </th>
        ))}
      </tr>
      {rows.map((row, index) => (
        <tr
          className={`${
            index % 2 !== 0 ? "bg-gray-100" : "bg-cave-white"
          } h-12`}
          key={index}
        >
          {(isTransfersOnly ? transferColumns : columns)?.map((col, colIdx) => (
            <td
              key={colIdx}
              className={`border-r border-black p-1 px-3 text-sm font-inter ${
                colIdx === 0 ? "text-black font-[900]" : "text-black font-[400]"
              }`}
              contentEditable={colIdx !== 0 && row.editable}
              suppressContentEditableWarning={true}
              onBlur={(e) => handleBlur(col.accessor, e, row)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              style={{
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {col.accessor === "header" && row.isEditableHeader ? (
                <input
                  type="text"
                  value={row.header}
                  onChange={(e) => handleHeaderChange(e, index)}
                  className="bg-transparent border-none p-0 m-0 text-center text-black font-[900]"
                  style={{
                    width: "100%",
                    outline: "none",
                    textAlign: "left",
                    textTransform: "uppercase",
                  }}
                />
              ) : (
                row[col.accessor]
              )}
            </td>
          ))}
        </tr>
      ))}
    </>
  );

  const transferColumns: Column[] = [
    { header: "DATE", accessor: "header" },
    { header: "FROM ACCOUNT", accessor: "value" },
    { header: "TO ACCOUNT", accessor: "value" },
    { header: "AMOUNT", accessor: "value" },
    { header: "MEMO", accessor: "value" },
    { header: "STAFF REQUESTING", accessor: "value" },
  ];

  return (
    <table
      className="min-w-full rounded-lg overflow-hidden table-fixed mt-[1rem]"
      style={{ tableLayout: "fixed", width: "100%" }}
    >
      <colgroup>
        {columns.map((column, idx) => (
          <col key={idx} style={{ width: columnWidths[idx] || "auto" }} />
        ))}
      </colgroup>
      {showTable && (
        <tbody className="w-full">
          {renderSection("MAMMOTH", editableRows.slice(18, 24), "#62A14A")}
          {renderSection("GRIZZLY", editableRows.slice(0, 6), "#D45033")}
          {renderSection("SABERTOOTH", editableRows.slice(6, 12), "#E7721D")}
          {renderSection("ASTRO", editableRows.slice(24, 30), "#B6E1D6")}
          {renderSection("OTHER", editableRows.slice(30, 36), "#8FC0BE")}
          {renderSection(
            "TRANSFERS ONLY",
            editableRows.slice(12, 18),
            "#F3BD3A",
            transferColumns,
            true
          )}
        </tbody>
      )}
      {!showTable && (
        <tbody>
          <tr>
            <td
              colSpan={columns.length || 1}
              className="bg-cave-white border-b border-black text-center text-sm font-normal whitespace-pre-line h-12"
            >
              NO DATA
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export const Wires = () => {
  const [isTableOpen, setIsTableOpen] = useState(false);

  const toggleTable = () => {
    setIsTableOpen(!isTableOpen);
  };

  const rows: Row[] = Array(36).fill({
    header: "",
    value: "",
    editable: false,
  });

  const columns: Column[] = [
    { header: "DATE", accessor: "header" },
    { header: "COMPANY TO SEND WIRE", accessor: "value" },
    { header: "EVENT, NAME, VENUE, CITY, STATE, DATE", accessor: "value" },
    { header: "WIRE AMOUNT", accessor: "value" },
    {
      header: "MEMO: ARTIST PAYMENT, CO-PRO SETTLE, ETC.",
      accessor: "value",
    },
    { header: "STAFF THAT REQUESTED", accessor: "value" },
  ];

  return (
    <div className="wiresWrapper mt-[2rem]">
      <h3 className="text-xl font-semibold dark:text-gray-200 mb-[1.5rem]">
        Wires
      </h3>
      <div
        className={`flex justify-between items-center w-full px-4 py-2 bg-[#8b0093] text-cave-white text-lg font-semibold cursor-pointer rounded-[10px] ${styles.chevronAccordionDiv}`}
        onClick={toggleTable}
      >
        <span className="m-auto">Wire Sheet</span>
        {isTableOpen ? (
          <ChevronDownIcon className={styles.chevronAccordionDown} />
        ) : (
          <ChevronUpIcon className={styles.chevronAccordionUp} />
        )}
      </div>
      {isTableOpen && (
        <WiresTable
          rows={rows}
          columns={columns}
          color="red"
          showTable={isTableOpen}
          columnWidths={["200px", "auto"]}
        />
      )}
    </div>
  );
};
