import React from "react";
import { useUser } from "@/components/Auth";
import { logoutUser } from "@/queries/auth";
import { useQueryClient, useMutation } from "@tanstack/react-query";

export const FTUE = () => {
  const { user } = useUser();
  const queryClient = useQueryClient();
  const backgroundImageUrl = "/static/images/welcome_to_cave.png";

  const mutation = useMutation({
    mutationFn: (id) => {
      console.log("---> logging out FTUE user");

      return logoutUser();
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["me"]);
      window.location.href = "/";
    },
  });

  return (
    <div
      className="flex justify-center items-center h-screen"
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: "cover",
      }}
    >
      <div className="absolute bottom-0 left-0 bg-black p-4 rounded-lg shadow-lg text-left">
        <hr />
        <a
          href="/password/reset/"
          className="text-cave-white hover:text-blue-700"
        >
          Reset your password
        </a>
        <br />
        <hr />
        <a
          onClick={() => mutation.mutate()}
          className="text-cave-white hover:text-blue-700"
        >
          Logout
        </a>
        <hr />
      </div>
    </div>
  );
};

export default FTUE;
