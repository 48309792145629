import { Navigate, Outlet } from "react-router-dom";

export const PublicLayout = () => {
  const { user } = false;

  if (user) {
    return <Navigate to="/dashboard/" />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
};
