import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import LoadingPulse from "@/components/shared/LoadingPulse";
import dayjs from "dayjs";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import YearView from "./YearView";
import DayView from "./DayView";
import WeekView from "./WeekView";
import HoldModal from "./HoldModal";
import {
  getEventsCalendar,
  fetchYearlyEvents,
  createEventHolds,
} from "@/queries/events";
import { EVENT_MAPPING } from "@/components/EventState";
import { usePerms } from "@/components/Auth";
import "./calendar.css";

const CalendarHeader = ({
  currentDate,
  currentView,
  handleNavigation,
  handleViewChange,
}) => {
  const formatDate = () => {
    switch (currentView) {
      case "dayView":
        return currentDate.format("MMM D, YYYY");
      case "yearView":
        return currentDate.format("YYYY");
      default:
        return currentDate.format("MMM YYYY");
    }
  };

  const renderViewButtons = () => {
    const views = ["Day", "Week", "Month", "Year"];
    return views.map((view) => (
      <button
        key={view}
        className={`calendar-view-button ${
          currentView === view.toLowerCase() + "view" ||
          (view === "Month" && currentView === "dayGridMonth")
            ? "calendar-view-button-active"
            : "calendar-view-button-inactive"
        }`}
        onClick={() => handleViewChange(view.toLowerCase() + "view")}
      >
        {view}
      </button>
    ));
  };

  return (
    <div className="flex justify-between items-center mb-4 bg-gray-900 p-4 rounded-lg">
      <div className="flex items-center space-x-4">
        <button
          onClick={() => handleNavigation("prev")}
          className="text-cave-white hover:bg-gray-800 rounded-full p-1"
          aria-label="Previous"
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <span className="text-2xl font-bold text-cave-white font-mono">
          {formatDate()}
        </span>
        <button
          onClick={() => handleNavigation("next")}
          className="text-cave-white hover:bg-gray-800 rounded-full p-1"
          aria-label="Next"
        >
          <ChevronRightIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="flex items-center space-x-2">{renderViewButtons()}</div>
    </div>
  );
};

export const Calendar = () => {
  const [selectedDates, setSelectedDates] = useState(null);
  const [showAddHold, setShowAddHold] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [currentView, setCurrentView] = useState("dayGridMonth");
  const [isYearViewActionLoading, setIsYearViewActionLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const excludedStates = ["offer_sent", "draft"];
  const [activeFilters, setActiveFilters] = useState(
    Object.keys(EVENT_MAPPING).filter(
      (state) => !excludedStates.includes(state)
    )
  );

  const navigate = useNavigate();
  const calendarRef = useRef(null);
  const queryClient = useQueryClient();
  const { perms } = usePerms();

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(currentView);
      setCurrentDate(dayjs(calendarApi.getDate()));
    }
  }, []);

  const monthlyPayload = useMemo(() => {
    // Get the first visible day of the calendar view
    const firstDay = currentDate.startOf("month").startOf("week");
    // Get the last visible day of the calendar view
    const lastDay = currentDate.endOf("month").endOf("week");

    return {
      start_year: firstDay.year(),
      start_month: firstDay.month() + 1,
      start_day: firstDay.date(),
      end_year: lastDay.year(),
      end_month: lastDay.month() + 1,
      end_day: lastDay.date(),
    };
  }, [currentDate]);

  const yearlyPayload = useMemo(
    () => ({
      year: currentDate.year(),
    }),
    [currentDate]
  );

  const {
    data: monthlyEvents,
    isLoading: isMonthlyLoading,
    error: monthlyError,
  } = useQuery(
    ["monthlyEvents", monthlyPayload],
    () => getEventsCalendar(monthlyPayload),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      keepPreviousData: true,
    }
  );

  const {
    data: yearlyEvents,
    isLoading: isYearlyLoading,
    error: yearlyError,
  } = useQuery(
    ["yearlyEvents", yearlyPayload.year],
    () => fetchYearlyEvents(yearlyPayload),
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      keepPreviousData: true,
    }
  );

  const events = useMemo(() => {
    return currentView === "yearview"
      ? yearlyEvents || []
      : monthlyEvents || [];
  }, [currentView, yearlyEvents, monthlyEvents]);

  const handleViewChange = useCallback((view) => {
    const newView = view.toLowerCase().endsWith("view")
      ? view.toLowerCase()
      : `${view.toLowerCase()}View`;

    // Use requestAnimationFrame to defer state updates
    requestAnimationFrame(() => {
      setIsLoading(true);
      setCurrentView(newView);

      if (
        newView !== "yearview" &&
        newView !== "dayview" &&
        calendarRef.current
      ) {
        const calendarApi = calendarRef.current.getApi();
        const fcView =
          newView === "monthview"
            ? "dayGridMonth"
            : newView === "weekview"
            ? "dayGridWeek"
            : newView === "dayview"
            ? "dayGridDay"
            : newView;
        calendarApi.changeView(fcView);
        setCurrentDate(dayjs(calendarApi.getDate()));
      }

      // Use requestAnimationFrame for loading state
      requestAnimationFrame(() => {
        setIsLoading(false);
      });
    });
  }, []);

  const toggleFilter = useCallback((filter) => {
    setIsLoading(true);
    setActiveFilters((prev) => {
      const newFilters = prev.includes(filter)
        ? prev.filter((f) => f !== filter)
        : [...prev, filter];

      setTimeout(() => setIsLoading(false), 500);
      return newFilters;
    });
  }, []);

  const filteredEvents = useMemo(() => {
    return events
      .filter(
        (event) =>
          activeFilters.length === 0 || activeFilters.includes(event.state)
      )
      .map((event) => ({
        ...event,
        backgroundColor: EVENT_MAPPING[event.state]["rgb-background"],
        textColor: EVENT_MAPPING[event.state]["rgb-text"],
      }));
  }, [events, activeFilters]);

  const handleYearViewDateClick = useCallback(
    (date) => {
      setCurrentDate(dayjs(date));
      handleViewChange("dayview");
    },
    [handleViewChange]
  );
  const handleDateSelect = useCallback((selectionInfo) => {
    setSelectedDates({
      start: selectionInfo.start,
      end: selectionInfo.end,
      allDay: selectionInfo.allDay,
    });
  }, []);

  const handleDateClick = useCallback((info) => {
    const clickedDate = dayjs(info.date);
    setSelectedDates({
      date: clickedDate.toDate(),
      dateStr: clickedDate.format("YYYY-MM-DD"),
    });
  }, []);

  const handleShowModal = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (perms.data.create_events && selectedDates) {
        setShowAddHold(true);
      }
    },
    [perms.data.create_events, selectedDates]
  );

  const handleCloseModal = useCallback(() => {
    setShowAddHold(false);
  }, []);

  const createHoldMutation = useMutation({
    mutationFn: (data) => {
      let dates = [];
      if (selectedDates.dateStr) {
        // Single day selection
        dates.push(selectedDates.dateStr);
      } else if (selectedDates.start && selectedDates.end) {
        // Multi-day selection
        let startDate = dayjs(selectedDates.start);
        const endDate = dayjs(selectedDates.end);
        while (startDate.isSame(endDate) || startDate.isBefore(endDate)) {
          dates.push(startDate.format("YYYY-MM-DD"));
          startDate = startDate.add(1, "day");
        }
      } else {
        throw new Error("Invalid date selection");
      }

      const holdData = {
        name: data.name,
        dates: dates,
        venue_ids: data.venues.map((venue) => venue.value),
        artist_ids: data.artists.map((artist) => artist.value),
        description: data.description,
      };
      return createEventHolds(holdData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["monthlyEvents"]);
      queryClient.invalidateQueries(["yearlyEvents"]);
      handleCloseModal();
      setSelectedDates(null);
    },
  });

  const clearSelection = useCallback(() => {
    if (!showAddHold) {
      setSelectedDates(null);
    }
  }, [showAddHold]);

  const renderEvent = (eventInfo) => {
    const event = eventInfo.event;
    const eventName = event.extendedProps.name.slice(0, 15);
    const venueName = event.extendedProps.venue_name
      .replace(/\bthe\b/gi, "")
      .trim()
      .slice(0, 6);
    const buyerName = event.extendedProps.buyer_name;
    return (
      <div
        className="cursor-pointer truncate p-1 text-xs font-bold"
        style={{
          backgroundColor: event.backgroundColor,
          color: event.textColor,
          borderRadius: "4px",
        }}
        title={event.title}
      >
        {`${eventName}@${venueName}`}
      </div>
    );
  };

  const handleDatesSet = (datesSetInfo) => {
    const { start, end, view } = datesSetInfo;
    if (currentView !== "yearView") {
      setSelectedDates({ start, end, view });
      setCurrentDate(dayjs(view.currentStart));
    }
  };

  const [transitionDate, setTransitionDate] = useState(null);

  const handleNavigation = useCallback(
    (direction) => {
      // Calculate new date outside of state updates
      const newDate = (() => {
        switch (currentView) {
          case "yearview":
            return direction === "prev"
              ? currentDate.subtract(1, "year")
              : currentDate.add(1, "year");
          case "dayview":
            return direction === "prev"
              ? currentDate.subtract(1, "day")
              : currentDate.add(1, "day");
          case "weekview":
          case "dayGridWeek":
            return direction === "prev"
              ? currentDate.subtract(1, "week")
              : currentDate.add(1, "week");
          case "monthview":
          case "dayGridMonth":
          default:
            return direction === "prev"
              ? currentDate.subtract(1, "month")
              : currentDate.add(1, "month");
        }
      })();

      // Use requestAnimationFrame to batch state updates
      requestAnimationFrame(() => {
        if (currentView !== "yearview" && calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          direction === "prev" ? calendarApi.prev() : calendarApi.next();
        }

        // Update date after calendar API change
        requestAnimationFrame(() => {
          setCurrentDate(newDate);
        });
      });
    },
    [currentView, currentDate]
  );

  useEffect(() => {
    if (transitionDate) {
      const timer = setTimeout(() => {
        setCurrentDate(transitionDate);
        setTransitionDate(null);
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [transitionDate]);

  const renderFilterButtons = () => (
    <div className="flex space-x-2 justify-end">
      {Object.entries(EVENT_MAPPING)
        .filter(([state]) => !excludedStates.includes(state))
        .map(([state, colors]) => (
          <button
            key={state}
            className={`px-2 py-1 rounded text-xs transition-colors duration-200`}
            style={{
              backgroundColor: activeFilters.includes(state)
                ? colors["rgb-background"]
                : "#4A5568",
              color: activeFilters.includes(state)
                ? colors["rgb-text"]
                : "#FFFFFF",
            }}
            onClick={() => toggleFilter(state)}
          >
            {state}
          </button>
        ))}
    </div>
  );

  const filterEventsForDay = useCallback((events, date) => {
    const dayStart = date.startOf("day");
    const dayEnd = date.endOf("day");
    return events.filter((event) => {
      const eventDate = dayjs(event.date);
      return eventDate.isSame(dayStart, "day");
    });
  }, []);

  const renderContent = () => {
    const eventsToUse = transitionDate ? events : filteredEvents;
    const dateToUse = transitionDate || currentDate;

    // Default case: month view or week view
    if (
      isLoading ||
      (currentView === "yearview" && isYearlyLoading) ||
      (currentView !== "yearview" && isMonthlyLoading)
    ) {
      return <LoadingPulse />;
    }

    if (currentView === "yearview") {
      return (
        <YearView
          events={eventsToUse}
          year={dateToUse.year()}
          onDateClick={handleYearViewDateClick}
          isLoading={isYearlyLoading}
          error={yearlyError}
        />
      );
    }
    if (currentView === "dayview" || currentView === "weekview") {
      if (monthlyError) {
        return (
          <div className="p-4 text-center text-red-500">
            Error loading monthly events.
          </div>
        );
      }

      if (currentView === "dayview") {
        const dailyEvents = filterEventsForDay(filteredEvents, currentDate);
        return (
          <DayView
            date={currentDate.toDate()}
            events={dailyEvents}
            onDateChange={handleNavigation}
            onEventClick={(info) => {
              if (info.id && info.id !== "") {
                window.open(`/events/${info.id}`, "_blank");
              }
            }}
            activeFilters={activeFilters}
            toggleFilter={toggleFilter}
            EVENT_MAPPING={EVENT_MAPPING}
            excludedStates={excludedStates}
            handleShowModal={handleShowModal}
            perms={perms}
          />
        );
      }

      return (
        <WeekView
          date={currentDate.toDate()}
          events={filteredEvents}
          onDateChange={handleNavigation}
          onEventClick={(info) => {
            if (info.id && info.id !== "") {
              window.open(`/events/${info.id}`, "_blank");
            }
          }}
          activeFilters={activeFilters}
          toggleFilter={toggleFilter}
          EVENT_MAPPING={EVENT_MAPPING}
          excludedStates={excludedStates}
          handleShowModal={handleShowModal}
          perms={perms}
        />
      );
    }
    if (monthlyError) {
      return (
        <div className="p-4 text-center text-red-500">
          Error loading monthly events.
        </div>
      );
    }
    return (
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        initialDate={currentDate.toDate()}
        selectable={true}
        showNonCurrentDates={true}
        fixedWeekCount={false}
        displayEventEnd={true}
        firstDay={0}
        dayMaxEvents={true}
        height="auto"
        contentHeight="auto"
        displayEventTime={false}
        select={(selectionInfo) => {
          handleDateSelect(selectionInfo);
          // Prevent immediate unselect
          selectionInfo.view.calendar.unselect = () => {};
        }}
        events={filteredEvents}
        selectMirror={true}
        dateClick={handleDateClick}
        eventClick={(info) => {
          if (info.event.id && info.event.id !== "") {
            window.open(`/events/${info.event.id}`, "_blank");
          }
        }}
        eventContent={renderEvent}
        dayHeaderFormat={{ weekday: "short" }}
        headerToolbar={false}
        datesSet={handleDatesSet}
        height="auto"
        unselect={clearSelection}
      />
    );
  };

  return (
    <div className="w-full mx-auto bg-[#fffef9] dark:bg-cave-gray-900 dark:text-cave-white font-sans relative">
      <CalendarHeader
        currentDate={transitionDate || currentDate}
        currentView={currentView}
        handleNavigation={handleNavigation}
        handleViewChange={handleViewChange}
      />
      <div className="flex items-center mt-4 mb-4 justify-end">
        {selectedDates && perms.data.create_events && (
          <button
            onClick={handleShowModal}
            className="px-4 py-2 bg-blue-500 text-cave-white rounded-md text-xs font-medium mr-32"
          >
            Create Hold
          </button>
        )}
        {renderFilterButtons()}
      </div>
      <div className="relative">{renderContent()}</div>
      <HoldModal
        showModal={showAddHold}
        setShowModal={setShowAddHold}
        selectedDates={selectedDates}
        onSubmit={(data) => createHoldMutation.mutate(data)}
      />
    </div>
  );
};

export default Calendar;
