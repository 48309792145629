import React, { useState, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Select from "react-select";
import { Table, TableRow } from "./AppTable2";
import {
  createEventExpense,
  getExpenseGroups,
  getEventExpenses,
  updateEventExpense,
} from "../queries/accounting";
import { amountDisplay, amountStrToInt } from "@/utils/money";

type Book = "offer" | "actual" | "artist" | "copro" | "final";

interface ExpenseTableProps {
  id: string;
  book?: Book;
  isPDF?: boolean;
  className?: string;
  title?: string;
  /* @shared represents shared expenses across each settlement */
  shared?: boolean;
  /* @unique represents unique expenses across each settlement */
  unique?: boolean;
}

const ExpenseTable: React.FC<ExpenseTableProps> = ({
  id,
  book = "offer",
  isPDF = false,
  className,
  title = "Expenses",
  shared = false,
  unique = false,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedSubGroup, setSelectedSubgroup] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [newExpenseData, setNewExpenseData] = useState({
    description: "",
    amount: "",
    paymentMethod: "",
    checkNumber: "",
    notes: "",
  });
  const queryClient = useQueryClient();

  const groupsQuery = useQuery(["expense-groups"], getExpenseGroups);
  const getMethod = () => {
    if (shared) return "shared";
    if (unique) return "unique";
    return "";
  };
  const expensesQuery = useQuery(
    ["event-expenses", id.toString(), book, getMethod()],
    () => getEventExpenses(id, book, getMethod())
  );

  const subGroups = () => {
    if (!groupsQuery.data?.length) return [];
    const parent = groupsQuery.data.find(
      (group) => group.name === selectedGroup?.label
    );
    if (!parent) return [];
    return parent.subgroups.map((subgroup) => ({
      label: subgroup.name,
      value: subgroup.name + ";id:" + subgroup.id,
    }));
  };

  const createExpenseMutation = useMutation({
    mutationFn: async (data: any) => createEventExpense(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-expenses", id.toString()]);
      queryClient.invalidateQueries(["event-expenses", id.toString(), book]);
    },
  });

  const updateExpenseMutation = useMutation({
    mutationFn: async (data: any) => updateEventExpense(data),
    onSuccess: () => {
      console.log("{queryClient}", { queryClient });
      queryClient.invalidateQueries(["event-expenses", id.toString()]);
      queryClient.invalidateQueries(["event-expenses", id.toString(), book]);
    },
  });

  const data = useMemo(() => {
    if (!expensesQuery.data) return [];

    return expensesQuery.data.map((expense) => {
      const total =
        Number(expense.mammoth || 0) +
        Number(expense.venue || 0) +
        Number(expense.artist || 0) +
        Number(expense.partner_1 || 0) +
        Number(expense.partner_2 || 0);
      const difference = total - Number(expense.amount);
      return {
        id: expense.id,
        description: expense.description || "UNKNOWN",
        amount: expense.amount,
        paymentMethod: expense.payment_method,
        checkNumber: expense.check_number,
        notes: expense.notes,
        subgroup: expense.subgroup,
        difference,
        total,
        ...expense,
      };
    });
  }, [expensesQuery.data]);

  const exclude_artist = book !== "artist";
  const exclude_copro = book !== "copro";
  const exclude_final = book !== "final";
  const handleFieldUpdate = (field: string, v: any, expenseId: number) => {
    console.log("{field, v, expenseId}", { field, v, expenseId });
    const expenseToUpdate = data.find((expense) => expense.id === expenseId);
    if (!expenseToUpdate) return;
    const value = v;
    const isString = isNaN(Number(v));
    if (!isString) {
      // value = amountStrToInt(v);
    }
    console.log({
      mut: {
        ...expenseToUpdate,
        expense_id: expenseId,
        payment_method:
          field === "paymentMethod" ? value : expenseToUpdate.paymentMethod,
        check_number:
          field === "checkNumber" ? value : expenseToUpdate.checkNumber,
        description:
          field === "description" ? value : expenseToUpdate.description,
        amount: field === "amount" ? value : expenseToUpdate.amount,
        notes: field === "notes" ? value : expenseToUpdate.notes,
        [field]: value,
        exclude_artist,
        exclude_copro,
        exclude_final,
      },
    });
    updateExpenseMutation.mutate({
      ...expenseToUpdate,
      expense_id: expenseId,
      payment_method:
        field === "paymentMethod" ? value : expenseToUpdate.paymentMethod,
      check_number:
        field === "checkNumber" ? value : expenseToUpdate.checkNumber,
      description:
        field === "description" ? value : expenseToUpdate.description,
      amount: field === "amount" ? value : expenseToUpdate.amount,
      notes: field === "notes" ? value : expenseToUpdate.notes,
      exclude_artist,
      exclude_copro,
      exclude_final,
      [field]: value,
    });
  };

  const handleResetNewExpenseForm = () => {
    setNewExpenseData({
      description: "",
      amount: "",
      paymentMethod: "",
      checkNumber: "",
      notes: "",
    });
    setSelectedGroup(null);
    setSelectedSubgroup(null);
  };

  const handleAddExpense = () => {
    const subgroup = selectedSubGroup?.value.split(";id:")[1];
    if (!subgroup) return;
    createExpenseMutation.mutate({
      subgroup,
      payment_method: newExpenseData.paymentMethod,
      check_number: newExpenseData.checkNumber,
      description: selectedSubGroup?.label,
      amount: newExpenseData.amount || "0", // Use input value or default to "0"
      notes: newExpenseData.notes,
      is_offer: false,
      exclude_artist,
      exclude_copro,
      exclude_final,
    });

    // Reset form
    setIsAdding(false);
    handleResetNewExpenseForm();
  };
  const bundleAllExpenses = expensesQuery.data?.reduce(
    (acc, curr) => {
      acc.mammoth += curr.mammoth || 0;
      acc.venue += curr.venue || 0;
      acc.artist += curr.artist || 0;
      acc.partner_1 += curr.partner_1 || 0;
      acc.partner_2 += curr.partner_2 || 0;
      acc.totals +=
        (curr.mammoth || 0) +
        (curr.venue || 0) +
        (curr.artist || 0) +
        (curr.partner_1 || 0) +
        (curr.partner_2 || 0);
      acc.offer += curr.amount || 0;
      return acc;
    },
    {
      mammoth: 0,
      venue: 0,
      artist: 0,
      partner_1: 0,
      partner_2: 0,
      totals: 0,
      offer: 0,
      difference: 0,
    }
  );
  const TotalRow = {
    render: () => (
      <>
        <TableRow
          rowData={{
            description: "TOTALS",
            notes: "",
            mammoth: amountDisplay(bundleAllExpenses?.mammoth),
            venue: amountDisplay(bundleAllExpenses?.venue),
            artist: amountDisplay(bundleAllExpenses?.artist),
            partner_1: amountDisplay(bundleAllExpenses?.partner_1),
            partner_2: amountDisplay(bundleAllExpenses?.partner_2),
            totals: amountDisplay(bundleAllExpenses?.totals),
            amount: amountDisplay(bundleAllExpenses?.offer),
            difference: amountDisplay(
              bundleAllExpenses?.totals - bundleAllExpenses?.offer
            ),
          }}
          rowContainerClass="bg-blue-200"
          columns={[
            {
              title: "EXPENSE",
              accessor: "description",
              textAlign: "left",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("description", value, data[rowIndex].id),
            },
            {
              title: "NOTES",
              accessor: "notes",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "MAMMOTH",
              accessor: "mammoth",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "VENUE",
              accessor: "venue",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "ARTIST",
              accessor: "artist",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "PARTNER 1",
              accessor: "partner_1",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "PARTNER 2",
              accessor: "partner_2",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("partner_2", value, data[rowIndex].id),
            },
            {
              title: "TOTALS",
              accessor: "totals",
              textAlign: "center",
              editable: false,
            },
            {
              title: "OFFER",
              accessor: "amount",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("amount", value, data[rowIndex].id),
            },
            {
              title: "DIFFERENCE",
              accessor: "difference",
              textAlign: "center",
              editable: false,
            },
          ]}
        />
      </>
    ),
  };
  const AddExpenseRow = {
    render: () => (
      <>
        <div className="text-left font-bold bg-cave-white p-2">
          Add New Expense Form
        </div>
        <TableRow
          onChange={(field: string, value: any) => {
            setNewExpenseData((prev) => ({
              ...prev,
              [field]: value,
            }));
          }}
          columns={[
            {
              title: "Description",
              accessor: "description",
              textAlign: "start",
              editable: true,
              className: "!p-0",
              render: () => (
                <div>
                  <Select
                    options={subGroups()}
                    value={selectedSubGroup}
                    onChange={(value) => setSelectedSubgroup(value)}
                    placeholder="Expense Subgroup"
                    isDisabled={
                      groupsQuery.isLoading ||
                      groupsQuery.isError ||
                      !selectedGroup
                    }
                    className="p-2 bg-input-blue"
                  />
                </div>
              ),
            },
            {
              title: "NOTES",
              accessor: "notes",
              textAlign: "center",
              editable: true,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "MAMMOTH",
              accessor: "mammoth",
              textAlign: "center",
              editable: true,
              format: "money",
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "VENUE",
              accessor: "venue",
              textAlign: "center",
              editable: true,
              format: "money",
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "ARTIST",
              accessor: "artist",
              textAlign: "center",
              editable: true,
              format: "money",
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "PARTNER 1",
              accessor: "partner_1",
              textAlign: "center",
              editable: true,
              format: "money",
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "PARTNER 2",
              accessor: "partner_2",
              textAlign: "center",
              editable: true,
              format: "money",
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("amount", value, data[rowIndex].id),
            },
            {
              title: "TOTALS",
              accessor: "total",
              textAlign: "center",
              editable: false,
              format: "money",
            },
            {
              title: "OFFER",
              accessor: "amount",
              textAlign: "center",
              editable: true,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("amount", value, data[rowIndex].id),
              format: "money",
            },
            {
              title: "DIFFERENCE",
              accessor: "difference",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("amount", value, data[rowIndex].id),
              format: "money",
            },
          ]}
          rowData={newExpenseData}
          onBlur={(field, value) => {
            setNewExpenseData((prev) => ({
              ...prev,
              [field]: value,
            }));
          }}
        />
        <div>
          <Select
            options={groupsQuery.data?.map((group) => ({
              label: group.name,
              value:
                group.name.toLowerCase().replace(/\s+/g, "-") +
                ";id:" +
                group.id,
            }))}
            value={selectedGroup}
            onChange={(value) => setSelectedGroup(value)}
            placeholder="Expense Group"
            isDisabled={groupsQuery.isLoading || groupsQuery.isError}
            className="w-64"
          />
        </div>
      </>
    ),
  };
  const customRows = () => {
    const rows = [TotalRow];
    if (isAdding) {
      rows.push(AddExpenseRow);
    }
    return rows;
  };

  return (
    <div className={`w-full mt-6${className ? ` ${className}` : ""}`}>
      <Table
        className="w-full"
        tableTitle={title}
        grid
        textCenter
        columns={[
          {
            title: "EXPENSE",
            accessor: "subgroup_name",
            textAlign: "left",
            editable: false,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("description", value, data[rowIndex].id),
          },
          {
            title: "NOTES",
            accessor: "notes",
            textAlign: "center",
            editable: true,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("notes", value, data[rowIndex].id),
          },
          {
            title: "MAMMOTH",
            accessor: "mammoth",
            textAlign: "center",
            editable: true,
            format: "money",
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("mammoth", value, data[rowIndex].id),
          },
          {
            title: "VENUE",
            accessor: "venue",
            textAlign: "center",
            editable: true,
            format: "money",
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("venue", value, data[rowIndex].id),
          },
          {
            title: "ARTIST",
            accessor: "artist",
            textAlign: "center",
            editable: true,
            format: "money",
            onBlur: (value: any, rowIndex: number, colKey: string) =>
              handleFieldUpdate("artist", value, data[rowIndex].id),
          },
          {
            title: "PARTNER 1",
            accessor: "partner_1",
            textAlign: "center",
            editable: true,
            format: "money",
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("partner_1", value, data[rowIndex].id),
          },
          {
            title: "PARTNER 2",
            accessor: "partner_2",
            textAlign: "center",
            editable: true,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("partner_2", value, data[rowIndex].id),
            format: "money",
          },
          {
            title: "TOTALS",
            accessor: "total",
            textAlign: "center",
            editable: false,
            format: "money",
          },
          {
            title: "OFFER",
            accessor: "amount",
            textAlign: "center",
            editable: true,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("amount", value, data[rowIndex].id),
            format: "money",
          },
          {
            title: "DIFFERENCE",
            accessor: "difference",
            textAlign: "center",
            editable: false,
            format: "money",
          },
        ]}
        data={data}
        customRows={customRows()}
        hideHeaders={false}
      />
      <div className="w-full flex justify-end gap-2">
        {!isAdding && !isPDF && (
          <button
            onClick={() => setIsAdding(true)}
            className="bg-gray-400 disabled:bg-gray-200 rounded px-4 py-1 text-cave-white font-sans text-sm mt-2"
          >
            Add Expense
          </button>
        )}
        {isAdding && (
          <>
            <button
              onClick={handleAddExpense}
              className={`bg-green-500 text-cave-white px-2 py-1 rounded ${
                createExpenseMutation.isLoading ||
                !selectedGroup ||
                !selectedSubGroup
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}
              disabled={
                createExpenseMutation.isLoading ||
                !selectedGroup ||
                !selectedSubGroup
              }
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsAdding(false);
                handleResetNewExpenseForm();
              }}
              className="bg-red-500 text-cave-white px-2 py-1 rounded"
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ExpenseTable;
