import React from "react";

export const ToggleSwitch = ({ isToggled, onToggle, disabled }) => {
  let disabledClass = disabled ? "cursor-not-allowed opacity-50" : "";

  return (
    <button
      onClick={onToggle}
      className={`relative inline-flex items-center justify-center w-14 h-8 rounded-full ${
        isToggled ? "bg-green-500" : "bg-gray-300"
      } transition-colors duration-300 ${disabledClass}`}
      disabled={disabled}
      role="switch"
      aria-checked={isToggled}
    >
      <span
        className={`transform transition-transform duration-300 ease-in-out absolute left-1 top-1 bg-cave-white w-6 h-6 rounded-full ${
          isToggled ? "translate-x-6" : ""
        }`}
      ></span>
    </button>
  );
};

export default ToggleSwitch;
