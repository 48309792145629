import React, { useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Button } from "@/components/Button";
import { forwardRef } from "react";
import { Input } from "@/components/Form/Input";
import {
  fetchSeatingCharts,
  createSeatingChart,
  getChartSections,
  saveChartTemplate,
} from "@/queries/venues";

export const TemplateManager = ({
  onTemplateLoad,
  removeManifestMutation,
  addManifestMutation,
  venueId,
}) => {
  const methods = useForm();
  const queryClient = useQueryClient();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const {
    data: seatingCharts = [],
    isLoading,
    isError,
    error,
  } = useQuery(["seatingCharts"], fetchSeatingCharts, {
    onSuccess: (data) => console.log("Fetched seating charts:", data),
    onError: (error) => console.error("Error fetching seating charts:", error),
  });

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value) {
      const filtered = seatingCharts.filter((chart) =>
        chart.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions([]);
    }
  };

  const handleOptionSelect = (chart) => {
    setInputValue(chart.name);
    setSelectedTemplate(chart);
    setFilteredOptions([]);
  };

  const onSubmit = async () => {
    if (selectedTemplate) {
      try {
        const sections = await getChartSections(selectedTemplate.id);
        // Transform sections into manifest format
        const manifestData = sections.map((section, index) => ({
          name: section.name,
          qty: parseInt(section.qty),
          price: 0, // Default price
          ticket_fees: 0, // Default fees
          is_offer: true,
          sort_order: index + 1,
          comps: 0,
          on_sale: new Date().toISOString().split("T")[0], // Today's date
          active: true,
        }));
        onTemplateLoad(manifestData);
        toast.info("Loading Template - Please Stand By");
      } catch (error) {
        console.error("Error loading template:", error);
        toast.error("Failed to load seating sections");
      }
    }
  };

  const saveMutation = useMutation({
    mutationFn: (data) => saveChartTemplate(data),
    onSuccess: () => {
      toast.success("Template saved successfully");
      queryClient.invalidateQueries(["seatingCharts"]);
      methods.reset(); // Reset form after successful save
    },
    onError: (error) => {
      console.error("Error saving template:", error);
      toast.error("Failed to save template");
    },
  });

  const handleSaveTemplate = async () => {
    const { templateName, templateDescription } = methods.getValues();
    if (!templateName || !templateDescription) {
      toast.error("Template name and description are required");
      return;
    }

    if (!venueId) {
      toast.error("No venue ID provided");
      return;
    }

    // Get the current manifest data from the query cache
    const queryClient = useQueryClient();
    const eventData = queryClient.getQueryData(["event-detail"]);

    const sections =
      eventData?.manifests
        ?.filter((m) => m.active === true && m.is_offer === true)
        ?.map((item, index) => ({
          name: item.name,
          qty: parseInt(item.qty),
          section_type: item.section_type || null,
          description: item.description || null,
          sort_order: index + 1,
        })) || [];

    if (!sections.length) {
      toast.error("No active manifests found to save as template");
      return;
    }

    const templateData = {
      name: templateName,
      description: templateDescription,
      venue_id: venueId,
      sections: sections,
    };

    console.log("Saving template with data:", templateData);

    saveMutation.mutate(templateData, {
      onError: (error) => {
        console.error("Template save error:", error);
        toast.error(`Failed to save template: ${error.message}`);
      },
    });
  };

  if (isLoading)
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4">
        <Controller
          name="templateSelect"
          control={methods.control}
          render={({ field }) => (
            <div className="relative">
              <input
                {...field}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Search for a template..."
                className="w-full px-4 py-2 text-gray-700 bg-cave-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && filteredOptions.length > 0) {
                    handleOptionSelect(filteredOptions[0]);
                    field.onChange(filteredOptions[0]);
                    e.preventDefault(); // Prevent form submission on Enter
                  }
                }}
              />
              {filteredOptions.length > 0 && (
                <ul className="absolute z-10 w-full mt-1 bg-cave-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
                  {filteredOptions.map((chart) => (
                    <li
                      key={chart.id}
                      onClick={() => {
                        handleOptionSelect(chart);
                        field.onChange(chart);
                      }}
                      className="px-4 py-2 cursor-pointer hover:bg-blue-100"
                    >
                      {chart.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        />
        {selectedTemplate && (
          <div className="p-4 mt-4 bg-blue-100 border border-blue-300 rounded-md">
            <p className="text-sm text-blue-700">Selected Template:</p>
            <p className="text-lg font-medium text-blue-800">
              {selectedTemplate.name}
            </p>
          </div>
        )}
        <Button type="submit">Load Template</Button>

        <div className="space-y-2">
          <input
            {...methods.register("templateName")}
            placeholder="New Template Name"
            className="w-full px-4 py-2 text-gray-700 bg-cave-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <input
            {...methods.register("templateDescription")}
            placeholder="New Template Description"
            className="w-full px-4 py-2 text-gray-700 bg-cave-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <Button type="button" onClick={handleSaveTemplate}>
            Save New Template
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default TemplateManager;
