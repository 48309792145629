import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
  jsonHeaders,
  makeRequest,
} from "./base";

export const getFiles = async (entityType, id, marketingOnly = false) => {
  const url = `/api/${entityType}/files/${id}/${
    marketingOnly ? "?marketing_only=true" : ""
  }`;
  return await makeRequest(url, "GET", await jsonHeaders());
};

export const createFile = async (entityType, parentId, data) => {
  console.log(`create${entityType}File called with parentId:`, parentId);
  const url = `/api/${entityType}/files/${parentId}/`;
  console.log("Request URL:", url);
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");

  const formData = new FormData();
  for (let [key, value] of data.entries()) {
    if (key === "file") {
      formData.append("files", value, value.name);
    } else {
      formData.append(key, value);
    }
  }

  try {
    const resp = await makeRequest(url, "POST", headers, formData);
    const jsonResponse = await resp.json();
    console.log(`create${entityType}File response:`, jsonResponse);
    return jsonResponse;
  } catch (error) {
    console.error(`Error in create${entityType}File:`, error);
    throw error;
  }
};

export const deleteFile = async (entityType, fileId, queryClient) => {
  console.log(`delete${entityType}File called with fileId:`, fileId);
  const url = `/api/${entityType}/file/${fileId}/`;
  console.log("Request URL:", url);
  const headers = await jsonHeaders(true);
  try {
    const resp = await makeRequest(url, "DELETE", headers);
    console.log(`delete${entityType}File response:`, resp);

    // Invalidate and refetch queries
    queryClient.invalidateQueries([entityType.replace(/s$/, ""), "files"]);

    return resp;
  } catch (error) {
    console.error(`Error in delete${entityType}File:`, error);
    throw error;
  }
};
